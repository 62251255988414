import { render, staticRenderFns } from "./AllowanceModal.vue?vue&type=template&id=4f8c3234&scoped=true&"
import script from "./AllowanceModal.vue?vue&type=script&lang=js&"
export * from "./AllowanceModal.vue?vue&type=script&lang=js&"
import style0 from "./AllowanceModal.vue?vue&type=style&index=0&id=4f8c3234&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f8c3234",
  null
  
)

export default component.exports