import { METAVERSE_IDS } from "~/core/services/utils/MetaverseConstants";

export const COMMON_CONTRACTS = {
    "BusinessParcel": {
        "56": "0x85ee8496F02Ee1911Ccabf6f35c7eeeb228B8d87",
        "97": "0x4ef9CCCbd30F2af6A89b3c45610CBFB749D37aBf",
    },
    "MiningSC": {
        "56": "0x66f62Eadf35a5FB3DB7deC01DB0c65f55Fa7539B",
        "97": "0x18dD8fD0852641A331D13BFad10FEeD19A5559C5",
    },
    "Vaults": {
        "56": "0x916ee43Ac5F338364986B9AA86f7962a5779d0a1",
        "97": "0xAd5480780b2dE9A38580bC863dB07C8A52bb1B70",
    },
    "GymStreetFarming": {
        "56": "0x3a5d981bBD00Ae1d8BBb955225A178965D67C494",
        "97": "0xBa8720060e8Bb79f55e761C36f6208254d201E62",
    },
    "GymNetwork": {
        "56": "0x0012365F0a1E5F30a5046c680DCB21D07b15FcF7",
        "97": "0x895E2653e8DD941fc3A39283109fA53603A98805",
    },
    "MLM": {
        "56": "0xf605b6F72A25eA4d4B0F0606e097FEb19D429A74",
        "97": "0x974928537f4c8465e2FEb1f968f728D360cf23C9",
    },
    "SignatureValidatorUM": {
        "56": "0x6EeF758d88eC91650265B7F8A1F91662792a0399",
        "97": "0x38ef312610F8D9f7F80fACe327CB3Cf63f342818",
    },
    "NetGymStreet": {
        "56": "0xf07cdb4ee143f3ecf34ece7c593d8dd726615484",
        "97": "0x61bbe362a483a9bdb1eb13716654ce18581690de",
    },
    "BUSD": {
        "56": "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
        "97": "0xC9D8207b45A0d41a4bdCA01D4Ed1640aE00f7D6f",
    },
    "PANCAKE_FACTORY": {
        "56": "0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73",
        "97": "0xB7926C0430Afb07AA7DEfDE6DA862aE0Bde767bc",
    },
    "WBNB": {
        "56": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
        "97": "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd",
    },
    "PancakeRouter": {
        "56": "0x10ED43C718714eb63d5aA57B78B54704E256024E",
        "97": "0x9ac64cc6e4415144c455bd8e4837fea55603e5c3",
    },
    "GymRouter": {
        "56": "0x6b869795937DD2B6F4E03d5A0Ffd07A8AD8c095B",
        "97": "0xca687ebC9D21dBf2a956d5f1E3373bCE7b399967",
    },
    "GymAgregator": {
        "56": "0x06cE812df5409ED4B4c9F5771b81Cf4A97d573E7",
        "97": "0x5E60B216BdD9ac67a39D5405BcF0cc229ff316EF",
    },
    "GymStreetLevelPool": {
        "56": "0x12cA98cf941c3aC658c5bDa539cf930fa3088627",
        "97": "0xB60a35CE47a01b8D0f276e33a2c0b1a48917C9Fc",
    },
    "TurnoverPool": {
        "56": "0xD252888619Fb8C39A23461317608D794e3D3a0f2",
        "97": "0xB865188207b32c2fCa73C29BA546d5d00c432fe2",
    },
    "MonthlyTaxPool": {
        "56": "0x26467A6C13D54dDD17f84A094af07c7cEa0dB7f4",
        "97": "0xB60a35CE47a01b8D0f276e33a2c0b1a48917C9Fc",
    },
    "MlmQualification": {
        "56": "0x42f9ccCaF27b18eBb0E4509dF19cB45447fF5E41",
        "97": "0xC1569F8f8828A098700d089BEdb14FCb355Ed5e8",
    },
    "NftReflection": {
        "56": "0x12b80DeB9A2d7F82ac95129e4CA0285C4e6Ab533",
        "97": "0x297fb46a1e6cd247943ca668f4eb575245bfcff7",
    },
    "SinglePool": {
        "56": "0xD249a1e3EEbae66B6B6C57197146C47e8e67E046",
        "97": "0x197054Cfb9A213013da9593eB1574BD40DcF0dA4",
    },
    "BitopexToken": {
        "56": "0x8d659d0fC18e7a26f10a3a57983448a2e16D441A",
        "97": "0x8B5Fc72A7207203D6AB3F84047dFAD8a85403213",
    },
    "USDT": {
        "56": "0x55d398326f99059ff775485246999027b3197955",
        "97": "0xC75090e0D311599b85feaaae9812932cd4AEfFd8",
    },
    "FileCoin": {
        "56": "0x0D8Ce2A99Bb6e3B7Db580eD848240e4a0F9aE153",
        "97": "0x8e8167234Da127b58ECa95d0A2Bb556BcdD15797",
    },
    "BinarySubscription": {
        "56": "0xdc6f1a59021f0d69a37de16a21b5d27f9feea67d",
        "97": "0x4120770b4545ecc0619f00a4bf8c69d01585c6dd",
    },
    "MysteryBox": {
        "56": "0x17F4Fdb30780e999C68E0B43534414f955C20A5c",
        "97": "0x1b406ED4601Dd8Fe8a3c4Eb24C37b2B5bBa0Dd94",
    },
    "CashFT": {
        "56": "0x433Ec21BdCA8fc5915E853D81bC61d2ca5d6157E",
        "97": "0x49078c86474e36B635DfBddff151A17A4b909F83",
    },
    "SinglePoolPendingCommission": {
        "56": "0x96cAA4d40467C1Fc964bb28FaC62C29dC7b6976A",
        "97": "0x6731a26D0F64A78eA166fe12d879DadCF80aa1f1"
    },
    "PendingCommissionActivation": {
        "56": "0x3E1240E879b4613C7Ae6eE1772292FC80B9c259e",
        "97": "0x6a4B27E70dbe2Cc0Fc23Bf69f300905371aD3b29"
    },
};


export const CONTRACTS = {
    [METAVERSE_IDS.GYMSTREET]: {
        "Municipality": {
            "56": "0xDb475545112e8Bf14cEf2A77C67e4a20cff8Dd18",
            "97": "0x8ca600389C7EF52de82a9a132aaeD9A3490Ea6CA",
        },
        "StandardParcel": {
            "56": "0x6b484924C33122c1B8a6AFEa8b8e889a22E7A950",
            "97": "0x4dcF21C3979d48BD6ec7399E7466447BE5dC295b",
        },
        "MinerNFT": {
            "56": "0xeF881176e420e846A02C768e2B0ccC0e54F2Cf50",
            "97": "0xF84c3b59428e8A4D25bE0bF92a362f3F67e42ec3",
        },
    },
    [METAVERSE_IDS.KABUTOCHO]: {
        "Municipality": {
            "56": "0x2051896b6462727BDF8a7Bd87Cf09f199dBc5193",
            "97": "0x96c9f91a98d4c58b57db40CCedac8338721B1B31",
        },
        "StandardParcel": {
            "56": "0xAB2D35d677F69d331C45Ea17Dec00FB6F2d640d6",
            "97": "0xecE3CE21B7C6B8d713cf1D6E1Ba1a13440F0f4A9",
        },
        "MinerNFT": {
            "56": "0xE0C8d06d547E5617D6624E1885efa93fD6F50dc0",
            "97": "0x722a65cC4065A871684c66c1F5119e24e293a087",
        },
    },
    // [METAVERSE_IDS.CASHFT]: {
    // }
};
