
import VueCountdown from "@chenfengyuan/vue-countdown";

export default {
    name: "HeaderAlertCountdown",
    components: {
        VueCountdown
    },
    emits: ['end'],
    props: {
        end: Date
    },
    data() {
        return {
            endDate: null
        }
    },
    created() {
        const endDate = this.end;
        this.endDate = endDate
    }
}
