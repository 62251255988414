
import {defineComponent} from 'vue';
import { MysteryBoxPaymentType } from '../../../constants/MysteryBoxPaymentType';
import {toCurrencyFormat} from '@/core/helpers/GlobalHelpers';
import GymNewButton from '~/components/utils/form-components/GymNewButton.vue';
import Asset from '~/pages/finances/wallet/models/Asset';
import {toNumericFormat} from '~/core/helpers/GlobalHelpers';
import {UserBalance} from '~/core/components/flows/mystery-box/models/UserBalance';
import MysteryBoxTx from '~/core/components/flows/mystery-box/models/MysteryBoxTx';

export type StepSelectCurrencyComponent = {
    userBalance: UserBalance,
    transactionObject: MysteryBoxTx,
    toCurrencyFormat: typeof toCurrencyFormat;
    totalAmountAsset: Asset,
    selectedBalance: null | MysteryBoxPaymentType,
    MysteryBoxPaymentType: typeof MysteryBoxPaymentType,
    isSelectionError: boolean;
    isGymnetDisabled: boolean;
    isUtilityDisabled: boolean;
    isGymnetSelected: boolean;
    isUtilitySelected: boolean;
    handleContinue: () => void;
    switchBalance: (balance: MysteryBoxPaymentType) => void;
}

export default defineComponent<keyof StepSelectCurrencyComponent, StepSelectCurrencyComponent>({
    name: 'CurrencySelection',
    components: {GymNewButton},
    props: {
        userBalance: {
            type: UserBalance,
            required: true,
        },
        transactionObject: {
            type: MysteryBoxTx,
            required: true,
        },
    },
    emits: [
        'onCurrencySelected',
        'onClickBack',
        'onStartLoading',
        'onStopLoading',
    ],
    data() {
        return {
            toCurrencyFormat,
            toNumericFormat,
            selectedBalance: null,
            isSelectionError: false,
            MysteryBoxPaymentType,
        };
    },
    computed: {
        totalAmountAsset() {
            return this.transactionObject.priceInfo.totalAmount;
        },
        isGymnetSelected() {
            return this.selectedBalance === MysteryBoxPaymentType.GYMNET;
        },
        isUtilitySelected() {
            return this.selectedBalance === MysteryBoxPaymentType.UTILITY;
        },
        isGymnetDisabled() {
            return this.userBalance.gymnet.value < this.totalAmountAsset.value;
        },
        isUtilityDisabled() {
            return this.userBalance.utility.value < this.totalAmountAsset.value;
        },
    },
    watch: {
        userBalance: {
            handler (newVal: UserBalance) {
                const preselectedBalance = this.selectedBalance;
                const preselectedBalanceAsset = preselectedBalance === MysteryBoxPaymentType.GYMNET ? 'gymnet' : 'utility';
                if (this.selectedBalance && newVal[preselectedBalanceAsset].value < this.totalAmountAsset.value) {
                    this.selectedBalance = null;
                }
                if (preselectedBalance === MysteryBoxPaymentType.GYMNET &&
                    this.selectedBalance === null &&
                    newVal.utility.value >= this.totalAmountAsset.value) {
                    this.selectedBalance = MysteryBoxPaymentType.UTILITY;
                }
                if (preselectedBalance === MysteryBoxPaymentType.UTILITY &&
                    this.selectedBalance === null &&
                    newVal.gymnet.value >= this.totalAmountAsset.value) {
                    this.selectedBalance = MysteryBoxPaymentType.GYMNET;
                }
            },
            immediate: true,
        },
    },
    methods: {
        async handleContinue(): Promise<void> {
            if (this.selectedBalance === MysteryBoxPaymentType.GYMNET ||
                this.selectedBalance === MysteryBoxPaymentType.UTILITY) {
                this.isSelectionError = false;
                try {
                    this.$emit('onStartLoading');
                    this.transactionObject.setPaymentType(this.selectedBalance);
                    const amountToCheck = this.totalAmountAsset.value;
                    this.transactionObject.clearCache();
                    await this.transactionObject.estimateGas();
                    await this.transactionObject.checkAllowance(amountToCheck);
                    this.$emit('onCurrencySelected');
                    this.$emit('onStopLoading');
                } catch (e) {
                    this.$emit('onStopLoading', e);
                }
            } else {
                this.isSelectionError = true;
            }
        },
        switchBalance(balance) {
            const assetName = balance === MysteryBoxPaymentType.GYMNET ? 'gymnet' : 'utility';
            if (this.userBalance[assetName].value < this.totalAmountAsset.value) {
                return;
            }
            this.selectedBalance = balance;
            this.isSelectionError = false;
        },
    },
});

