import { MysteryBox } from "~/core/components/flows/mystery-box/models/MysteryBox";
import { MYSTERY_BOX_PURCHASE_STEPS } from "~/core/components/flows/mystery-box/constants/MysteryBoxPurchaseSteps";
import { MetaInventoryContainer } from "~/core/models/MetaInventoryContainer";
import { MetaWorldManager } from "~/core/services/map/MetaWorldManager";
import { FinishReasonEnum } from "~/core/components/flows/mystery-box/constants/FinishReasonEnum";
import { MysteryBoxPurchase } from "~/core/components/flows/mystery-box/types/MysteryBoxPurchase";
import { MysteryBoxStatistics } from "~/core/components/flows/mystery-box/models/MysteryBoxStatistics";
import ApiService from "~/core/services/api-interaction/ApiService";
import ExchangeService from "~/pages/finances/wallet/services/ExchangeService";
import Asset from "~/pages/finances/wallet/models/Asset";
import { CurrencyName } from "~/pages/finances/wallet/models/CurrencyName";
import { MysteryBoxHistoryEntry } from "~/core/components/flows/mystery-box/models/MysteryBoxHistoryEntry";
import { MysteryBoxPrize } from "~/core/components/flows/mystery-box/models/MysteryBoxPrize";
import { MYSTERY_BOX_ACTIVE } from "~/core/services/utils/Constants";

type MysteryBoxState = {
    allPrizesById: Record<number, MysteryBoxPrize>;
    mysteryBoxEndDate: number;
    isMysteryBoxFlowVisible: boolean;
    isMysteryBoxOptionsPopupVisible: boolean;
    isMysteryBoxesLoading: boolean;
    mysteryBoxes: MysteryBox[];
    parcelPrice: number;
    purchase: MysteryBoxPurchase | null;
    startStep: MYSTERY_BOX_PURCHASE_STEPS;
    finishedId: number;
    finishReason: FinishReasonEnum | null;
    finishStep: MYSTERY_BOX_PURCHASE_STEPS;
    mysteryBoxStatistics: MysteryBoxStatistics | null;
    mysteryBoxStatisticsLoading: boolean;
    mysteryBoxTotalHistoryEntries: number;
    mysteryBoxHistoryPage: number;
    mysteryBoxHistoryPageSize: number;
    mysteryBoxHistoryPageData: MysteryBoxHistoryEntry[];
    isMysteryBoxHistoryPageLoading: boolean;
}

const MARCH_19_1500 = 1710846000000; // Tuesday, March 19, 2024 15:00:00 GMT+04:00

const state = () => {
    return {
        allPrizesById: {},
        mysteryBoxEndDate: MARCH_19_1500,
        isMysteryBoxFlowVisible: false,
        isMysteryBoxOptionsPopupVisible: false,
        isMysteryBoxesLoading: false,
        mysteryBoxes: [],
        parcelPrice: -1,
        product: null,
        purchase: null,
        startStep: MYSTERY_BOX_PURCHASE_STEPS.SELECT_PRODUCT_OWNER,
        finishedId: 0,
        finishReason: null,
        finishStep: MYSTERY_BOX_PURCHASE_STEPS.SELECT_PRODUCT_OWNER,
        mysteryBoxStatistics: null,
        mysteryBoxStatisticsLoading: true,
        mysteryBoxTotalHistoryEntries: 0,
        mysteryBoxHistoryPage: 1,
        mysteryBoxHistoryPageSize: 10,
        mysteryBoxHistoryPageData: [],
        isMysteryBoxHistoryPageLoading: false,
    };
};

const actions = {
    setMysteryPurchaseFlowVisibility ({ commit }, visible: boolean) {
        commit('SET_MYSTERY_BOXES_LOADING', visible);
    },
    async loadMysteryBoxes ({ commit, dispatch }) {
        try {
            commit('SET_MYSTERY_BOXES_LOADING', true);
            const parcelPricePromise = dispatch('loadParcelPrice');
            const bundles = MetaInventoryContainer.sharedInstance().bundles;
            const contract = this.$contracts[this.$metaverseId()].MysteryBox;
            const mysteryBoxContractData = await contract.methods.getMysteryBoxInfo().call();
            const gymnetRate = await ExchangeService.getRate('GYMNET');
            const mysteryBoxesData = mysteryBoxContractData.map((mysteryBoxData) => {
                const type = Number(mysteryBoxData[0]);
                const price = new Asset(
                    null,
                    "Gym Network Token",
                    "GYMNET" as CurrencyName,
                    require("~/assets/images/icons/gymnet-icon.svg"),
                    Number(this.$web3().utils.fromWei(mysteryBoxData[1])),
                    gymnetRate,
                    false,
                    MetaWorldManager.sharedInstance(),
                );
                return {
                    type,
                    price,
                    discount: Number(mysteryBoxData[2]),
                    chances: mysteryBoxData.slice(3),
                };
            });
            const parcelPrice = await parcelPricePromise;
            const mysteryBoxes = mysteryBoxesData.map((mysteryBoxData) => new MysteryBox(
                mysteryBoxData.type,
                mysteryBoxData.price,
                mysteryBoxData.discount,
                mysteryBoxData.chances,
                bundles,
                parcelPrice,
            )).reverse();
            const allPrizesById: Record<number, MysteryBoxPrize> = {};
            for (const mysteryBox of mysteryBoxes) {
                for (const prize of mysteryBox.prizeList) {
                    if (!allPrizesById[prize.id]) {
                        allPrizesById[prize.id] = prize;
                    }
                }
            }
            commit('SET_ALL_PRIZES_BY_ID', allPrizesById);
            dispatch('setMysteryBoxes', mysteryBoxes);
        } finally {
            commit('SET_MYSTERY_BOXES_LOADING', false);
        }
    },
    setMysteryBoxes ({ commit }, mysteryBoxes: MysteryBox[]) {
        commit('SET_MYSTERY_BOXES', mysteryBoxes);
    },
    setMysteryBoxOptionsPopupVisible ({ commit }, payload: boolean) {
        commit('SET_MYSTERY_BOX_OPTIONS_POPUP_VISIBLE', payload);
    },
    async loadParcelPrice ({ state, commit }) {
        const mwm = MetaWorldManager.sharedInstance();
        if (mwm.parcelStats) {
            commit('SET_PARCEL_PRICE', mwm.parcelStats.parcelsCurrentPrice);
        } else {
            const {priceForBuyParcel} = await mwm.fetchParcelPriceInfo(1);
            commit('SET_PARCEL_PRICE', priceForBuyParcel);
        }
        return state.parcelPrice;
    },
    async loadMysteryBoxStatistics({ commit }) {
        try {
            const response = await ApiService.get(`${this.$config.binaryTreeApi}/api/mystery/history`);
            commit('SET_MYSTERY_BOX_STATISTICS', response.data);
        } catch {
            commit('SET_MYSTERY_BOX_STATISTICS', null);
        } finally {
            commit('SET_MYSTERY_BOX_STATISTICS_LOADING', null);
        }
    },
    show ({ commit, state }, purchase: MysteryBoxPurchase) {
        commit('SET_MYSTERY_BOX_PURCHASE_STEP', MYSTERY_BOX_PURCHASE_STEPS.SELECT_PRODUCT_OWNER);
        commit('SET_PURCHASE_OBJECT', purchase);
        commit('SET_MYSTERY_BOX_FLOW_STATUS', true);
        commit('SET_FINISHED_ID', state.finishedId + 1);
        commit('SET_FINISH_REASON', null);
        commit('SET_FINISH_STEP', MYSTERY_BOX_PURCHASE_STEPS.SELECT_PRODUCT_OWNER);
    },
    hide ({ commit, state }, { finishReason, step = null }) {
        commit('SET_MYSTERY_BOX_PURCHASE_STEP', MYSTERY_BOX_PURCHASE_STEPS.SELECT_PRODUCT_OWNER);
        commit('SET_PURCHASE_OBJECT', null);
        commit('SET_MYSTERY_BOX_FLOW_STATUS', false);
        commit('SET_FINISHED_ID', state.finishedId + 1);
        commit('SET_FINISH_REASON', finishReason);
        commit('SET_FINISH_STEP', step);
    },
    async loadMysteryBoxHistory({ commit, state }, loading = false) {
        if (loading) {
            commit('SET_MYSTERY_BOX_HISTORY_PAGE_LOADING', true);
        }
        const page = state.mysteryBoxHistoryPage;
        const perPage = state.mysteryBoxHistoryPageSize;
        try {
            const response = await ApiService.query(`${this.$config.binaryTreeApi}/api/mystery/user-history`, {
                params: {
                    page,
                    perPage,
                },
            });
            if (page === state.mysteryBoxHistoryPage && perPage === state.mysteryBoxHistoryPageSize) {
                commit('SET_MYSTERY_BOX_HISTORY_TOTAL_ENTRIES', response.data.meta.total);
                commit('SET_MYSTERY_BOX_HISTORY', response.data.data);
            }
        } catch(e) {
            console.log('Failed to load mystery box history data', e);
        } finally {
            if (page === state.mysteryBoxHistoryPage && perPage === state.mysteryBoxHistoryPageSize) {
                commit('SET_MYSTERY_BOX_HISTORY_PAGE_LOADING', false);
            }
        }
    },
    async changeMysteryBoxHistoryPage({ commit, dispatch }, page: number) {
        commit('SET_MYSTERY_BOX_HISTORY_PAGE', page);
        await dispatch('loadMysteryBoxHistory', true);
    },
    async changeMysteryBoxHistoryPageSize({ commit, dispatch }, pageSize: number) {
        commit('SET_MYSTERY_BOX_HISTORY_PAGE_SIZE', pageSize);
        commit('SET_MYSTERY_BOX_HISTORY_PAGE', 1);
        await dispatch('loadMysteryBoxHistory', true);
    },
    resetMysteryBoxHistoryData({ commit }) {
        commit('SET_MYSTERY_BOX_HISTORY_PAGE', 1);
        commit('SET_MYSTERY_BOX_HISTORY_TOTAL_ENTRIES', 0);
        commit('SET_MYSTERY_BOX_HISTORY', []);
        commit('SET_MYSTERY_BOX_HISTORY_PAGE_LOADING', false);
        commit('SET_MYSTERY_BOX_HISTORY_PAGE_SIZE', 10);
    },
};

const mutations = {
    SET_MYSTERY_BOXES_LOADING (state: MysteryBoxState, payload: boolean) {
        state.isMysteryBoxesLoading = payload;
    },
    SET_MYSTERY_BOXES (state: MysteryBoxState, payload: MysteryBox[]) {
        state.mysteryBoxes = payload;
    },
    SET_MYSTERY_BOX_OPTIONS_POPUP_VISIBLE (state: MysteryBoxState, payload: boolean) {
        state.isMysteryBoxOptionsPopupVisible = payload;
    },
    SET_PARCEL_PRICE (state: MysteryBoxState, payload: number) {
        state.parcelPrice = payload;
    },
    SET_MYSTERY_BOX_FLOW_STATUS (state: MysteryBoxState, payload: boolean) {
        state.isMysteryBoxFlowVisible = payload;
    },
    SET_PURCHASE_OBJECT (state: MysteryBoxState, payload: MysteryBoxPurchase | null) {
        state.purchase = payload;
    },
    SET_MYSTERY_BOX_PURCHASE_STEP (state: MysteryBoxState, payload: MYSTERY_BOX_PURCHASE_STEPS) {
        state.startStep = payload;
    },
    SET_FINISHED_ID (state: MysteryBoxState, payload: number) {
        state.finishedId = payload;
    },
    SET_FINISH_REASON (state: MysteryBoxState, payload: FinishReasonEnum | null) {
        state.finishReason = payload;
    },
    SET_FINISH_STEP (state: MysteryBoxState, payload: MYSTERY_BOX_PURCHASE_STEPS) {
        state.finishStep = payload;
    },
    SET_MYSTERY_BOX_STATISTICS(state: MysteryBoxState, payload: any) {
        state.mysteryBoxStatistics = new MysteryBoxStatistics(payload);
    },
    SET_MYSTERY_BOX_STATISTICS_LOADING(state: MysteryBoxState, payload: boolean) {
        state.mysteryBoxStatisticsLoading = payload;
    },
    SET_MYSTERY_BOX_HISTORY_PAGE(state: MysteryBoxState, payload: number) {
        state.mysteryBoxHistoryPage = payload;
    },
    SET_MYSTERY_BOX_HISTORY_TOTAL_ENTRIES(state: MysteryBoxState, payload: number) {
        state.mysteryBoxTotalHistoryEntries = payload;
    },
    SET_MYSTERY_BOX_HISTORY_PAGE_SIZE(state: MysteryBoxState, payload: number) {
        state.mysteryBoxHistoryPageSize = payload;
    },
    SET_MYSTERY_BOX_HISTORY(state: MysteryBoxState, payload: MysteryBoxHistoryEntry[]) {
        state.mysteryBoxHistoryPageData = payload;
    },
    SET_MYSTERY_BOX_HISTORY_PAGE_LOADING(state: MysteryBoxState, payload: boolean) {
        state.isMysteryBoxHistoryPageLoading = payload;
    },
    SET_ALL_PRIZES_BY_ID(state: MysteryBoxState, payload: Record<number, MysteryBoxPrize>) {
        state.allPrizesById = payload;
    },
    SET_MYSTERY_BOX_LOADING(state: MysteryBoxState, payload: boolean) {
        state.isMysteryBoxesLoading = payload;
    },
};

const getters = {
    allPrizesById: (state: MysteryBoxState) => state.allPrizesById,
    mysteryBoxes: (state: MysteryBoxState) => state.mysteryBoxes,
    isMysteryBoxesLoading: (state: MysteryBoxState) => state.isMysteryBoxesLoading,
    isMysteryBoxOptionsPopupVisible: (state: MysteryBoxState) => state.isMysteryBoxOptionsPopupVisible,
    isMysteryBoxFlowVisible: (state: MysteryBoxState) => state.isMysteryBoxFlowVisible,
    purchase: (state: MysteryBoxState) => state.purchase,
    startStep: (state: MysteryBoxState) => state.startStep,
    finishedId: (state: MysteryBoxState) => state.finishedId,
    finishReason: (state: MysteryBoxState) => state.finishReason,
    mysteryBoxStatistics: (state: MysteryBoxState) => state.mysteryBoxStatistics,
    mysteryBoxStatisticsLoading: (state: MysteryBoxState) => state.mysteryBoxStatisticsLoading,
    mysteryBoxEndDate: (state: MysteryBoxState) => state.mysteryBoxEndDate,
    mysteryBoxTotalHistoryEntries: (state: MysteryBoxState) => state.mysteryBoxTotalHistoryEntries,
    mysteryBoxHistoryPage: (state: MysteryBoxState) => state.mysteryBoxHistoryPage,
    mysteryBoxHistoryPageSize: (state: MysteryBoxState) => state.mysteryBoxHistoryPageSize,
    mysteryBoxHistoryPageData: (state: MysteryBoxState) => state.mysteryBoxHistoryPageData,
    isMysteryBoxHistoryPageLoading: (state: MysteryBoxState) => state.isMysteryBoxHistoryPageLoading,
    isMysteryBoxActive() {
        return MYSTERY_BOX_ACTIVE;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
