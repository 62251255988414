
import GymNewButton from '~/components/utils/form-components/GymNewButton.vue';
import DividerWithText from '~/components/utils/DividerWithText.vue';
import AuthService from '~/core/services/api-interaction/AuthService';
import { mapGetters } from 'vuex';

const BACK_END_EMAIL_INTERVAL = 60;

export default {
    name: "ConfirmationEmailSent",
    components: {
        GymNewButton,
        DividerWithText,
    },
    emits: ['onOK', 'onResendEmail', 'onChangeEmail'],
    data() {
        return {
            resendButtonDisabled: false,
            seconds: null,
            interval: null,
            isAuthUserSet: false,
        }
    },
    async mounted() {
        this.isAuthUserSet = !!this.authUser;
        const lastSentDate = new Date(this.authUser?.lastEmailSent || null);
        const currentDate = new Date();
        const seconds = Math.floor((currentDate - lastSentDate) / 1000);
        
        if(this.authUser && !this.authUser?.isEmailVerified && !this.authUser?.emailConfirmationOverdued) {
            if(seconds < BACK_END_EMAIL_INTERVAL) {
                this.resendButtonDisabled = true;
                this.startCountDown(BACK_END_EMAIL_INTERVAL - seconds);
            } else {
                await this.resendEmail();
            }
        }
    },
    destroyed() {
        if(this.interval) {
            clearInterval(this.interval);
        }
    },
    computed: {
        ...mapGetters('application/driver', ['authUser']),
    },
    methods: {
        async resendEmail() {
            try {
                this.startLoading();
                await AuthService.resendVerificationEmail(this.email);
                const lastEmailDate = new Date().toISOString();
                this.$store.commit('application/driver/UPDATE_AUTH_USER', {
                                lastEmailSent: lastEmailDate,
                            });
                this.$auth.setUser({
                                ...this.$auth.user,
                                last_email_sent: lastEmailDate,
                            });
                this.startCountDown();
                this.$emit('onResendEmail');
            } catch (err) {
                this.onError(err);
            }
        },
        changeEmail() {
            this.$emit('onChangeEmail');
        },
        startLoading() {
            this.resendButtonDisabled = true;
            this.$emit('onLoading');
        },
        onError(errorObject) {
            this.resendButtonDisabled = false;
            this.$emit('onError', {
                error: errorObject,
                popupData: {
                    title: "Couldn't resend confirmation email.",
                    message: errorObject.message,
                }
            });
        },
        startCountDown(seconds = BACK_END_EMAIL_INTERVAL) {
            this.seconds = seconds;
            this.interval = setInterval(() => {
                this.seconds--;
                if(this.seconds === 0) {
                    this.resendButtonDisabled = false;
                    this.seconds = null;
                    clearInterval(this.interval);
                }
            }, 1000);
        }
    }
}
