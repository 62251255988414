
import LocaleSwitcher from "@/components/utils/localeSwitcher/LocaleSwitcher";
import ProfileDropdown from "@/components/header/components/ProfileDropdown";
import InfoTooltip from "../utils/InfoTooltip.vue";
import {mapGetters} from "vuex";
import GYMButton from "@/components/utils/buttons/GYMButton";
import {GYMSTREET_STATE, NOTIFICATIONS_FETCH_INTERVAL} from "@/core/services/utils/Constants";
import ContractAddress from "@/components/utils/ContractAddress";
import NotificationDropdown from "@/components/header/components/NotificationDropdown";
import {MetaNotificationsContainer} from "@/core/services/notifications/MetaNotificationsContainer";
import {MetaNotificationsManager} from "@/core/services/notifications/MetaNotificationsManager";
import ContractAddressesDropDown from "@/components/header/components/ContractAddressesDropDown";
import BalanceSection from "@/components/header/components/BalanceSection";
import GymNewButton from "../utils/form-components/GymNewButton.vue";
import { AUTH_FLOW_STEPS } from "@/core/services/utils/Constants";
import HeaderAlertCountdown from "@/components/header/components/HeaderAlertCountdown";
import MetaverseSwitcher from "@/components/header/components/MetaverseSwitcher.vue";
import { METAVERSES, METAVERSE_IDS } from "@/core/services/utils/MetaverseConstants";
import GymCountdown from "@/components/utils/GymCountdown.vue";

export default {
    name: 'GYMHeader',
    components: {
        HeaderAlertCountdown,
        NotificationDropdown,
        ContractAddress,
        GYMButton,
        LocaleSwitcher,
        ProfileDropdown,
        GymNewButton,
        BalanceSection,
        ContractAddressesDropDown,
        MetaverseSwitcher,
        InfoTooltip,
        GymCountdown,
    },
    watch: {
        oldAddress: {
            async handler() {
                console.log('oldAddress changed');
                await this.$store.dispatch('application/driver/logOut')
            },
            deep: true
        },
    },
    data() {
        return {
            search: '',
            lightDarkMood: 'light',
            accountData: null,
            showWalletDialog: false,
            notificationsContainer: null,
            GYMSTREET_STATE,
        }
    },
    computed: {
        getPageTitle() {
            const str = this.$route.name.replace('___en', '').replace('-', ' ');
            return str.charAt(0).toUpperCase() + str.slice(1);
        },
        ...mapGetters('application/maintenances', ['nearestMaintenance']),
        ...mapGetters('application/driver', ['isInitWalletInProgress']),
        ...mapGetters('application/driver', ['authUser', 'applicationStatus']),
        ...mapGetters({
            address: "auth/address",
            oldAddress: "auth/oldAddress"
        }),
        ...mapGetters('map/mapModule', ['metaverseId']),
        logo() {
            return METAVERSES[this.metaverseId].logo
        },
        isGymStreet() {
            return this.metaverseId === METAVERSE_IDS.GYMSTREET
        },
    },
    created() {
        this.notificationsContainer = MetaNotificationsContainer.sharedInstance();
        setInterval(() => {
            if (this.authUser && (this.authUser.isEmailVerified || (this.authUser.email && !this.authUser.emailConfirmationOverdued))) {
                MetaNotificationsManager.fetchNewNotifications();
            }
        }, NOTIFICATIONS_FETCH_INTERVAL)
        if (process.client) {
            document.getElementsByTagName('html')[0].className = this.lightDarkMood
        }
    },
    methods: {
        toggleLightDarkMood(value) {
            this.lightDarkMood = value;
            if (process.client) {
                let rootElement = document.getElementsByTagName('html')[0]
                rootElement.className = value
            }
            localStorage.setItem("light_dark_mode", value);
        },
        handleLogIn() {
            this.$store.dispatch('application/driver/setAuthStep', AUTH_FLOW_STEPS.SIGN_IN_WITH_EMAIL);
            this.$store.dispatch('application/popup-control/changeConnectWalletPopupStatus', true);
        },
        handleSignUp() {
            this.$store.dispatch('application/driver/setAuthStep', AUTH_FLOW_STEPS.SIGN_UP_WITH_EMAIL);
            this.$store.dispatch('application/popup-control/changeConnectWalletPopupStatus', true);
        },
        openMetaMaskInstallationPopup() {
            this.$store.dispatch('application/popup-control/openMetaMaskInstallationPopup', true);
        },
        reloadPage() {
            window.location.reload();
        },
    }
}
