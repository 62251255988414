
import {defineComponent} from 'vue';
import InfoTooltip from '~/components/utils/InfoTooltip.vue';
import InfoAlertCard from '~/components/utils/cards/info-alert-card/InfoAlertCard.vue';
import GymNewButton from '~/components/utils/form-components/GymNewButton.vue';
import {PaymentMethodOption} from '~/core/components/flows/mystery-box/types/PaymentMethodOption';
import {PaymentMethodEnum} from '~/core/components/flows/mystery-box/constants/PaymentMethodEnum';
import {MetaUser} from '~/core/models/MetaUser';
import {UserBalance} from '~/core/components/flows/mystery-box/models/UserBalance';
import {PaymentSpecialConditions} from '~/core/components/flows/mystery-box/types/PaymentSpecialConditions';
import MysteryBoxTx from '~/core/components/flows/mystery-box/models/MysteryBoxTx';

const BUSD_FEE_VALUE = 3;

const ALWAYS_AVAILABLE_PAYMENT_METHODS: PaymentMethodOption[] = [
    {label: 'Web3 Wallet', value: PaymentMethodEnum.WEB3_WALLET, disabled: false},
    // {label: 'External wallet', value: PaymentMethodEnum.EXTERNAL, disabled: false}, // disabled: true, disabledTitle: 'Maintenance', disabledReason: 'We apologize for the inconvenience. Our third-party provider is currently undergoing maintenance, and during this time, you can only make payments using USDT. We appreciate your understanding.'
];

export default defineComponent({
    name: 'PaymentMethodSelection',
    components: {
        InfoTooltip,
        InfoAlertCard,
        GymNewButton,
    },
    emits: [
        'onPaymentMethodSelected',
        'onStartLoading',
        'onStopLoading',
        'onClickBack',
    ],
    props: {
        user: {
            type: MetaUser,
            required: true,
        },
        userBalance: {
            type: UserBalance,
            required: true,
        },
        transactionObject: {
            type: MysteryBoxTx,
            required: true,
        },
    },
    data() {
        return {
            PaymentMethodEnum,
            selectedPaymentMethod: ALWAYS_AVAILABLE_PAYMENT_METHODS[0],
            paymentMethodOptions: ALWAYS_AVAILABLE_PAYMENT_METHODS,
            specialConditions: {
                isGasBack: false,
            } as PaymentSpecialConditions,
            isDirty: false,
        };
    },
    watch: {
        userBalance: {
            handler(newBalance, oldBalance) {
                if (newBalance !== oldBalance && this.user.isFullyWeb2User && this.userBalance) {
                    this.updatePaymentMethods();
                }
            },
            immediate: true,
        },
    },
    methods: {
        handlePaymentMethodSelected() {
            this.$emit('onPaymentMethodSelected', this.selectedPaymentMethod.value, this.specialConditions);
        },
        handleMethodChange() {
            this.isDirty = true;
        },
        updatePaymentMethods() {
            this.specialConditions.isGasBack = false;
            const newPaymentMethods = [...ALWAYS_AVAILABLE_PAYMENT_METHODS];
            if (this.user.isFullyWeb2User && this.userBalance) {
                const bnbBalance = this.userBalance.bnb.value;
                const usdtBalance = this.userBalance.usdt.value;
                const gymnetBalance = this.userBalance.gymnet.value;
                const utilityBalance = this.userBalance.utility.value;

                const gasFee = this.transactionObject.priceInfo.gasAmount.value;
                const totalPrice = this.transactionObject.priceInfo.totalAmount.value;

                const isGasFeeSufficient = bnbBalance >= gasFee;
                const isGasBackSufficient = usdtBalance >= BUSD_FEE_VALUE;
                const isGymnetSufficient = gymnetBalance >= totalPrice;
                const isUtilitySufficient = utilityBalance >= totalPrice;

                const internalMethod = {label: 'Internal wallet', value: PaymentMethodEnum.INTERNAL};

                let disabled = false;
                let disabledReason = null;
                let disabledTitle = null;

                if (isGymnetSufficient || isUtilitySufficient) {
                    if (!isGasFeeSufficient) {
                        if (!isGasBackSufficient) {
                            disabled = true;
                            disabledTitle = this.$t('Insufficient funds');
                            disabledReason = this.$t('Please note that AMOUNT_CURRENCY is required for gas fee', {
                                amount_currency: BUSD_FEE_VALUE + ' USDT ' + this.$t('Or').toLowerCase() + ' ' + gasFee + ' BNB',
                            });
                        } else {
                            this.specialConditions.isGasBack = true;
                        }
                    }
                } else {
                    disabled = true;
                    disabledTitle = this.$t('Insufficient funds');
                    disabledReason = this.$t('Please note that AMOUNT worth of CURRENCY is required to complete the transaction.', {
                        amount: totalPrice,
                        currency: 'GYMNET ' + this.$t('Or').toLowerCase() + ' Utility',
                    });
                }
                Object.assign(internalMethod, {disabled, disabledReason, disabledTitle});
                if (disabled) {
                    if (this.selectedPaymentMethod.value === PaymentMethodEnum.INTERNAL) {
                        this.selectedPaymentMethod = newPaymentMethods[0];
                    }
                    newPaymentMethods.push(internalMethod);
                } else {
                    if (!this.isDirty) {
                        this.selectedPaymentMethod = internalMethod;
                    }
                    newPaymentMethods.unshift(internalMethod);
                }
            }
            this.paymentMethodOptions = newPaymentMethods;
        }
    },
});
