
    export default {
        name: "DividerWithText",
        props: {
            alignSelf: {
                type: String,
                default: 'center',
            },
            color: {
                type: String,
                default: '#8C98A3',
            },
            fontSize: {
                type: String,
                default: '12px',
            },
            fontWeight: {
                type: String,
                default: '600',
            },
            lineHeight: {
                type: String,
                default: '18px',
            },
            letterSpacing: {
                type: String,
                default: '0em',
            },
            textGap: {
                type: String,
                default: '8px',
            },
            margin: {
                type: String,
                default: '0px 0px',
            },
            padding: {
                type: String,
                default: '0px 0px',
            },
            width: {
                type: String,
                default: '111px',
            },
            maxWidth: {
                type: String,
                default: '111px',
            },
            backgroundColor: {
                type: String,
                default: '#333333',
            },
        },
    }
