import { render, staticRenderFns } from "./DepositWeb2Modal.vue?vue&type=template&id=271396b6&scoped=true&"
import script from "./DepositWeb2Modal.vue?vue&type=script&lang=js&"
export * from "./DepositWeb2Modal.vue?vue&type=script&lang=js&"
import style0 from "./DepositWeb2Modal.vue?vue&type=style&index=0&id=271396b6&prod&lang=scss&"
import style1 from "./DepositWeb2Modal.vue?vue&type=style&index=1&id=271396b6&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "271396b6",
  null
  
)

export default component.exports