
import {defineComponent} from "vue";
import EthereumProvider from "@walletconnect/ethereum-provider/dist/types/EthereumProvider";
import ProductOwnerSelection from "../steps/product-owner-selection/ProductOwnerSelection.vue";
import PaymentMethodSelection from "../steps/payment-method-selection/PaymentMethodSelection.vue";
import Web3WalletConnection from "../steps/web3-wallet-connection/Web3WalletConnection.vue";
import CurrencySelection from "../steps/currency-selection/CurrencySelection.vue";
import InsufficientFunds from "../steps/insufficient-funds/InsufficientFunds.vue";
import FlowError from "../steps/flow-error/FlowError.vue";
import PaymentSuccessful from "../steps/payment-successful/PaymentSuccessful.vue";
import PurchaseSummary from "../steps/purchase-summary/PurchaseSummary.vue";
import ResultsCheckingError from '../steps/results-checking-error/ResultsCheckingError.vue';
import {MYSTERY_BOX_PURCHASE_STEPS} from "../../constants/MysteryBoxPurchaseSteps";
import BlurLoader from "~/components/utils/loader/blur-loader/BlurLoader.vue";
import StepIndicator from "~/components/utils/indicators/step-indicator/StepIndicator.vue";
import {MetaUser} from "~/core/models/MetaUser";
import {UserBalance} from "~/core/components/flows/mystery-box/models/UserBalance";
import WalletService from "~/pages/finances/wallet/services/WalletService";
import {MetaInventoryContainer} from "~/core/models/MetaInventoryContainer";
import {MetaWorldManager} from "~/core/services/map/MetaWorldManager";
import Asset from "~/pages/finances/wallet/models/Asset";
import MysteryBoxTx from "~/core/components/flows/mystery-box/models/MysteryBoxTx";
import Web3MysteryBoxTx from "~/core/components/flows/mystery-box/models/Web3MysteryBoxTx";
import {PriceInfo} from "~/core/components/flows/mystery-box/models/PriceInfo";
import {PaymentMethodEnum} from "~/core/components/flows/mystery-box/constants/PaymentMethodEnum";
import {PaymentSpecialConditions} from "~/core/components/flows/mystery-box/types/PaymentSpecialConditions";
import ContractService from "~/pages/finances/wallet/services/ContractService";
import {CurrencyName} from "~/pages/finances/wallet/models/CurrencyName";
import ExchangeService from "~/pages/finances/wallet/services/ExchangeService";
import {fromWei} from "~/core/helpers/GlobalHelpers";
import {FinishReasonEnum} from "~/core/components/flows/mystery-box/constants/FinishReasonEnum";
import {WALLET_ERROR_CODES} from "~/core/services/utils/Constants";
import {MysteryBoxPurchase} from "~/core/components/flows/mystery-box/types/MysteryBoxPurchase";
import { GOOGLE_ANALYTICS_EVENT_NAMES } from "~/constants/gtag-events";

export type PurchaseCore = {
    user: MetaUser,
    MYSTERY_BOX_PURCHASE_STEPS: typeof MYSTERY_BOX_PURCHASE_STEPS,
    currentStep: number,
    currentStepIndicatorNumber: number,
    isLoading: boolean,
    purchase: MysteryBoxPurchase,
    userBalance: UserBalance;
    transactionObject: MysteryBoxTx | Web3MysteryBoxTx,
    paymentMethod: PaymentMethodEnum,
    flowError: Error,
    createInitialTransaction: () => MysteryBoxTx,
    handleProductOwnerSelected: () => void,
    handlePaymentMethodSelected: (paymentMethod: PaymentMethodEnum, specialConditions: PaymentSpecialConditions) => void,
    handleWeb3WalletConnected: (address: string, provider: EthereumProvider) => void,
    handleCurrencySelected: () => void;
    handleTryTxAgain: () => void,
    handleSuccessfulPayment: () => void,
    handleGoToNFTs: () => void;
    handleBack: () => void,
    startLoading: () => void,
    stopLoading: (err?: unknown) => void,
    handleSeeStatistics: () => void,
    handleTryAnotherBox: () => void,
    handleContactSupport: () => void,
    handleResultCheckingError: () => void,
}

const BALANCE_UPDATE_INTERVAL = 5000;

export default defineComponent<keyof PurchaseCore, PurchaseCore>({
    name: "MysteryBoxPurchaseCore",
    components: {
        StepIndicator,
        BlurLoader,
        ProductOwnerSelection,
        PaymentMethodSelection,
        Web3WalletConnection,
        CurrencySelection,
        InsufficientFunds,
        PaymentSuccessful,
        FlowError,
        PurchaseSummary,
        ResultsCheckingError,
    },
    props: {
        user: {
            type: MetaUser,
            required: true,
        },
        purchase: {
            type: Object as () => MysteryBoxPurchase,
            required: true,
        },
    },
    emits: [
        'onStepChanged',
        'onFlowFinished',
        'onFinishReasonUpdate',
    ],
    data () {
        return {
            MYSTERY_BOX_PURCHASE_STEPS,
            currentStep: MYSTERY_BOX_PURCHASE_STEPS.SELECT_PRODUCT_OWNER,
            previousActiveStep: null,
            isLoading: false,
            userBalance: null,
            userBalanceUpdatePromise: null,
            userBalanceUpdateInterval: null,
            municipalityTx: null,
            transactionObject: (this as unknown as PurchaseCore).createInitialTransaction(),
            paymentMethod: null,
            web3Provider: null,
            flowError: null,
        };
    },
    computed: {
        currentStepIndicatorNumber (): number {
            if ([MYSTERY_BOX_PURCHASE_STEPS.SELECT_PRODUCT_OWNER].includes(this.currentStep)) {
                return 1;
            } else if ([MYSTERY_BOX_PURCHASE_STEPS.SELECT_PAYMENT_METHOD].includes(this.currentStep)) {
                return 2;
            } else if ([
                MYSTERY_BOX_PURCHASE_STEPS.SELECT_BALANCE,
                MYSTERY_BOX_PURCHASE_STEPS.INSUFFICIENT_FUNDS,
                MYSTERY_BOX_PURCHASE_STEPS.CONNECT_WALLET,
            ].includes(this.currentStep)) {
                return 3;
            } else if ([
                MYSTERY_BOX_PURCHASE_STEPS.PURCHASE_SUMMARY,
                MYSTERY_BOX_PURCHASE_STEPS.TRANSACTION_REJECTED,
            ].includes(this.currentStep)) {
                return 4;
            }
            return 0;
        },
    },
    watch: {
        currentStep: {
            handler (newStep, oldStep) {
                if (newStep !== oldStep) {
                    this.$emit('onStepChanged', newStep);
                    this.previousActiveStep = oldStep;
                }
            },
        },
    },
    mounted () {
        this.updateUserBalance();
        this.setUserBalanceUpdateInterval();
    },
    beforeUnmount () {
        clearInterval(this.userBalanceUpdateInterval);
    },
    methods: {
        async handleProductOwnerSelected () {
            await this.userBalanceUpdatePromise?.catch(() => {
            });
            this.currentStep = MYSTERY_BOX_PURCHASE_STEPS.SELECT_PAYMENT_METHOD;
        },
        async handlePaymentMethodSelected (
            paymentMethod: PaymentMethodEnum,
            specialConditions: PaymentSpecialConditions,
        ) {
            this.paymentMethod = paymentMethod;
            switch (paymentMethod) {
                case PaymentMethodEnum.INTERNAL:
                    this.currentStep = MYSTERY_BOX_PURCHASE_STEPS.SELECT_BALANCE;
                    break;
                case PaymentMethodEnum.WEB3_WALLET:
                    this.currentStep = MYSTERY_BOX_PURCHASE_STEPS.CONNECT_WALLET;
                    this.transactionObject = new Web3MysteryBoxTx(this.transactionObject);
                    break;
            }
        },
        async handleWeb3WalletConnected (address: string, provider: EthereumProvider) {
            if (this.web3Provider !== provider) {
                this.web3Provider = provider;
            }
            await this.updateWeb3WalletInfo([address]);
            this.stopLoading();
        },
        handleCurrencySelected () {
            this.currentStep = MYSTERY_BOX_PURCHASE_STEPS.PURCHASE_SUMMARY;
        },
        handleGoToNFTs () {
            this.$emit('onFlowFinished');
            this.$router.push('/nfts/my-nfts');
        },
        handleTryTxAgain () {
            this.$emit('onFinishReasonUpdate', FinishReasonEnum.CLOSED_BY_USER);
            if (this.previousActiveStep) {
                this.currentStep = this.previousActiveStep;
                this.previousActiveStep = null;
            } else {
                this.currentStep = MYSTERY_BOX_PURCHASE_STEPS.SELECT_PRODUCT_OWNER;
            }
        },
        handleSuccessfulPayment () {
            this.$emit('onFinishReasonUpdate', FinishReasonEnum.SUCCESS);
            const product = this.purchase.product;
            const transactionId = `MB-${String(new Date().valueOf())}`;
            const eventName = GOOGLE_ANALYTICS_EVENT_NAMES.PURCHASE;
            const purchaseName = `Mystery Box ${product.type} (${product.price.value} GYMNET)`;
            this.$gtag.event(eventName, {
                transaction_id: transactionId,
                value: Number(product.price.value),
                purchase_name: purchaseName,
                currency: "GYMNET",
                items: [{
                    item_name: purchaseName,
                    quantity: 1,
                    price: Number(product.price.value),
                }],
            });
            this.$gtag.event(GOOGLE_ANALYTICS_EVENT_NAMES.MYSTERY_BOX_PURCHASE_SUCCESS, {
                transaction_id: transactionId,
                purchase_name: purchaseName,
                purchase_price: Number(product.price.value),
            });
            this.currentStep = MYSTERY_BOX_PURCHASE_STEPS.PAYMENT_SUCCESS;
        },
        async handleBack () {
            this.startLoading();
            this.transactionObject.account = this.user.walletAddress ?? this.user.relatedWalletAddress;
            let transactionToCreateFrom = this.transactionObject;
            this.municipalityTx = MysteryBoxTx.fromMunicipalityTx(transactionToCreateFrom);
            await this.userBalanceUpdatePromise?.catch(() => {
            });
            await this.updateUserBalance();
            this.transactionObject = this.municipalityTx;
            this.paymentMethod = null;
            this.municipalityTx = null;
            switch (this.currentStep) {
                case MYSTERY_BOX_PURCHASE_STEPS.SELECT_PAYMENT_METHOD:
                case MYSTERY_BOX_PURCHASE_STEPS.PURCHASE_SUMMARY:
                case MYSTERY_BOX_PURCHASE_STEPS.INSUFFICIENT_FUNDS:
                    this.currentStep = MYSTERY_BOX_PURCHASE_STEPS.SELECT_PRODUCT_OWNER;
                    break;
                case MYSTERY_BOX_PURCHASE_STEPS.SELECT_BALANCE:
                case MYSTERY_BOX_PURCHASE_STEPS.CONNECT_WALLET:
                    this.currentStep = MYSTERY_BOX_PURCHASE_STEPS.SELECT_PAYMENT_METHOD;
                    break;
            }
            this.stopLoading();
        },
        async updateWeb3WalletInfo () {
            if (this.paymentMethod === PaymentMethodEnum.WEB3_WALLET) {
                try {
                    this.startLoading();
                    const metaverseId = this.$metaverseId();
                    const metaWorldManager = MetaWorldManager.sharedInstance();
                    const mysteryBoxContract = await ContractService.getContract('MysteryBox', metaWorldManager, metaverseId, true, this.web3Provider);
                    const GymnetContract = await ContractService.getContract('GymNetwork', metaWorldManager, metaverseId, true, this.web3Provider);
                    this.transactionObject._mysteryBox = mysteryBoxContract;
                    this.transactionObject._gymnet = GymnetContract;
                    await this.userBalanceUpdatePromise?.catch(() => {});
                    const userBalance = await this.updateUserBalance();
                    await this.transactionObject.estimateGas();
                    const priceInfo = this.transactionObject.priceInfo;

                    const gymnetBalance = userBalance.gymnet.value;
                    const utilityBalance = userBalance.utility.value;

                    const totalPrice = priceInfo.totalAmount.value;

                    const isGymnetSufficient = gymnetBalance >= totalPrice;
                    const isUtilitySufficient = utilityBalance >= totalPrice;

                    if (!isGymnetSufficient && !isUtilitySufficient) {
                        this.currentStep = MYSTERY_BOX_PURCHASE_STEPS.INSUFFICIENT_FUNDS;
                    } else {
                        this.currentStep = MYSTERY_BOX_PURCHASE_STEPS.SELECT_BALANCE;
                    }
                } finally {
                    this.stopLoading();
                }
            }
        },
        startLoading () {
            this.isLoading = true;
        },
        stopLoading (err?: unknown) {
            this.isLoading = false;
            if (err) {
                const code = (err as { code: number })?.code;
                if (code === WALLET_ERROR_CODES.USER_REJECTED) {
                    this.$emit('onFinishReasonUpdate', FinishReasonEnum.CLOSED_BY_USER);
                } else if (err) {
                    this.$emit('onFinishReasonUpdate', FinishReasonEnum.FAILED);
                }

                this.flowError = err;
                this.currentStep = MYSTERY_BOX_PURCHASE_STEPS.TRANSACTION_REJECTED;
            }
        },
        setUserBalanceUpdateInterval () {
            clearInterval(this.userBalanceUpdateInterval);
            this.userBalanceUpdateInterval = setInterval(this.updateUserBalance, BALANCE_UPDATE_INTERVAL);
        },
        async updateUserBalance () {
            try {
                const inventoryContainer = MetaInventoryContainer.sharedInstance();
                const metaWorldManager = MetaWorldManager.sharedInstance();
                const walletAddress = this.transactionObject.account;
                const assets = WalletService.getAssets(
                    inventoryContainer,
                    walletAddress,
                    metaWorldManager,
                    this.$metaverseId(),
                    true,
                );
                const utilityPromise = this.getUtilityAsset(
                    metaWorldManager,
                    inventoryContainer,
                    await ExchangeService.getRate("GYMNET"),
                );
                this.userBalanceUpdatePromise = Promise.all([assets, utilityPromise]);
                const internalAssets = await this.userBalanceUpdatePromise;
                this.userBalanceUpdatePromise = null;
                const [[bnb, _filecoin, gymnet, busd, _, usdt], utility] = internalAssets;
                this.userBalance = new UserBalance(
                    bnb,
                    gymnet,
                    busd,
                    utility,
                    usdt,
                );

                const userBalance = this.userBalance;
                const priceInfo = this.transactionObject.priceInfo;

                const gymnetBalance = userBalance.gymnet.value;
                const utilityBalance = userBalance.utility.value;

                const totalPrice = priceInfo.totalAmount.value;

                const isGymnetSufficient = gymnetBalance >= totalPrice;
                const isUtilitySufficient = utilityBalance >= totalPrice;

                if (this.currentStep === MYSTERY_BOX_PURCHASE_STEPS.SELECT_BALANCE ||
                this.currentStep === MYSTERY_BOX_PURCHASE_STEPS.INSUFFICIENT_FUNDS) {
                    if (!isGymnetSufficient && !isUtilitySufficient) {
                        this.currentStep = MYSTERY_BOX_PURCHASE_STEPS.INSUFFICIENT_FUNDS;
                    } else {
                        this.currentStep = MYSTERY_BOX_PURCHASE_STEPS.SELECT_BALANCE;
                    }
                }

                return this.userBalance;
            } catch (err) {
                console.log('Error updating balance');
                this.userBalanceUpdatePromise = null;
                return this.userBalance;
            }
        },
        async getUtilityAsset (
            metaWorldManager: MetaWorldManager,
            inventoryContainer: MetaInventoryContainer,
            gymnetRate: number,
        ) {
            const transactionSender = this.transactionObject.account.toLowerCase();
            const currentUserAddress = this.user.walletAddress ?? this.user.relatedWalletAddress;
            let utilityAmount = inventoryContainer?.minerRewardsData
                ?.minerRewards
                ?.minerRewardsUtilityInfo
                ?.realAvailable ?? 0;
            if (currentUserAddress.toLowerCase() !== transactionSender) {
                const utilityInfo = await this.$contracts[this.$metaverseId()].MiningSC.methods.utilInfo(transactionSender).call();
                utilityAmount = Number(fromWei(utilityInfo.available));
            }
            const utilityAsset = new Asset(
                null,
                "Utility",
                "Utility" as CurrencyName,
                require('~/assets/images/gymstreet/currencies/utility-balance.svg'),
                utilityAmount,
                gymnetRate,
                false,
                metaWorldManager,
            );
            return utilityAsset;
        },
        createInitialTransaction () {
            const self = this as any;
            const contracts = self.$contracts[self.$metaverseId()];
            const purchase = self.$props.purchase;
            const user = self.$props.user;
            const priceInfo = new PriceInfo(
                new Asset(
                    null,
                    "Tether USD",
                    'USDT',
                    require('~/assets/images/wallets/usdt.png'),
                    Number(purchase.product.price.value),
                    1,
                    false,
                    MetaWorldManager.sharedInstance(),
                    "Binance Smart Chain (BEP20)",
                ),
            );
            let transaction = new MysteryBoxTx(
                contracts.GymNetwork,
                contracts.MysteryBox,
                user.walletAddress ?? user.relatedWalletAddress,
                purchase.product,
                priceInfo,
            );

            if (!user.isFullyWeb2User) {
                transaction = new Web3MysteryBoxTx(transaction);
            }

            return transaction;

        },
        handleSeeStatistics () {
            this.$router.push('/promos/mystery-box');
            this.$emit('onFlowFinished');
        },
        async handleTryAnotherBox () {
            this.$emit('onFlowFinished');
            this.$gtag.event(GOOGLE_ANALYTICS_EVENT_NAMES.TRY_ANOTHER_MYSTERY_BOX);
            await this.$nextTick();
            await this.$store.dispatch('application/mystery-box/setMysteryBoxOptionsPopupVisible', true);
        },
        handleResultCheckingError () {
            this.$gtag.event(GOOGLE_ANALYTICS_EVENT_NAMES.MYSTERY_BOX_RESULT_CHECKING_PROBLEM);
            this.currentStep = MYSTERY_BOX_PURCHASE_STEPS.RESULT_CHECKING_ERROR;
        },
        handleContactSupport() {
            const txHashes = this.transactionObject.txHashes;
            const txHash = txHashes[txHashes.length - 1];
            const link = `mailto:support@gymstreet.io?subject=Mystery Box&body=I have a problem with my mystery box purchase [transaction hash: ${txHash}]. I didn't get results.`;
            window.open(link);
        },
    },
});

