
import GymNewButton from "~/components/utils/form-components/GymNewButton.vue";
import MysteryBoxTx from "~/core/components/flows/mystery-box/models/MysteryBoxTx";
import NftImageComponent from "~/core/components/flows/mystery-box/components/steps/purchase-summary/components/nft-image/NftImageComponent.vue";
import { MysteryBoxWonInfoKey } from "../../../constants/MysteryBoxWonInfoKey";
import { PRODUCT_TYPES } from "~/core/services/utils/Constants";

export default {
    name: 'PaymentSuccesful',
    components: {
        GymNewButton,
        NftImageComponent,
    },
    props: {
        transactionObject: {
            type: MysteryBoxTx,
            required: true,
        },
    },
    emits: ['onGoToNFTs', 'onTryAnotherBox', 'onSeeStatistics', 'onStopLoading', 'onResultCheckingError'],
    data() {
        return {
            wonPrize: null,
            isLoading: false,
        };
    },
    
    computed: {
        particleOptions () {
            return {
                fpsLimit: 60,
                fullScreen: {
                    enable: false,
                    zIndex: -1,
                },
                particles: {
                    color: {
                        value: [
                            '#FE4102',
                            '#E6E6E6',
                            '#4DD2D7',
                            '#FFD338',
                        ],
                    },
                    move: {
                        direction: 'bottom',
                        enable: true,
                        outModes: {
                            default: 'out',
                        },
                        size: true,
                        speed: {
                            min: 1,
                            max: 3,
                        },
                    },
                    number: {
                        value: 500,
                        density: {
                            enable: true,
                            area: 800,
                        },
                    },
                    opacity: {
                        value: 1,
                        animation: {
                            enable: false,
                            startValue: 'max',
                            destroy: 'min',
                            speed: 0.3,
                            sync: true,
                        },
                    },
                    rotate: {
                        value: {
                            min: 0,
                            max: 360,
                        },
                        direction: 'random',
                        move: true,
                        animation: {
                            enable: true,
                            speed: 60,
                        },
                    },
                    tilt: {
                        direction: 'random',
                        enable: true,
                        move: true,
                        value: {
                            min: 0,
                            max: 360,
                        },
                        animation: {
                            enable: true,
                            speed: 60,
                        },
                    },
                    shape: {
                        type: [
                            'circle',
                            'square',
                        ],
                        options: {},
                    },
                    size: {
                        value: {
                            min: 2,
                            max: 4,
                        },
                    },
                    roll: {
                        darken: {
                            enable: true,
                            value: 30,
                        },
                        enlighten: {
                            enable: true,
                            value: 30,
                        },
                        enable: true,
                        speed: {
                            min: 15,
                            max: 25,
                        },
                    },
                    wobble: {
                        distance: 30,
                        enable: true,
                        move: true,
                        speed: {
                            min: -15,
                            max: 15,
                        },
                    },
                },
            };
        },
    },
    async mounted() {
        try {
            this.isLoading = true;
            const MAXIMUM_CHECKING_ATTEMPTS = 10;
            let count = 0;
            while (count < MAXIMUM_CHECKING_ATTEMPTS) {
                try {
                    const result = await this.transactionObject.getGambleResult();
                    this.$emit('onStopLoading');
                    const prize = this.transactionObject.getPrizeById(Number(result.prizeId));
                    this.wonPrize = prize;
                    if (this.wonPrize) {
                        this.setResultToLocalStorage();
                    }
                    break;
                } catch (e) {
                    count++;
                    await new Promise(resolve => setTimeout(resolve, 1000));
                }
            }
            if (count === MAXIMUM_CHECKING_ATTEMPTS) {
                this.$emit('onResultCheckingError');
            }
        } catch (e) {
            this.$emit('onStopLoading', e);
        } finally {
            this.isLoading = false;
        }
    },
    methods: {
        handleGoToNFTs () {
            this.$emit('onGoToNFTs');
        },
        handleTryAnotherBox () {
            this.$emit('onTryAnotherBox');
        },
        handleSeeStatistics () {
            this.$emit('onSeeStatistics');
        },
        setResultToLocalStorage() {
            const transactionObject = this.transactionObject;
            const walletAddress = transactionObject.account.toLowerCase();
            const wonPrize = this.wonPrize;
            const data = JSON.parse(localStorage.getItem(MysteryBoxWonInfoKey)) || {};
            const userData = data[walletAddress] || {};
            if (!userData.resolved) {
                userData.wonBundle = wonPrize.nftType === PRODUCT_TYPES.BUNDLE;
            }
            data[walletAddress] = userData;
            localStorage.setItem(MysteryBoxWonInfoKey, JSON.stringify(data));
        },
    },
};
