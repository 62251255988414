import BigNumber from 'bignumber.js';
import { Contract } from 'web3-eth-contract';
import { MysteryBoxPaymentType } from '../constants/MysteryBoxPaymentType';
import { MysteryBox } from './MysteryBox';
import { PriceInfo } from './PriceInfo';
import { AllowanceInfo } from './AllowanceInfo';
import BNBAsset from '~/pages/finances/wallet/models/BNBAsset';
import WalletAddress from '~/pages/finances/wallet/models/WalletAddress';
import { fromWei, toBN } from '@/core/helpers/GlobalHelpers';
import ApiService from '~/core/services/api-interaction/ApiService';
import type { PurchaseTransaction } from '~/core/types/purchase-popup-2/PurchaseTransaction';
import { GlobalMakerService } from '~/core/services/GlobalMakerService';
import { MetaWorldManager } from '~/core/services/map/MetaWorldManager';
import Asset from '~/pages/finances/wallet/models/Asset';
import ExchangeService from '~/pages/finances/wallet/services/ExchangeService';
import { CurrencyName } from '~/pages/finances/wallet/models/CurrencyName';

const GAS_FEE_MULTIPLIER_FOR_INTERNAL = 1.3; // multiplier for gas fee for internal transactions, to sync with backend
export default class MysteryBoxTx implements PurchaseTransaction {
    protected readonly method: string = 'purchaseBox';
    protected cachedGasFee: BNBAsset | null = null;
    protected cachedGasPrice: BigNumber | null = null;
    protected lastAllowance: AllowanceInfo = null;
    protected txHashes: string[] = [];
    protected prizes: any[] = [];

    constructor (
        public _gymnet: Contract,
        public _mysteryBox: Contract,
        public account: WalletAddress,
        public product: MysteryBox,
        public priceInfo?: PriceInfo,
        public paymentType: MysteryBoxPaymentType | null = null,
    ) { }

    public setPaymentType (paymentType: MysteryBoxPaymentType) {
        this.paymentType = paymentType;
        const isUtility = this.paymentType === MysteryBoxPaymentType.UTILITY;
        const utilityIcon = require('~/assets/images/gymstreet/currencies/utility-balance.svg');
        const gymnetIcon = require("~/assets/images/icons/gymnet-icon.svg");
        const metaWorldManager = MetaWorldManager.sharedInstance();
        const totalAmount = new Asset(
            null,
            isUtility ? "Utility" : "Gym Network Token",
            (isUtility ? "Utility" : "GYMNET") as CurrencyName,
            isUtility ? utilityIcon : gymnetIcon,
            this.priceInfo.totalAmount.value,
            this.priceInfo.totalAmount.rate,
            false,
            metaWorldManager,
        );
        this.priceInfo = new PriceInfo(
            totalAmount,
            this.priceInfo.gasAmount,
        );
    }

    get args () {
        return [
            this.product.type,
            this.paymentType,
        ];
    }

    async estimateGas (): Promise<BNBAsset> {
        const metaWorldManager = MetaWorldManager.sharedInstance();
        const { account: outgoingAccount } = this;
        if(this.cachedGasFee) {
            return this.cachedGasFee;
        }
        let estimatedGas = 0;
        try {
            estimatedGas = await this._mysteryBox.methods[this.method](...this.args)
                .estimateGas({
                    from: outgoingAccount,
                });
        } catch (e) {
            console.log('Gas estimation failed!');
            console.log(e);
        }
        const readWeb3 = metaWorldManager.readWeb3();
        const gasPrice = this.cachedGasPrice ?? new BigNumber(await readWeb3.eth.getGasPrice());
        const bigNumberEstimatedGas = toBN(Math.ceil(estimatedGas * GAS_FEE_MULTIPLIER_FOR_INTERNAL));
        const gasInBNB = bigNumberEstimatedGas.mul(toBN(gasPrice));
        const gasInBNBValue = fromWei(gasInBNB.toString());
        const gasAmount = new BNBAsset(Number(gasInBNBValue), 0, false, metaWorldManager);
        if (this.priceInfo) {
            this.priceInfo.gasAmount = gasAmount;
        }
        this.cachedGasFee = gasAmount;
        this.cachedGasPrice = gasPrice;
        return this.priceInfo.gasAmount;
    }

    async send () {
        const transmissionData = {
            to: this._mysteryBox.options.address,
            value: 0,
            data: this._mysteryBox.methods[this.method](...this.args).encodeABI(),
        };
        const response = await ApiService.post('internal-wallet/send-tx', transmissionData);
        const { tx_hash: txHash } = response.data;
        this.txHashes.push(txHash);
        return txHash;
    }

    async approve () {
        const mysteryBoxAddress = this._mysteryBox.options.address;
        const allowanceAmount = 1e24.toLocaleString('fullwide', { useGrouping: false });
        const transactionObject = { // TODO refactor this
            to: this._gymnet.options.address,
            value: 0,
            data: this._gymnet.methods.approve(mysteryBoxAddress, allowanceAmount).encodeABI(),
        };

        await GlobalMakerService.$store.dispatch('application/driver/internalWalletSendTx', transactionObject);
        return true;
    }

    async checkAllowance (amount: number) {
        if (this.paymentType === MysteryBoxPaymentType.GYMNET) {
            const { account: outgoingAccount } = this;
            const allowanceWei = await this._gymnet.methods
                .allowance(outgoingAccount, this._mysteryBox.options.address)
                .call();
            const allowanceEth = fromWei(allowanceWei) as string;
            const isSufficient = parseFloat(allowanceEth) >= amount;
            this.lastAllowance = new AllowanceInfo(
                Number(allowanceEth),
                allowanceWei,
                amount,
                isSufficient,
            );
            return isSufficient;
        }
        return true;
    }

    public clearCache () {
        this.cachedGasFee = null;
        this.cachedGasPrice = null;
    }

    public async updatePriceInfo () {
        const metaWorldManager = MetaWorldManager.sharedInstance();
        const gymnetRate = await ExchangeService.getRate("GYMNET");
        const isUtility = this.paymentType === MysteryBoxPaymentType.UTILITY;
        const utilityIcon = require('~/assets/images/gymstreet/currencies/utility-balance.svg');
        const gymnetIcon = require("~/assets/images/icons/gymnet-icon.svg");
        const gymnetOrUtilityPriceOfProduct = this.product.price.value;
        const totalAmount = new Asset(
            null,
            isUtility ? "Utility" : "Gym Network Token",
            (isUtility ? "Utility" : "GYMNET") as CurrencyName,
            isUtility ? utilityIcon : gymnetIcon,
            gymnetOrUtilityPriceOfProduct,
            gymnetRate,
            false,
            metaWorldManager,
        );
        this.priceInfo = new PriceInfo(
            totalAmount,
            this.priceInfo.gasAmount,
        );
        return this.priceInfo;
    }

    public setAccount (account: WalletAddress) {
        this.account = account;
    }

    public async getGambleResult (index = -1) {
        if (this.txHashes.length === 0) {
            return null;
        }
        if (index < 0) {
            index = this.txHashes.length - 1;
        }
        if (index >= this.txHashes.length) {
            return null;
        }
        if (this.prizes[index]) {
            return this.prizes[index];
        }
        const metaWorldManager = MetaWorldManager.sharedInstance();
        const mysteryBoxContract = metaWorldManager.contracts.MysteryBox;
        const txHash = this.txHashes[index];
        const web3 = MetaWorldManager.sharedInstance().readWeb3();
        const txData = await web3.eth.getTransaction(txHash);
        const blockNumber = txData.blockNumber;
        const purchaseEvent = await mysteryBoxContract.getPastEvents('MysteryBoxPurchased', {
            filter: { user: this.account },
            fromBlock: blockNumber,
            toBlock: blockNumber,
        });
        const purchaseEventData = purchaseEvent[0].returnValues;
        const MAXIMUM_COUNT_TO_CHECK_PRIZE = 10;
        let prizeWon = null;
        let count = 0;
        while (!prizeWon) {
            if (count > MAXIMUM_COUNT_TO_CHECK_PRIZE) {
                throw new Error('Cannot get prize won');
            }
            const prizeWonEvent = await mysteryBoxContract.getPastEvents('PrizeWon', {
                filter: { user: this.account, requestId: purchaseEventData.requestId },
                fromBlock: blockNumber,
                toBlock: 'latest',
            });
            if (prizeWonEvent.length > 0) {
                const prizeWonEventData = prizeWonEvent[0].returnValues;
                prizeWon = prizeWonEventData;
            } else {
                await new Promise(resolve => setTimeout(resolve, 1000));
            }
            count++;
        }
        this.prizes[index] = prizeWon;
        return prizeWon;
    }

    public getPrizeById(prizeId: number) {
        return this.product.prizeList.find((prize) => prize.id === prizeId);
    }

    get boxType () {
        return this.product.type;
    }

    get isUtility () {
        return this.paymentType === MysteryBoxPaymentType.UTILITY;
    }

    get isGymnet () {
        return this.paymentType === MysteryBoxPaymentType.GYMNET;
    }

    get isEnabled () {
        if (this.isUtility) {
            return true;
        }
        return this.lastAllowance?.isSufficient;
    }

    public static fromMunicipalityTx (tx: MysteryBoxTx) {
        const municipalityTx = new MysteryBoxTx(
            tx._gymnet,
            tx._mysteryBox,
            tx.account,
            tx.product,
            tx.priceInfo,
            tx.paymentType,
        );
        return municipalityTx;
    }
}
