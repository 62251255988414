
// Derived from Exception, is thrown when a chain change has been aborted by the user
class ChainChangeError extends Error {
    constructor(message) {
        super(message);
        this.name = 'ChainChangeError';
    }
}

export default ChainChangeError;
