
import {mapGetters} from "vuex";

export default {
    name: "GlobalPopups",
    computed: {
        ...mapGetters({
            authUser: "application/driver/authUser",
            isShowCashFTModal: "cashFT/cashFT-KYC-verification/isShowCashFTModal",
            showModalCardType: "cashFT/cashFT-KYC-verification/showModalCardType",
        })
    }
}
