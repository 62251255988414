// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../../../assets/images/icons/copy-blue.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../../../../assets/images/icons/upgraded.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".total-line[data-v-76919432]{display:flex;flex-flow:row;align-items:center;font-weight:400;font-size:16px;line-height:24px;text-align:right;color:#1c2734;justify-content:flex-end}.total-line-title[data-v-76919432]{font-size:16px}.token-icon[data-v-76919432]{width:24px;height:24px;margin-right:4px;margin-left:8px}.usd-line[data-v-76919432]{font-size:14px;font-weight:400;color:#858f9b;text-align:right}.copy-token-price[data-v-76919432]{padding:0;border:none;display:flex;align-items:center;margin-left:4px;width:18px;height:18px;background:rgba(0,0,0,0) url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:100%}.copy-token-price[data-v-76919432]:focus{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
