
import GymCountdown from "@/components/utils/GymCountdown.vue";
import GymNewButton from '~/components/utils/form-components/GymNewButton.vue';
import { METAVERSE_IDS } from "~/core/services/utils/MetaverseConstants";
import { isMobileDevice } from '@/core/helpers/GlobalHelpers';
import { mapGetters } from "vuex";

export default {
    name: "SixDayIncentivePopup",
    components: {
        GymCountdown,
        GymNewButton,
    },
    emits: ['goToSpecialOffers'],
    data() {
        return {
            isMobile: isMobileDevice(),
            resizeHandler: () => this.isMobile = isMobileDevice(),
            isPopupVisible: false,
        };
    },
    computed: {
        ...mapGetters('application/driver', ['authUser']),
        ...mapGetters('map/mapModule', ['metaverseId']),
        popupCustomClass() {
            let customClass = 'six-day-incentive-popup ';
            if(this.isMobile) {
                customClass += 'six-day-inventive-popup__mobile ';
            }
            if (!this.isMobile) {
                customClass += 'gym-popup';
            }
            return customClass;
        },
        isGymStreet() {
            return this.metaverseId === METAVERSE_IDS.GYMSTREET
        },
        showPopup() {
            return this.authUser && this.authUser.isRoleSelected;
        }
    },
    watch: {
        authUser: {
            handler(val, oldVal) {
                this.isPopupVisible = !!val && val.isSixDayIncentiveActive;
            },
        },
        metaverseId: {
            handler(val, oldVal) {
                this.isPopupVisible = !!this.authUser && this.authUser.isSixDayIncentiveActive;
            },
        },
        isPopupVisible(val) {
            if (val) {
                window.addEventListener('resize', this.resizeHandler);
                this.isMobile = isMobileDevice();
            } else {
                window.removeEventListener('resize', this.resizeHandler);
            }
        },
    },
    mounted() {
        this.isPopupVisible = !!this.authUser && this.authUser.isSixDayIncentiveActive;
    },
    methods: {
        handleClosePopup() {
            this.isPopupVisible = false;
        },
        handleGoToSpecialOffers() {
            this.$emit('goToSpecialOffers');
            this.isPopupVisible = false;
        }
    },
}
