import ApiService from "~/core/services/api-interaction/ApiService";
import { getClientRuntimeConfig } from "~/core/helpers/GlobalHelpers";

const $config = getClientRuntimeConfig();
const BINARY_TREE_API = `${$config.binaryTreeApi}/api`;
const BINARY_TREE_API_USERS = `${$config.binaryTreeApi}/api/users`;

export class BinaryTreeService {
    private static sponsorUsername: string;

    static async getBinaryTree () {
        try {
            const responseBinaryData = await ApiService.get(`${ BINARY_TREE_API_USERS }/binary-tree`);

            return responseBinaryData?.data;

        } catch (err) {
            throw new Error(err);
        }
    }

    static async getLastLeg (legPosition) {
        try {
            const responseLastData = await ApiService.get(`${ BINARY_TREE_API_USERS }/binary-tree/last-${legPosition}`);

            return responseLastData?.data;

        } catch (err) {
            throw new Error(err);
        }
    }

    static async getItemsBySearch (searchItem) {
        try {
            const responseBinaryData = await ApiService.get(`${ BINARY_TREE_API_USERS }/binary-tree/search/${searchItem}`);

            return responseBinaryData?.data;

        } catch (err) {
            throw new Error(err);
        }
    }

    static async validateReferralId(referralId: string): Promise<boolean> {
        try {
            const response = await ApiService.query(`${BINARY_TREE_API}/referr/check`, {
                params: { referr: referralId }
            });
            BinaryTreeService.sponsorUsername = response.data?.sponsor_username || '';
            return !!response?.data?.validity;
        } catch (err) {
            console.error('BinaryTreeService validateReferralId', err);
            return false;
        }
    }
}
