
export default {
    name: 'SupportButton',
    data() {
        return {
            isChatOpen: false,
            isLoaded: false,
        }
    },
    methods: {
        toggleMessenger() {
            if (this.isChatOpen) {
                window.zE('messenger', 'close');
            } else {
                window.zE('messenger', 'open');
            }
        },
    },
    async mounted() {
        await this.$zendesk;
        this.isLoaded = true;
        window.zE('messenger:on', 'open', () => {this.isChatOpen = true});
        window.zE('messenger:on', 'close', () => {this.isChatOpen = false});
    },
}
