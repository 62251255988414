import APIServiceError from "~/core/helpers/APIServiceError";
import { SubscriptionServiceException } from "../exceptions/SubscriptionServiceException";
import ApiService from "~/core/services/api-interaction/ApiService";
import { getClientRuntimeConfig } from "~/core/helpers/GlobalHelpers";
import {GlobalMakerService} from "~/core/services/GlobalMakerService";

const $config = getClientRuntimeConfig();

const BINARY_API = `${$config.binaryTreeApi}/api`;
const USER_NOT_FOUND_CODE = 400;

export class SubscriptionService {
    public static async getSubscriptionActivities(page: number, perPage: number) {
        try {
            const response = await ApiService.query(`${BINARY_API}/statistics/subscription-point`, {
                params: {
                    page,
                    perPage,
                }
            });
            response.data.data = response.data.data.map((item: any) => {
                return {
                    id: item.id,
                    type: item.type,
                    balance_before: item.balanceBefore,
                    balance_after: item.balanceAfter,
                    created_at: item.createdAt,
                    balance_change: item.balanceChange,
                };
            });
            return response.data;
        } catch (error: unknown) {
            if(error instanceof APIServiceError) {
                throw new SubscriptionServiceException('Failed to get Product Autoship activities', error);
            }
            throw error;
        }
    }

    public static async getSubscriptionPurchasesHistory(page: number, perPage: number) {
        try {
            const response = await ApiService.query(`${BINARY_API}/subscription/history`, {
                params: {
                    page,
                    perPage,
                }
            });
            return response.data;
        } catch (error: unknown) {
            if(error instanceof APIServiceError) {
                throw new SubscriptionServiceException('Failed to get Product Autoship history', error);
            }
            throw error;
        }
    }

    public static async getSubscriptionPoints() {
        try {
            const response = await ApiService.get(BINARY_API, 'subscription/subscription-points-balance');
            GlobalMakerService.$store.dispatch('application/driver/setBinaryAPIStatus', false);
            return response.data.balance;
        } catch (error: unknown) {
            if(error instanceof APIServiceError) {
                if(error.httpErrorCode === USER_NOT_FOUND_CODE) {
                    return 0;
                }
                GlobalMakerService.$store.dispatch('application/driver/setBinaryAPIStatus', true);
                throw new SubscriptionServiceException('Failed to get Product Autoship points balance', error);
            }
            throw error;
        }
    }

    public static async* getSubscriptionPointUpdateStream(
        interval: number,
        stillRunning: () => boolean,
    ) {
        let subscriptionPoints = 0;
        while(stillRunning()) {
            try {
                subscriptionPoints = await this.getSubscriptionPoints();
            } catch { /* ignore */ } finally {
                yield subscriptionPoints;
            }
            await new Promise(resolve => setTimeout(resolve, interval));
        }
    }
}
