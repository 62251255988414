
import { mapGetters } from "vuex";
import { MainMenuConfig, PartnerAreaMenuConfig } from "@/core/config/MenuConfig";
import { Menu, Submenu, MenuItem } from 'element-ui';
import { PartnerAreaPages } from "@/constants/constants";
import MenuCountdown from "@/components/countdowns/menu-countdown/MenuCountdown.vue";
import 'element-ui/lib/theme-chalk/index.css';
import GYMMenuItem from "~/components/aside/menu/GYMMenuItem.vue";
import UsernameStatusContent from "~/components/aside/menu/UsernameStatusContent.vue";

export default {
    name: "GYMMenu",
    components: {
        UsernameStatusContent,
        GYMMenuItem,
        MenuCountdown,
        'el-menu': Menu,
        'el-sub-menu': Submenu,
        'el-menu-item': MenuItem,
    },
    props: ['isPartnerMenu', 'handleSwitchPartnerAreaBtn'],
    data () {
        return {
            script: require("@/assets/images/icons/asideIcons.svg"),
        };
    },
    methods: {
        hasActiveChildren (match) {
            return this.$route.path.includes(match) || this.$route.path.startsWith(match);
        },
    },
    computed: {
        ...mapGetters({
            authUser: "application/driver/authUser",
        }),
        ...mapGetters('map/mapModule', ['metaverseId']),
        menuConfig () {
            return this.$props.isPartnerMenu ? PartnerAreaMenuConfig() : MainMenuConfig();
        },
    },
    watch: {
        '$route.path': {
            handler () {
                if (this.$props.isPartnerMenu && !PartnerAreaPages?.includes(this.$route.name)) {
                    this.$emit('handleSwitchPartnerAreaBtn', true);
                }
            },
        },
    },
};
