

import GymNewButton from "~/components/utils/form-components/GymNewButton.vue";
import NotificationDropdownItem from "@/components/header/components/NotificationDropdownItem";
import {MetaNotificationsManager} from "@/core/services/notifications/MetaNotificationsManager";
import {MetaNotificationsContainer} from "@/core/services/notifications/MetaNotificationsContainer";

export default {
    name: "NotificationDropdown",
    components: {NotificationDropdownItem, GymNewButton},
    props: {
        notifications: Array,
        newNotifications: Array,
    },
    watch: {
        newNotifications(val) {
            if (val && val.length > 0) {
                this.totalNotificationCount += val.length;
            }
        }
    },
    data() {
        return {
            offset: 0,
            count: 10,
            isLoading: false,
            totalNotificationCount: 0,
            isNotificationsHandlerVisible: false,
        }
    },
    methods: {
        closeDropdown() {
            if (this.$refs.notificationDropdown) {
                this.$refs.notificationDropdown.classList.remove('show');
            }
            MetaNotificationsContainer.sharedInstance().emptyNewNotifications();
        },
        async fetchNotificationsHistory() {
            this.isNotificationsHandlerVisible = false;
            if(this.$refs.notificationsContainer) {
                this.$refs.notificationsContainer.scrollTop = 0;
            }
            MetaNotificationsContainer.sharedInstance().emptyNewNotifications();
            this.offset = 0;
            let ntf = await MetaNotificationsManager.fetchNotificationsHistory();
            if (ntf) {
                this.totalNotificationCount = Number(ntf.total);
                MetaNotificationsContainer.sharedInstance().setNotificationsHistory(ntf);
            }
        },
        async loadNotifications() {
            this.isLoading = true;
            this.offset = this.notifications.length;
            let ntf = await MetaNotificationsManager.fetchNotificationsHistory(this.offset, this.count);
            if (ntf) {
                this.totalNotificationCount = Number(ntf.total);
                MetaNotificationsContainer.sharedInstance().addNotificationsToHistory(ntf);
            }
            this.isLoading = false;
        },
        onScroll(event) {
            this.isNotificationsHandlerVisible = event.target.scrollTop > 60;
            if(event.target.scrollTop <= 60) {
                MetaNotificationsContainer.sharedInstance().emptyNewNotifications();
            }
        },
        readNewNotifications() {
            if(this.$refs.notificationsContainer) {
                this.$refs.notificationsContainer.scrollTop = 0;
            }
            MetaNotificationsContainer.sharedInstance().emptyNewNotifications();
        }
    }
}
