import Asset from "~/pages/finances/wallet/models/Asset";

export class UserBalance {
    constructor(
        public readonly bnb: Asset,
        public readonly gymnet: Asset,
        public readonly busd: Asset,
        public readonly utility: Asset,
        public readonly usdt: Asset,
    ) {}
}
