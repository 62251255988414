
    import GymNewButton from '~/components/utils/form-components/GymNewButton.vue';
    import createConfirmValidator from '@/core/validators/confirm';
    import ERROR_MESSAGES from '@/core/validators/error-messages';
    import AuthService from '~/core/services/api-interaction/AuthService';
    import { emailExists } from '~/core/validators/email';

    export default {
        name: "ChangeEmail",
        components: {
            GymNewButton,
        },
        emits: ['onError', 'onLoading', 'onEmailChange'],
        props: {
            isMobile: {
                type: Boolean,
                default: false
            },
            initialFormData: {
                type: Object,
                default: null,
            },
        },
        data() {
            const changeEmailForm = {
                email: '',
                confirmEmail: '',
            };
            const confirmEmailValidator = createConfirmValidator('email', changeEmailForm, this.$t(ERROR_MESSAGES.EMAIL_ERR.CONFIRM));
            return {
                changeEmailForm,
                validationRules: {
                    email: [
                        { required: true, message: this.$t(ERROR_MESSAGES.EMAIL_ERR.REQUIRED), trigger: 'blur' },
                        { type: 'email', message: this.$t(ERROR_MESSAGES.EMAIL_ERR.INVALID), trigger: ['blur', 'change'] },
                        { validator: emailExists, trigger: ['blur', 'change']},
                    ],
                    confirmEmail: [
                        { validator: confirmEmailValidator, message: this.$t(ERROR_MESSAGES.EMAIL_ERR.CONFIRM), trigger: ['blur', 'change'] },
                    ],
                },
                areActionsDisabled: false,
                isConfirmButtonDisabled: true,
            }
        },
        mounted() {
            if (this.initialFormData) {
                this.changeEmailForm = this.initialFormData;
            }
        },
        methods: {
            changeEmail() {
                this.$refs.changeEmailForm.validate().then(async valid => {
                    if(valid) {
                        this.startLoading();
                        try {
                            await AuthService.changeEmail(this.changeEmailForm.email);
                            this.$store.commit('application/driver/UPDATE_AUTH_USER', {
                                email: this.changeEmailForm.email,
                                lastEmailSent: new Date().toISOString(),
                            });
                            this.$auth.setUser({
                                ...this.$auth.user,
                                email: this.changeEmailForm.email,
                                last_email_sent: new Date().toISOString(),
                            });
                            this.$emit('onEmailChange', this.changeEmailForm);
                        } catch (err) {
                            this.onError(err);
                        }
                    }
                });
            },
            startLoading() {
                this.areActionsDisabled = true;
                this.$emit('onLoading');
            },
            onValidate(field, isValid, validationRules) {
                this.isConfirmButtonDisabled = !isValid;
            },
            onError(errorObject) {
                this.areActionsDisabled = false;
                this.$emit('onError', {
                    error: errorObject,
                    popupData: {
                        title: "Couldn't change email",
                        message: errorObject.message,
                    }
                });
            }
        },
    }
