import { RawExternalWalletPayment } from "~/core/types/purchase-popup-2/RawExternalWalletPayment";
import { PriceInfo } from "./PriceInfo";
import TokenAsset from "./TokenAsset";
import { CryptoCurrency } from "~/core/types/purchase-popup-2/CryptoCurrency";
import { getClientRuntimeConfig } from "~/core/helpers/GlobalHelpers";
import { PaymentStatus } from "~/core/types/purchase-popup-2/PaymentStatus";

const $config = getClientRuntimeConfig();
const EXTERNAL_WALLET_PURCHASE_TIME_MS = $config.externalWalletPurchaseTimeMS ? Number($config.externalWalletPurchaseTimeMS) : 3 * 60 * 60 * 1000;

export class ExternalWalletPayment {
    public priceInfo: PriceInfo;
    public canRequestAnotherPayment = true;

    constructor(
        public rawPaymentData: RawExternalWalletPayment,
        public cryptocurrency: TokenAsset,
        public cryptocurrencyObject: CryptoCurrency,
    ) {
        const amountWithFee = Number(rawPaymentData.fee_included_amount);
        const amount = Number(rawPaymentData.amount);
        const fee = amountWithFee - amount;
        this.priceInfo = new PriceInfo(
            cryptocurrency.copy(amount),
            null,
            null,
            null,
            cryptocurrency.copy(fee),
        );
    }

    public get totalAmountAsset() {
        const totalAmount = this.priceInfo.totalAmount;
        const fee = this.priceInfo.externalFee;
        return totalAmount.copy(totalAmount.value + fee.value);
    }

    public get missingAmountAsset() {
        const deviationAmount = Number(this.rawPaymentData.deviation_amount);
        const totalAmount = this.totalAmountAsset;
        if(deviationAmount === 0) {
            return totalAmount;
        } else if (deviationAmount > 0) {
            return totalAmount.copy(0);
        }
        return totalAmount.copy(-deviationAmount);
    }

    public get receivedAmountAsset() {
        const deviationAmount = Number(this.rawPaymentData.deviation_amount);
        const totalAmount = this.totalAmountAsset;
        if(deviationAmount === 0) {
            return totalAmount.copy(0);
        }
        return totalAmount.copy(totalAmount.value + deviationAmount);
    }

    public get endDate() {
        const endDate = new Date(this.rawPaymentData.created_at);
        return endDate.getTime() + EXTERNAL_WALLET_PURCHASE_TIME_MS;
    }

    public get paymentId() {
        return this.rawPaymentData.payment_uid;
    }

    public get paymentAddress() {
        return this.rawPaymentData.payment_wallet_address;
    }

    public get isExpired() {
        return this.rawPaymentData.status === PaymentStatus.EXPIRED;
    }

    public get isCompleted() {
        return this.rawPaymentData.status === PaymentStatus.COMPLETED;
    }

    public get isCreated() {
        return this.rawPaymentData.status === PaymentStatus.CREATED;
    }

    public get isMissingAmount() {
        return this.rawPaymentData.status === PaymentStatus.CREATED && Number(this.rawPaymentData.deviation_amount) < 0;
    }

    public get owner() {
        return this.rawPaymentData.user_wallet_address?.toLowerCase();
    }

    public get bundleType() {
        return this.rawPaymentData.bundle_sc_id;
    }

    public get productType() {
        return this.rawPaymentData.product_type;
    }

    public get quantity() {
        return Number(this.rawPaymentData.quantity);
    }

    public static fromPaymentAndNewRawData(payment: ExternalWalletPayment, rawData: RawExternalWalletPayment) {
        return new ExternalWalletPayment(
            rawData,
            payment.cryptocurrency,
            payment.cryptocurrencyObject,
        );
    }
}
