
import { defineComponent } from "vue";
import {mapGetters, mapActions} from "vuex";
import {isMobileDevice, toCurrencyFormat} from "~/core/helpers/GlobalHelpers";
import GymNewButton from "~/components/utils/form-components/GymNewButton.vue";
import CustomLoader from "~/components/utils/CustomLoader.vue";
import { MysteryBoxPurchase } from "~/core/components/flows/mystery-box/types/MysteryBoxPurchase";
import {MetaInventoryContainer} from "~/core/models/MetaInventoryContainer";
import {AUTH_FLOW_STEPS} from "~/core/services/utils/Constants";
import { GOOGLE_ANALYTICS_EVENT_NAMES } from "~/constants/gtag-events";
const MOBILE_BREAKPOINT = 992;

export default defineComponent({
    name: "MysteryBoxOptionsPopup",
    components: {
        GymNewButton,
        CustomLoader
    },
    props: {
        popupVisible: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            activeBox: 0,
            isMobile: false,
            showDetails: false,
            toCurrencyFormat
        };
    },
    computed: {
        ...mapGetters({
            authUser: 'application/driver/authUser',
            mysteryBoxes: 'application/mystery-box/mysteryBoxes',
            isMysteryBoxesLoading: 'application/mystery-box/isMysteryBoxesLoading',
        }),
        isInsufficientFunds() {
            const mic = MetaInventoryContainer.sharedInstance();
            return mic.minerRewardsData
                        ?.minerRewards
                        ?.minerRewardsUtilityInfo
                        ?.realAvailable < this.mysteryBoxes[this.activeBox].price.value &&
                    mic.userCryptoBalance?.gymnet < this.mysteryBoxes[this.activeBox].price.value;
        },
    },
    mounted() {
        this.$gtag.event(GOOGLE_ANALYTICS_EVENT_NAMES.OPEN_MYSTERY_BOX_OPTIONS);
        this.isMobile = isMobileDevice(MOBILE_BREAKPOINT);
        window.addEventListener('resize', this.onResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
    methods: {
        ...mapActions({
            showMysteryBoxFlow: 'application/mystery-box/show',
        }),
        handleCloseOptionsPopup() {
            this.$store.dispatch('application/mystery-box/setMysteryBoxOptionsPopupVisible', false);
        },
        handleSelectBox(id) {
            this.activeBox = id;
            this.showDetails = true;
        },
        onResize() {
            this.isMobile = isMobileDevice(MOBILE_BREAKPOINT);
        },
        async handleBuyBox() {
            this.$gtag.event(GOOGLE_ANALYTICS_EVENT_NAMES.BUY_MYSTERY_BOX, {
                boxId: this.mysteryBoxes[this.activeBox].type,
                price: this.mysteryBoxes[this.activeBox].price.value
            });
            if (this.authUser) {
                const purchaseObject: MysteryBoxPurchase = {
                    product: this.mysteryBoxes[this.activeBox],
                    canBuyFor: (forMe) => !!forMe,
                    infoTexts: [],
                };
                this.showMysteryBoxFlow(purchaseObject);
                this.handleCloseOptionsPopup();
            } else {
                this.handleCloseOptionsPopup();
                await this.$store.dispatch('application/driver/setAuthStep', AUTH_FLOW_STEPS.SIGN_IN_WITH_EMAIL);
                await this.$store.dispatch('application/popup-control/changeConnectWalletPopupStatus', true);
            }
        },
    },
});
