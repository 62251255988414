import ApiService from "~/core/services/api-interaction/ApiService";
import {MetaNotificationsContainer} from "~/core/services/notifications/MetaNotificationsContainer";

export class MetaNotificationsManager {
    static async fetchNotificationsHistory(offset = 0, count = 10) {
        ApiService.setHeader();
        const url = `user/notifications/history?count=${count}&offset=${offset}`;
        let notificationsResponse = await ApiService.get(url);
        let res = null;
        if (notificationsResponse.data) {
            res = {
                notifications: notificationsResponse.data.notifications,
                total: notificationsResponse.data.total,
            };
        }

        return res;
    }

    static async fetchNewNotifications() {
        let notificationsResponse = await ApiService.get('user/notifications/poll');
        if (notificationsResponse.data) {
            MetaNotificationsContainer.sharedInstance().handleNewNotification({notifications: notificationsResponse.data});
        }
    }
}
