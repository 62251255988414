const state = () => {
    return {
        successPopupData: null,
        comfirmationPopupData: null,
        loadingPopupData: null,
        isConnectWalletPopupVisible: false,
        isMetaMaskInstallationPopupVisible: false,
        affiliateTmsPopupData: null,
        claimWeb2PopupData: null,
        isWeb2ClaimPopupVisible: false,
        contractNameForTransaction: null,
        isWeb2AllowancePopupVisible: false,
        allowanceContractAddress: null,
        isWeb2SubscriptionModalVisible: false,
        subscriptionWeb2PopupData: null,
        isWeb2DepositToSPModalVisible: false,
        isWeb2DepositToSPModalVisibleLockedUnlocked: false,
        depositToSPWeb2PopupData: null,
        depositToSPWeb2PopupDataLockedUnlocked: null,
        cashFTCardMinAmount: 0
    };
};

// Actions
const actions = {
    async hideGlobalPopup ({commit}) {
        commit("SET_SUCCESS_POPUP_DATA", null);
    },
    async showComfirmationPopup ({commit, dispatch}, payload) {
        commit("SET_COMFIRMATION_POPUP_DATA", {
            title: payload.title,
            message: payload.message,
            type: payload.type,
            showIcon: payload.showIcon,
            isVisible: true,
            buttons: payload.buttons,
        });
    },
    showLoadingPopup ({commit}, payload) {
        const popupData = !payload ?
            {isVisible: true} :
            {
                title: payload.title,
                message: payload.message,
                isVisible: true,
            };
        commit("SET_LOADING_POPUP_DATA", popupData);
    },
    hideLoadingPopup ({commit}) {
        commit("SET_LOADING_POPUP_DATA", null);
    },
    async changeConnectWalletPopupStatus ({commit, dispatch, state}, visible) {
        commit('SET_CONNECT_WALLET_POPUP_STATUS', visible);
    },
    openMetaMaskInstallationPopup ({commit, dispatch, state}, visible) {
        commit('SET_META_MASK_INSTALLATION_POPUP_STATUS', visible);
    },
    setAffiliateTmsPopupData ({commit, dispatch, state}, data) {
        commit('SET_AFFILIATE_TMS_POPUP_DATA', data);
    },
    web2TransactionPopupData ({ commit, dispatch }, payload) {
        commit('SET_CLAIM_POPUP_STATUS_WITH_DATA', payload);
    },
    showWeb2Popup ({ commit }, visible) {
        commit('SET_POPUP_SHOW', visible);
    },
    showWeb2AllowancePopup ({ commit }, visible) {
        commit('SET_ALLOWANCE_POPUP_SHOW', visible);
    },
    web2AllowancePopupData ({ commit }, payload) {
        commit('SET_ALLOWANCE_DATA', payload);
    },
    showWeb2SubscriptionPopup ({commit}, visible) {
        commit('SET_SUBSCRIPTION_POPUP_SHOW', visible);
    },
    web2TransactionSubscriptionPopup ({commit}, payload) {
        commit('SET_SUBSCRIPTION_POPUP_DATA', payload);
    },
    showWeb2DepositToSPPopup ({commit}, visible) {
        commit('SET_DEPOSIT_SP_POPUP_SHOW', visible);
    },
    showWeb2DepositToSPPopupLockedUnlocked ({commit}, visible) {
        commit('SET_DEPOSIT_SP_POPUP_SHOW_LOCKED_UNLOCKED', visible);
    },
    web2TransactionDepositToSPPopup ({commit}, payload) {
        commit('SET_DEPOSIT_SP_POPUP_DATA', payload);
    },
    web2TransactionDepositToSPPopupLockedUnlocked ({commit}, payload) {
        commit('SET_DEPOSIT_SP_POPUP_DATA_LOCKED_UNLOCKED', payload);
    },
    setMinAmount({ commit }, value) {
        commit('SET_MIN_AMOUNT_FOR_DEPOSIT_CASHFT', value);
    }
};

// Mutations
const mutations = {
    SET_SUCCESS_POPUP_DATA (state, payload) {
        state.successPopupData = payload;
    },
    SET_COMFIRMATION_POPUP_DATA (state, payload) {
        state.comfirmationPopupData = payload;
    },
    SET_LOADING_POPUP_DATA (state, payload) {
        state.loadingPopupData = payload;
    },
    SET_CONNECT_WALLET_POPUP_STATUS (state, payload) {
        state.isConnectWalletPopupVisible = payload;
    },
    SET_META_MASK_INSTALLATION_POPUP_STATUS (state, payload) {
        state.isMetaMaskInstallationPopupVisible = payload;
    },
    SET_AFFILIATE_TMS_POPUP_DATA (state, payload) {
        state.affiliateTmsPopupData = payload;
    },
    SET_CLAIM_POPUP_STATUS_WITH_DATA (state, payload) {
        state.contractNameForTransaction = payload?.contractName;
        state.claimWeb2PopupData = payload;
    },
    SET_CLAIM_POPUP_CLOSE (state) {
        state.claimWeb2PopupData = null;
    },
    SET_POPUP_SHOW (state, value) {
        state.isWeb2ClaimPopupVisible = value;
    },
    SET_ALLOWANCE_POPUP_SHOW (state, value) {
        state.isWeb2AllowancePopupVisible = value;
    },
    SET_ALLOWANCE_DATA (state, payload) {
        state.allowanceContractAddress = payload;
    },
    SET_SUBSCRIPTION_POPUP_SHOW (state, value) {
        state.isWeb2SubscriptionModalVisible = value;
    },
    SET_SUBSCRIPTION_POPUP_DATA (state, payload) {
        state.subscriptionWeb2PopupData = payload;
    },
    SET_DEPOSIT_SP_POPUP_SHOW (state, value) {
        state.isWeb2DepositToSPModalVisible = value;
    },
    SET_DEPOSIT_SP_POPUP_SHOW_LOCKED_UNLOCKED (state, value) {
        state.isWeb2DepositToSPModalVisibleLockedUnlocked = value;
    },
    SET_DEPOSIT_SP_POPUP_DATA (state, payload) {
        state.depositToSPWeb2PopupData = payload;
    },
    SET_DEPOSIT_SP_POPUP_DATA_LOCKED_UNLOCKED (state, payload) {
        state.depositToSPWeb2PopupDataLockedUnlocked = payload;
    },
    SET_MIN_AMOUNT_FOR_DEPOSIT_CASHFT(state, value) {
        state.cashFTCardMinAmount = value;
    }
};

// Getters
const getters = {
    successPopupData: (state) => state.successPopupData,
    comfirmationPopupData: (state) => state.comfirmationPopupData,
    loadingPopupData: (state) => state.loadingPopupData,
    isConnectWalletPopupVisible: (state) => state.isConnectWalletPopupVisible,
    isMetaMaskInstallationPopupVisible: (state) => state.isMetaMaskInstallationPopupVisible,
    affiliateTmsPopupData: (state) => state.affiliateTmsPopupData,
    claimWeb2PopupData: (state) => state.claimWeb2PopupData,
    isWeb2ClaimPopupVisible: (state) => state.isWeb2ClaimPopupVisible,
    contractNameForTransaction: (state) => state.contractNameForTransaction,
    isWeb2AllowancePopupVisible: (state) => state.isWeb2AllowancePopupVisible,
    allowanceContractAddress: (state) => state.allowanceContractAddress,
    isWeb2SubscriptionModalVisible: (state) => state.isWeb2SubscriptionModalVisible,
    subscriptionWeb2PopupData: (state) => state.subscriptionWeb2PopupData,
    isWeb2DepositToSPModalVisible: (state) => state.isWeb2DepositToSPModalVisible,
    isWeb2DepositToSPModalVisibleLockedUnlocked: (state) => state.isWeb2DepositToSPModalVisibleLockedUnlocked,
    depositToSPWeb2PopupData: (state) => state.depositToSPWeb2PopupData,
    depositToSPWeb2PopupDataLockedUnlocked: (state) => state.depositToSPWeb2PopupDataLockedUnlocked,
    cashFTCardMinAmount: (state) => state.cashFTCardMinAmount,
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
