
import GYMMenu from "~/components/aside/menu/GYMMenu";
import svg from "assets/images/icons/asideIcons.svg";
import {mapActions, mapGetters} from "vuex";
import { METAVERSES } from "~/core/services/utils/MetaverseConstants";
import { PopupHelper } from "~/core/helpers/PopupHelper";
import GYMButton from "@/components/utils/buttons/GYMButton";
import {AUTH_FLOW_STEPS} from "@/core/services/utils/Constants";
import LocaleSwitcher from "@/components/utils/localeSwitcher/LocaleSwitcher";
import ContractAddressesDropDown from "@/components/header/components/ContractAddressesDropDown";
import { DrawerComponent } from "assets/ts/DrawerComponent";

export default {
    name: "GYMAside",
    components: {
        ContractAddressesDropDown,
        LocaleSwitcher,
        GYMButton,
        GYMMenu
    },
    props: {
        lightLogo: {
            Type: String,
            default: ''
        },
        darkLogo: {
            Type: String,
            default: ''
        },
        isPartnerMenu: {
            Type: Boolean,
            default: false
        }
    },
    emits: ['onPartnerAreaClick', 'onMainAreaClick'],
    data() {
        return {
            asideTheme: 'light',
            script: svg,
            partnerAreaHref: '',
        }
    },
    computed: {
        ...mapGetters({
            authUser: 'application/driver/authUser',
            isEasyLoginLoading: 'user/isEasyLoginLoading'
        }),
        ...mapGetters('map/mapModule', ['metaverseId']),
        minerRewardsHref() {
            if(this.authUser?.relatedWalletAddress) {
                const remoteAuth = this.$hashString.encode(this.authUser.relatedWalletAddress);
                return `https://gymnetwork.io/miner-rewards/?remote_auth=${remoteAuth}`;
            }
            return null;
        },
        logo() {
            return METAVERSES[this.metaverseId].logo
        },
        hasAccessToPartnerArea() {
            return this.authUser && this.authUser.hasAccessToPartnerArea();
        }
    },
    watch: {
        authUser: {
            immediate: true,
            deep: true,
            async handler(newVal, oldVal) {
                if(newVal && newVal.id) {
                    if(!oldVal) {
                        this.closeMenu();
                    }
                }
            }
        }
    },
    methods: {
        ...mapActions({
            goToCashFT: 'actions/goToCashFT',
        }),
        partnerAreaClick() {
            if(this.hasAccessToPartnerArea) {
                this.$emit('onPartnerAreaClick');
            }
        },
        handleMainAreaClick() {
            this.$emit('onMainAreaClick');
            this.$router.push('/dashboard');
        },
        async handleGoToCashFT() {
            try {
                await this.goToCashFT();
            } catch (e) {
                PopupHelper.showErrorAlert(this.$t(e.message));
            }
        },
        handleLogIn() {
            this.$store.dispatch('application/driver/setAuthStep', AUTH_FLOW_STEPS.SIGN_IN_WITH_EMAIL);
            this.$store.dispatch('application/popup-control/changeConnectWalletPopupStatus', true);
        },
        handleSignUp() {
            this.$store.dispatch('application/driver/setAuthStep', AUTH_FLOW_STEPS.SIGN_UP_WITH_EMAIL);
            this.$store.dispatch('application/popup-control/changeConnectWalletPopupStatus', true);
        },
        closeMenu() {
            var drawer = DrawerComponent.getInstance('kt_aside');
            if (!drawer) return;
            drawer.hide()
        },
    }

}
