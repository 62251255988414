
import {mapActions, mapGetters} from "vuex";
import GymNewButton from "~/components/utils/form-components/GymNewButton.vue";
import CustomLoader from "~/components/utils/CustomLoader.vue";
import PROVIDERS from "@/constants/providers";
import { PopupHelper } from "~/core/helpers/PopupHelper";
import { GlobalMakerService } from "~/core/services/GlobalMakerService";
import ChainChangeError from "~/core/helpers/ChainChangeError";
import SignatureDeclinedError from "~/core/helpers/SignatureDeclinedError";
import InvalidWalletAddressError from "~/core/helpers/InvalidWalletAddressError";
import { AuthError } from "~/core/services/api-interaction/AuthService";
import { WALLET_ERROR_CODES } from "~/core/services/utils/Constants";
import ApiService from "@/core/services/api-interaction/ApiService";
import {DataService} from "@/core/services/DataService";

export default {
    name: "ConnectMyWallet",
    components: {
        GymNewButton,
        CustomLoader,
    },
    props: {
        attach: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        ...mapGetters({
            address: "auth/address",
        }),
        ...mapGetters('application/driver', ['authUser']),
        providers() {
            let identifier = null;
            if (process.client) {
                return PROVIDERS.filter((provider) => {
                    if (provider.isInstalled && !provider.isInstalled()) {
                        return false;
                    }
                    if (window.ethereum && provider.identifier) {
                        identifier = window.ethereum[provider.identifier]
                    }
                    return (
                        provider.providerName !== "injected" || identifier
                    );
                });
            }
            return null;
        },
    },
    data() {
        return {
            connecting: false,
        };
    },
    methods: {
        ...mapActions({
            login: "auth/login"
        }),
        async handleConnect(index) {
            this.selectedProviderIndex = index;
            const {providerName} = this.providers[index];
            this.connecting = true;
            DataService.removeAllClaimStatusesFromLocalStorage();
            if(!this.authUser) {
                // await this.changeChain();
                try {
                    await this.$store.dispatch('auth/cleanAddresses');
                    localStorage.setItem("provider", providerName);
                    try {
                        await this.$setWeb3Provider(providerName);
                    } catch (e) {
                        if (e.code !== WALLET_ERROR_CODES.USER_REJECTED) {
                            throw e;
                        } else {
                            return;
                        }
                    }
                    await this.login();

                    this.handleConnectWalletPopupClose();

                    await this.binaryUser();

                    DataService.removeFromLocalStorage('redirect');

                } catch(e) {
                    if(e instanceof SignatureDeclinedError || e instanceof InvalidWalletAddressError) {
                        PopupHelper.showErrorAlert(this.$t(e.message));
                    } else if (e.code === WALLET_ERROR_CODES.TX_ERROR) {
                        PopupHelper.showErrorAlert(this.$t('It seems that a some request is already pending. Please check your wallet app or extension and try again.'));
                    } else {
                        PopupHelper.showErrorAlert(this.$t('Something went wrong, please refresh the page and try again'));
                    }
                } finally {
                    this.connecting = false;
                }
            } else {
                await localStorage.setItem("provider", providerName);
                await this.$setWeb3Provider(providerName);
                // await this.changeChain();
                await GlobalMakerService.showLoadingIndicator(async () => {
                    await this.$store.dispatch('application/driver/handleVerifyUserConnect');
                    PopupHelper.showSuccessNotification(this.$t('Wallet address has been successfully applied'))
                    this.handleConnectWalletPopupClose();
                    this.connecting = false;
                }, async (e) => {
                    let errMessage = e.message;
                    if(e instanceof ChainChangeError) {
                        errMessage = this.$t("User cancelled chain change");
                    } else if(e instanceof SignatureDeclinedError) {
                        errMessage = this.$t("User declined the signature");
                    } else if(e instanceof InvalidWalletAddressError) {
                        errMessage = this.$t(e.message);
                    } else if (e instanceof AuthError) { // TODO check error handling here
                        console.error("Auth error", e);
                        errMessage = this.$t(e.message);
                    } else {
                        console.error("Unknown error", e);
                        errMessage = this.$t("Something went wrong, please refresh the page and try again");
                    }

                    PopupHelper.showErrorAlert(errMessage);
                    this.connecting = false;
                });
            }
        },
        async changeChain() {
            try {
                await this.$store.dispatch('application/driver/switchOrAddSupportedChain', null);
            } catch (e) {
                if (e?.code === WALLET_ERROR_CODES.USER_REJECTED) {
                    PopupHelper.showErrorAlert(this.$t('Request to change the chain has been rejected'));
                } else if (e?.code === WALLET_ERROR_CODES.UNSUPPORTED_METHOD) {
                    PopupHelper.showErrorAlert(this.$t('Chain can not be changed automatically. Please change it manually in your wallet.'));
                } else if(e) {
                    PopupHelper.showErrorAlert(this.$t('Something Went Wrong'));
                }
                throw e;
            }
        },
        handleConnectWalletPopupClose() {
            this.$store.dispatch('application/popup-control/changeConnectWalletPopupStatus', false);
        },
        handleOpenGuide(index) {
            if(index === 0) {
                this.$store.dispatch('application/popup-control/openMetaMaskInstallationPopup', true);
            }
        },
        async binaryUser () {
            try {
                const responseBinary = await ApiService.get(`${this.$config.binaryTreeApi}/api/users`);
                GlobalMakerService.$store.commit('application/driver/UPDATE_AUTH_USER', {
                    isRoleSelected: responseBinary?.data?.user?.isRoleSelected,
                    currentRankName: responseBinary?.data?.user?.currentRank,
                    commissionRankName: responseBinary?.data?.user?.commissionRank,
                    subscriptionExpiresAt: responseBinary?.data?.subscription_expires_at,
                    isReferralLinkActive: responseBinary?.data?.user?.isReferralLinkActive,
                    leftLegReffer: responseBinary?.data?.left_leg_referr,
                    rightLegReffer: responseBinary?.data?.right_leg_referr,
                    isFastStartIncentiveActive: responseBinary?.data?.user?.isFastStartIncentiveActive,
                    binaryUserRegistrationDate: responseBinary?.data?.user?.createdAt,
                    totalOwnPurchaseAmount: responseBinary?.data?.user?.totalOwnPurchaseAmount,
                    totalUnilevelTeamTurnoverAmount: responseBinary?.data?.user.totalUnilevelTeamTurnoverAmount,
                    sponsorWalletAddress: responseBinary?.data?.sponsorWalletAddress,
                    isUsedFastStartIncentive: responseBinary?.data?.user?.isUsedFastStartIncentive,
                });
            } catch (e) {
                console.log('binaryErr', e)
            }
        }
    }
}
