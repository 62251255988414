

import VueCountdown from "@chenfengyuan/vue-countdown";
import {defineComponent} from "vue";

type MenuCountdownComponent = {
    end: Date;
    countdownTime: number;
    addZero(time: Record<string, number>): Record<string, string>;
}

export default defineComponent<MenuCountdownComponent, keyof MenuCountdownComponent>({
    name: "YellowCountdown",
    components: {VueCountdown},
    props: {
        end: {
            type: Date,
            required: true,
        },
    },
    emits: ['end'],
    computed: {
        countdownTime(): number {
            return this.end.getTime() - Date.now();
        },
    },
    methods: {
        addZero(time: Record<string, number>) {
            const formattedTime = {};

            Object.entries(time).forEach(([key, value]) => {
                formattedTime[key] = value < 10 ? `0${value}` : String(value);
            });

            return formattedTime;
        },
    },
})
