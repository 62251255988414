import {
    BUNDLE_TYPE_MINERS_1,
    BUNDLE_TYPE_MINERS_MAX,
    BUNDLE_TYPE_PARCELS_MINERS_1,
    BUNDLE_TYPE_STARTER_BUNDLE,
    SUPER_BUNDLE_ID_ARRAY
} from "~/core/services/utils/Constants";

export class MetaBundle {

    constructor (jsonData) {
        this.smartContractId = jsonData.sc_id;
        this.name = jsonData.name;
        this.numberOfMiners = jsonData.number_of_miners;
        this.numberOfParcels = jsonData.number_of_parcels;
        this.price = jsonData.price;
        this.discountedPrice = jsonData.discounted_price;
        this.extraDiscountPercent = jsonData.extra_discount_percent;
        this.sixDayDiscountPercent = jsonData.six_day_discount_percent;
        this.benefitsInfo = jsonData.benefitsInfo;
        if (this.sixDayDiscountPercent) {
            this.discountedPrice -= this.price * this.sixDayDiscountPercent / 100;
        }
        // set extraDiscountPercent to null for hiding extra discount section from bundle card
        this.utilityPrice = jsonData.utilityPrice;
    }

    getSale() {
        return 100 * (this.price - this.discountedPrice) / this.price;
    }

    get discountedPriceWOSixDayIncentive() {
        return this.discountedPrice + this.price * this.sixDayDiscountPercent / 100;
    }

    isSuperBundle() {
        return this.smartContractId && SUPER_BUNDLE_ID_ARRAY.includes(this.smartContractId);
    }

    isStarterBundle() {
        return this.smartContractId === BUNDLE_TYPE_STARTER_BUNDLE;
    }

    isBasicBundle() {
        return this.smartContractId === BUNDLE_TYPE_PARCELS_MINERS_1;
    }

    isOnlyMinerBundle() {
        return this.smartContractId && this.smartContractId >= BUNDLE_TYPE_MINERS_1 && this.smartContractId <= BUNDLE_TYPE_MINERS_MAX;
    }
}
