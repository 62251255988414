
import AuthCore from '~/core/components/flows/signin/AuthCore.vue';
import GymNewButton from '~/components/utils/form-components/GymNewButton.vue';
import CustomLoader from '../utils/CustomLoader.vue';
import { isMobileDevice } from '@/core/helpers/GlobalHelpers';
import { mapGetters } from 'vuex';
import { AUTH_FLOW_STEPS } from '~/core/services/utils/Constants';
import { METAVERSES } from '~/core/services/utils/MetaverseConstants';

export default {
    name: "GymSignInPopup",
    components: {
        AuthCore,
        GymNewButton,
        CustomLoader,
    },
    emits: ['onLoading', 'onStopLoading', 'onPopupClose'],
    props: {
        popupVisible: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            loaderVisible: false,
            isMobile: isMobileDevice(),
            resizeHandler: () => this.isMobile = isMobileDevice(),
            AUTH_FLOW_STEPS,
            METAVERSES,
        }
    },
    mounted() {
        window.addEventListener('resize', this.resizeHandler);
    },
    destroyed() {
        window.removeEventListener('resize', this.resizeHandler);
    },
    computed: {
        ...mapGetters('application/driver', ['authCoreStep']),
        popupCustomClass() {
            let customClass = 'sign-in-popup ';
            if(this.isMobile) {
                customClass += 'sign-in-popup__mobile ';
            }
            if (!this.isMobile) {
                customClass += 'gym-popup';
            }
            return customClass;
        }
    },
    methods: {
        handleCloseSignInPopup() {
            if(!this.loaderVisible && this.authCoreStep !== AUTH_FLOW_STEPS.RESET_PASSWORD) {
                this.$emit('onPopupClose');
            }
        },
        showLoader() {
            this.loaderVisible = true;
            this.$emit('onLoading');
        },
        hideLoader() {
            this.loaderVisible = false;
            this.$emit('onStopLoading');
        },
        handleError(errorData) {
            this.loaderVisible = false;
            this.$emit('onError', errorData);
        }
    }
}
