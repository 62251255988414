import Asset from './Asset';
import BNBAsset from './BNBAsset';
import { CurrencyName } from '~/pages/finances/wallet/models/CurrencyName';
import type WalletAddress from "~/pages/finances/wallet/models/WalletAddress";
import { toWei, toBN, fromWei } from "~/core/helpers/GlobalHelpers";

export type WalletCurrencyName = Exclude<CurrencyName, 'BNB'>;
const GAS_FEE_MULTIPLIER_FOR_INTERNAL = 1.3;

export default class WalletAsset extends Asset {
    _contract: any; // TODO: fixit
    metaverseID: number;
    contractAddress: string;

    constructor (public id: number | null,
                 public name: string | null | undefined,
                 public currency: WalletCurrencyName,
                 public icon: string,
                 value: number,
                 rate: number,
                 isInsufficient: boolean,
                 metaverseID: number,
                 metaWorldManager: any,
                 contract: any) {
        super(id, name, currency, icon, value, rate, isInsufficient, metaWorldManager);

        this.metaverseID = metaverseID;
        this._contract = contract;
        this.contractAddress = contract._address;
    }

    async estimateGas (
        gasPrice: number,
        addressFrom: WalletAddress,
        addressTo: WalletAddress
    ): Promise<BNBAsset> {
        const { _metaWorldManager } = this;
        const { toWei, fromWei } = _metaWorldManager.web3().utils;
        const transaction = this._contract.methods.transfer(addressTo, toWei(this._value.toString()));
        const gas = await transaction.estimateGas({ from: addressFrom });
        const gasAmmountPrice = fromWei(Math.ceil(parseFloat(gas) * gasPrice * GAS_FEE_MULTIPLIER_FOR_INTERNAL).toString(), 'gwei');
        return new BNBAsset(
            gasAmmountPrice,
            0,
            false,
            this._metaWorldManager
        );
    }

    _getSendTransaction (): object {
        return {
            type: 'token',
            contract: this._contract._address
        };
    }

    async estimateSwapGas (
        path: string[],
        walletAddress: string,
        amount: number,
        amountOutMin = 0
    ) {
        const { _metaWorldManager } = this;
        const amountFromToWei = (toWei(amount.toString()));

        const transaction = _metaWorldManager.contracts.GymRouter.methods.swapExactTokensForTokensSupportingFeeOnTransferTokens(
            amountFromToWei, amountOutMin, path, walletAddress
        );

        const gasEstimated = toBN(await transaction.estimateGas({ from: walletAddress }));
        const gasPrice = toBN(await _metaWorldManager.readWeb3().eth.getGasPrice());
        const totalAmountWithGas = gasEstimated.mul(gasPrice);
        const GasEstimatedInBNB = fromWei(totalAmountWithGas.toString());

        return Number(GasEstimatedInBNB) * GAS_FEE_MULTIPLIER_FOR_INTERNAL;
    }

    subtract (subtrahend: number): WalletAsset {
        const parentAmt = super.subtract(subtrahend);
        return new WalletAsset(
            this.id,
            this.name,
            this.currency,
            this.icon,
            subtrahend,
            this._rate,
            parentAmt.isInsufficient,
            this.metaverseID,
            this._metaWorldManager,
            this._contract
        );
    }

    copyRate (asset: WalletAsset): WalletAsset {
        return new WalletAsset(
            this.id,
            this.name,
            this.currency,
            this.icon,
            this.value,
            asset._rate,
            this._isInsufficient,
            this.metaverseID,
            this._metaWorldManager,
            this._contract
        );
    }
}
