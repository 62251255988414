import ERROR_MESSAGES from './error-messages';

function passwordAlphaNumValidator(rule, value, callback) {
    const numRegexp = /[0-9]/;
    const lowercaseRegexp = /[a-z]/;
    const uppercaseRegexp = /[A-Z]/;
    if (!numRegexp.test(value) ||
        !lowercaseRegexp.test(value) ||
        !uppercaseRegexp.test(value)) {
        return callback(new Error(ERROR_MESSAGES.PASSWORD_ERR.ALPHA_NUM));
    }
    return callback();
}

function passwordSpecialCharsValidator(rule, value, callback) {
    const regex = /[\~\`\!\@\#\$\%\^\&\*\(\)\_\-\+\=\{\[\}\]\|\:\;\"\'\<\,\>\.\?]/;
    if (!regex.test(value)) {
        return callback(new Error(ERROR_MESSAGES.PASSWORD_ERR.SPECIAL_CHARS));
    }
    return callback();
}

function passwordSpaceValidator(rule, value, callback) {
    const spaceRegex = /[\s\0\n\f\r\t\v]/g;
    if (spaceRegex.test(value)) {
        return callback(new Error(ERROR_MESSAGES.PASSWORD_ERR.SPACE));
    }
    return callback();
}

export default { passwordAlphaNumValidator, passwordSpecialCharsValidator, passwordSpaceValidator };