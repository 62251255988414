

import {toCurrencyFormat, toNumericFormat} from '@/core/helpers/GlobalHelpers';
import MysteryBoxTx from '~/core/components/flows/mystery-box/models/MysteryBoxTx';
import {UserBalance} from '~/core/components/flows/mystery-box/models/UserBalance';
import {PaymentMethodEnum} from '~/core/components/flows/mystery-box/constants/PaymentMethodEnum';

// const BUSD_FEE_VALUE = 3;

export default {
    name: 'TotalWidget',
    props: {
        transactionObject: {
            type: MysteryBoxTx,
            required: true,
        },
        paymentMethod: {
            type: String,
            required: true,
        },
        userBalance: {
            type: UserBalance,
            default: null,
        },
    },
    data() {
        return {
            PaymentMethodEnum,
            toCurrencyFormat,
            toNumericFormat,
        };
    },
    computed: {
        utilityAsset() {
            return this.transactionObject.priceInfo?.splitAmountUtility?.gymnet;
        },
        mainAsset() {
            let asset = this.transactionObject.priceInfo.totalAmount;
            // if (this.paymentMethod === PaymentMethodEnum.INTERNAL &&
            //     this.transactionObject.priceInfo.gasAmount.value > this.userBalance.bnb.value) {
            //     asset = asset.copy(asset.value + BUSD_FEE_VALUE);
            // }
            return asset;
        },
        totalUSD() {
            let totalUSD = this.mainAsset.usd;
            // if (this.transactionObject.isUtility) {
            //     totalUSD += this.utilityAsset.usd;
            // }
            return totalUSD;
        },
    },
};

