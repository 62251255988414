import { MetaWorldManager } from "../services/map/MetaWorldManager";
import ERROR_MESSAGES from "./error-messages";

export function isValidUsername (rule, value, callback) {
    const regex = /^(?![-._])[a-z][-._a-z0-9]{7,}$/;
    if(regex.test(value)) {
        return callback();
    }
    return callback(new Error(ERROR_MESSAGES.USERNAME_ERR.INVALID));
}

export function isValidEmailCallback (rule, value, callback) {
    if(value && !value.includes('@')) {
        return callback();
    }
    const regex = /^[a-zA-Z0-9._-]+(?:\+\d+)*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/g;
    if (regex.test(value)) {
        return callback();
    }
    return callback(new Error(ERROR_MESSAGES.EMAIL_ERR.INVALID));
}

export function isValidUsernameNotStrict (rule, value, callback) {
    if(value.includes('@')){
        return  callback();
    }
    const regex = /^(?![-._])[a-z][-._a-z0-9]{7,}$/;
    if(regex.test(value)) {
        return callback();
    }
    return callback(new Error(ERROR_MESSAGES.USERNAME_ERR.INVALID));
}

export async function usernameExists (rule, value, callback) {
    let isExists = await MetaWorldManager.sharedInstance().validateIsCredentialExists({username: value});
    if (isExists) {
        callback(new Error(ERROR_MESSAGES.USERNAME_ERR.ALREADY_TAKEN));
    }
    callback();
}




