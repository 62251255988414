
import {CONTRACT_ADDRESSES} from "@/core/services/utils/Constants";
import {copyToClipboard} from "@/core/helpers/GlobalHelpers";

export default {
    name: "ContractAddress",
    data() {
        return {
            CONTRACT_ADDRESSES,
            isCopied: false
        }
    },
    methods: {
        copyAddress(address) {
            copyToClipboard(address)
            this.isCopied = true;
            setTimeout(() => {
                this.isCopied = false;
            }, 2000);
        }
    }
}
