
import { MainMenuItem } from "~/core/config/MenuConfig";

export default {
    name: "GYMMenuItem",
    props: {
        menuData: {
            type: Object as () => MainMenuItem,
        },
        keyIndex: Number,
        hasActiveChildren: Function,
    },
}
