const middleware = {}

middleware['authenticated'] = require('../middleware/authenticated.js')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['authenticatedPartnerArea'] = require('../middleware/authenticatedPartnerArea.js')
middleware['authenticatedPartnerArea'] = middleware['authenticatedPartnerArea'].default || middleware['authenticatedPartnerArea']

middleware['eliteVote'] = require('../middleware/eliteVote.js')
middleware['eliteVote'] = middleware['eliteVote'].default || middleware['eliteVote']

middleware['gymstreetMetaverse'] = require('../middleware/gymstreetMetaverse.js')
middleware['gymstreetMetaverse'] = middleware['gymstreetMetaverse'].default || middleware['gymstreetMetaverse']

middleware['viableDiamondAbove'] = require('../middleware/viableDiamondAbove.js')
middleware['viableDiamondAbove'] = middleware['viableDiamondAbove'].default || middleware['viableDiamondAbove']

export default middleware
