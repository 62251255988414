import { render, staticRenderFns } from "./PurchaseCountdown.vue?vue&type=template&id=134d611c&scoped=true&"
import script from "./PurchaseCountdown.vue?vue&type=script&lang=ts&"
export * from "./PurchaseCountdown.vue?vue&type=script&lang=ts&"
import style0 from "./PurchaseCountdown.scss?vue&type=style&index=0&id=134d611c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "134d611c",
  null
  
)

export default component.exports