
    import AuthService from '~/core/services/api-interaction/AuthService';
    import GymNewButton from '~/components/utils/form-components/GymNewButton.vue';
    import ERROR_MESSAGES from '@/core/validators/error-messages';

    export default {
        name: "ForgotPassword",
        components: {
            GymNewButton,
        },
        emits: ['onLoading', 'onError', 'onRecoverPassword'],
        props: {
            isMobile: {
                type: Boolean,
                default: false
            },
            initialFormData: {
                type: Object,
                default: null,
            }
        },
        data() {
            return {
                recoverPasswordForm: {
                    email: '',
                },
                validationRules: {
                    email: [
                        { required: true, message: this.$t(ERROR_MESSAGES.EMAIL_ERR.REQUIRED), trigger: 'blur' },
                        { type: 'email', message: this.$t(ERROR_MESSAGES.EMAIL_ERR.INVALID), trigger: ['blur', 'change'] },
                    ],
                },
                areActionsDisabled: false,
                recoverPasswordMailSent: false
            }
        },
        mounted() {
            if (this.initialFormData) {
                this.recoverPasswordForm = this.initialFormData;
            }
        },
        methods: {
            resetPassword() {
                this.$refs.forgotPasswordForm.validate().then(async valid => {
                    if (valid) {
                        this.startLoading();
                        try {
                            await AuthService.recoverPassword(this.recoverPasswordForm.email);
                            this.$emit('onRecoverPassword', this.recoverPasswordForm);
                            this.recoverPasswordMailSent = true;
                        } catch (err) {
                            this.onError(err);
                            this.recoverPasswordMailSent = false;
                        }
                    }
                });
            },
            startLoading() {
                this.areActionsDisabled = true;
                this.$emit('onLoading');
            },
            onError(errorObject) {
                this.areActionsDisabled = false;
                this.$emit('onError', {
                    error: errorObject,
                    popupData: {
                        title: "Couldn't recover password",
                        message: errorObject.message,
                    }
                });
            }
            
        }
    }
