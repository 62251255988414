
import {MetaNotificationContainerItem} from "@/core/services/notifications/MetaNotificationsContainer";
import {NOTIFICATION_TYPES} from "@/core/services/utils/Constants";

export default {
    name: "NotificationDropdownItem",
    props: {
        notification: MetaNotificationContainerItem
    },
    data() {
        return {
            NOTIFICATION_TYPES
        }
    }
}
