import MysteryBoxTx from './MysteryBoxTx';
import BNBAsset from '~/pages/finances/wallet/models/BNBAsset';
import { toWei } from '@/core/helpers/GlobalHelpers';
import { MetaWorldManager } from '~/core/services/map/MetaWorldManager';

const GAS_FEE_MULTIPLIER = 1.5;

export default class Web3MysteryBoxTx extends MysteryBoxTx {

    constructor (internalTransaction: MysteryBoxTx) {
        super(
            internalTransaction._gymnet,
            internalTransaction._mysteryBox,
            internalTransaction.account,
            internalTransaction.product,
            internalTransaction.priceInfo,
            internalTransaction.paymentType,
        );
    }

    async approve () {
        const metaWorldManager = MetaWorldManager.sharedInstance();
        const readWeb3 = metaWorldManager.readWeb3();
        const gasPrice = await readWeb3.eth.getGasPrice();
        const { account: outgoingAccount } = this;
        const approveAmount = this.priceInfo.totalAmount.value;
        const requestPrice = toWei(String(approveAmount));

        const tx = this._gymnet.methods
            .approve(this._mysteryBox.options.address, requestPrice);

        const estimatedGas = await tx.estimateGas({
            from: outgoingAccount,
        });

        const response = await tx.send({
            from: outgoingAccount,
            gas: Math.floor(estimatedGas * GAS_FEE_MULTIPLIER),
            gasPrice,
        });
        return response.status;
    }

    async send () {
        const { account: outgoingAccount } = this;
        const metaWorldManager = MetaWorldManager.sharedInstance();
        const readWeb3 = metaWorldManager.readWeb3();
        const gasPrice = await readWeb3.eth.getGasPrice();
        const tx = this._mysteryBox.methods[this.method](...this.args);
        const estimatedGas = await tx.estimateGas({
            from: outgoingAccount,
        });
        const eventEmitter = tx.send({
            from: outgoingAccount,
            gas: Math.floor(estimatedGas * GAS_FEE_MULTIPLIER),
            gasPrice,
        });
        eventEmitter.on('transactionHash', (txHash: string) => {
            this.txHashes.push(txHash);
        });
        return eventEmitter;
    }

    // TODO do we need this?
    estimateGas (): Promise<BNBAsset> {
        const metaWorldManager = MetaWorldManager.sharedInstance();
        const gasInBNBValue = 0;
        const gasInBNB = new BNBAsset(Number(gasInBNBValue), 0, false, metaWorldManager);
        this.cachedGasFee = gasInBNB;
        this.priceInfo.gasAmount = gasInBNB;
        return Promise.resolve(gasInBNB);
    }
}
