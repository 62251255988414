
import { mapGetters } from "vuex";
import { binaryRanks } from "~/constants/constants";
import { getHidedAccountAddress } from "@/core/helpers/GlobalHelpers";
import MenuCountdown from "~/components/countdowns/menu-countdown/MenuCountdown.vue";

export default {
    name: "UsernameStatusContent",
    components: {
        MenuCountdown
    },
    data() {
        return {
            getHidedAccountAddress
        }
    },
    computed: {
        ...mapGetters({
            authUser: "application/driver/authUser",
            isBinaryAPIFailed: "application/driver/isBinaryAPIFailed"
        }),
        getRank () {
            return binaryRanks.filter(el => el.name === this.authUser?.currentRankName)[0]?.rank;
        },
    },
    methods: {
        refreshPage () {
            window.location.reload();
        },
    }
}
