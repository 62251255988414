
    import ChangeEmail from './steps/ChangeEmail.vue';
    import ConnectMyWallet from './steps/ConnectMyWallet.vue';
    import SignUpWithEmail from './steps/SignUpWithEmail.vue';
    import SignInWithEmail from './steps/SignInWithEmail.vue';
    import ForgotPassword from './steps/ForgotPassword.vue';
    import ResetPassword from './steps/ResetPassword.vue';
    import GymNewButton from '~/components/utils/form-components/GymNewButton.vue';
    import { AUTH_FLOW_STEPS } from '~/core/services/utils/Constants';
    import { mapGetters } from 'vuex';
    import ConfirmationEmailSent from './steps/ConfirmationEmailSent.vue';
    import { OAuthError, AuthError } from '~/core/services/api-interaction/AuthService';
    import {PopupHelper} from "@/core/helpers/PopupHelper";
import { isValidEmail } from '~/core/validators/email';

    export default {
        name: "AuthCore",
        components: {
            ChangeEmail,
            ConnectMyWallet,
            SignUpWithEmail,
            SignInWithEmail,
            ForgotPassword,
            ResetPassword,
            GymNewButton,
            ConfirmationEmailSent,
        },
        emits: ['onFlowFinished', 'onLoading', 'onStopLoading', 'onError', 'onStepTransition'],
        props: {
            isMobile: {
                type: Boolean,
                default: false
            },
            loaderVisible: {
                type: Boolean,
                default: false
            },
            showPopupOnError: {
                type: Boolean,
                default: true,
            },
        },
        data() {
            return {
                flow: AUTH_FLOW_STEPS,
                step: AUTH_FLOW_STEPS.SIGN_UP_WITH_EMAIL,
                flowHistory: [],
                currentStepInitialData: null,
            }
        },
        watch: {
            authCoreStep(newVal, oldVal) {
                if(newVal) {
                    this.step = newVal;
                }
            }
        },
        mounted() {
            if(this.authCoreStep) {
                this.step = this.authCoreStep;
            }
        },
        beforeDestroy() {
            this.$store.dispatch('application/driver/setAuthStep', null);
        },
        computed: {
            ...mapGetters('application/driver', ['authCoreStep', 'authUser']),
            recoverPasswordEmail() {
                return this.flowHistory[this.flowHistory.length - 1]?.stepState.email || this.authUser?.email;
            }
        },
        methods: {
            startLoading() {
                this.$emit('onLoading');
            },
            handleError(errorHandlingData) {
                this.$emit('onError', errorHandlingData);
                if(errorHandlingData.error && errorHandlingData.error instanceof OAuthError) {
                    return;
                }

                if(this.showPopupOnError) {
                    let message = 'Something went wrong, please refresh the page and try again';

                    if(errorHandlingData.error && errorHandlingData.error instanceof AuthError && errorHandlingData.popupData.message) {
                        message = errorHandlingData.popupData.message;
                    }
                    PopupHelper.showErrorAlert(this.$t(message));
                }
            },
            onFlowFinished() {
                this.$emit('onStopLoading');
                this.$emit('onFlowFinished');
                if (this.authUser?.isUMUser) {
                    this.$router.push('/account');
                }
            },
            stepTransition(stepToTransition, stepStateToSave = null) {
                this.$emit('onStopLoading');
                let currentStepData = null;
                if (stepToTransition === AUTH_FLOW_STEPS.FORGOT_PASSWORD && isValidEmail(stepStateToSave.email)) {
                    currentStepData = stepStateToSave;
                }
                const lastIndex = this.flowHistory.map(el => el.step).lastIndexOf(stepToTransition);
                if(lastIndex !== -1) {
                    currentStepData = this.flowHistory[lastIndex].stepState;
                }
                this.currentStepInitialData = currentStepData;

                this.flowHistory.push({
                    step: this.step,
                    stepState: stepStateToSave,
                });
                this.$store.dispatch('application/driver/setAuthStep', stepToTransition);
                this.$emit('onStepTransition', stepToTransition);
            },
            stepBack() {
                if(this.canGoBack()) {
                    this.flowHistory.forEach(item => {
                        if(item.step === this.step) {
                            item.stepState = this.currentStepInitialData;
                        }
                    });
                    const previousStepData = this.flowHistory.pop();
                    this.currentStepInitialData = previousStepData.stepState;
                    this.$store.dispatch('application/driver/setAuthStep', previousStepData.step);
                    this.$emit('onStopLoading');
                }
            },
            canGoBack() {
                return this.flowHistory.length > 0;
            }
        }
    }
