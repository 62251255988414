
// Components
import {mapGetters} from "vuex";
import GymNewButton from "@/components/utils/form-components/GymNewButton";
import { PopupHelper } from "~/core/helpers/PopupHelper";
import {toNumericFormat, toBN, isMobileDevice, fromWei} from "@/core/helpers/GlobalHelpers";
import {MetaWorldManager} from "@/core/services/map/MetaWorldManager";
import {MetaInventoryContainer} from "@/core/models/MetaInventoryContainer";

const BUSD_FEE_VALUE = 3;
const GAS_FEE_MULTIPLIER_FOR_INTERNAL = 1.3;

export default {
    name: "ClaimModal",
    components: {GymNewButton},
    props: {
        modalTitle: {
            type: String,
            default: '',
        },
        showPopup: Boolean,
        popupData: Object,
        contractName: String,
    },
    data () {
        return {
            gasFeeBnbDollarValue: 0,
            maxGasFee: 0,
            totalAmountBnb: 0,
            totalAmountDollarValue: 0,
            totalAmountAndGasFee: 0,
            bnbPriceInETH: 0,
            toNumericFormat,
            GASInBNB: 0,
            GASPriceInUSD: 0,
            reRenderComponent: false,
            txObject: null,
            isMobile: isMobileDevice(),
            estimatingGas: false,
            bnbBalance: null,
            BUSD_FEE_VALUE,
            resizeHandler: () => { this.isMobile = isMobileDevice(); },
            timer: null,
        };
    },
    computed: {
        ...mapGetters({
            authUser: "application/driver/authUser",
            address: "auth/address",
            isSendTxLoading: "application/driver/isSendTxLoading",

        }),
        ...mapGetters('map/mapModule', ['metaverseId']),
        getBaseURL () {
            if (process.client) {
                return window.location.origin;
            }
            return null;
        },
        getTitle () {
            return this.popupData?.title;
        },
        showDollarCurrencySymbol() {
            return !this.popupData?.hideDollar;
        },
        getRefId () {
            return this.popupData?.refID;
        },
        getAmount () {
            return this.popupData?.amount || 0;
        },
        getTXArguments () {
            return this.popupData?.transactionArguments;
        },
        getContractAddressName () {
            if (this.popupData?.method === 'convertFreeMiners') {
                return 'CONVERT UNUSED MINERS';
            }
            return this.separateAndUpperCase(this.popupData?.method);
        },
        getContactAddress () {
            if (this.contractName) {
                const cntAddress = this.$contracts[this.metaverseId][this.contractName]._address;
                return cntAddress.slice(0, 6) + "..." + cntAddress.slice(-5, cntAddress.length);
            }
            return null;
        },
        gasFeeBnb () {
            return 0;
        },
        getTotalPriceInBNB () {
            return 0;
        },
        isMunicipality () {
            return this.contractName?.toLowerCase() === 'municipality';
        },
        isAmount0 () {
            return this.getAmount === 0;
        },
        isRefundable () {
            return this.popupData?.method !== 'convertFreeMiners';
        },
        currencyForGasFeeText () {
            if(this.isMunicipality && this.isRefundable) {
                return 'USDT ' + this.$t('Or').toLowerCase() + ' BNB';
            }
            return 'BNB';
        },
        amountAndCurrencyForGasFeeText () {
            if(this.isMunicipality && this.isRefundable) {
                return BUSD_FEE_VALUE + ' USDT ' + this.$t('Or').toLowerCase() + ' ' + this.GASInBNB + ' BNB';
            }
            return this.GASInBNB + ' BNB';
        },
        hasEnoughBNBFeeBalance () {
            return this.bnbBalance && this.bnbBalance >= this.GASInBNB;
        },
        hasEnoughBUSDFeeBalance () {
            const isBNBSufficient = this.hasEnoughBNBFeeBalance;
            const busdBalance = this.getUserCryptoBalance;
            const amount = this.getAmount;
            const amountWithFee = amount + BUSD_FEE_VALUE;
            return busdBalance && (isBNBSufficient && busdBalance >= amount || !isBNBSufficient && busdBalance >= amountWithFee);
        },
        getUserCryptoBalance () {
            return MetaInventoryContainer.sharedInstance().userCryptoBalance.usdt;
        },
        noEnoughUSDTForUpgrade() {
            return !this.isAmount0 && this.getUserCryptoBalance < this.getAmount && this.isMunicipality;
        }
    },
    watch: {
        popupData: {
            handler (value) {
                if (value) {
                    if (this.showPopup) {
                        this.reRenderComponent = true;
                        setTimeout(() => {
                            this.reRenderComponent = false;
                            this.transactionObject();
                        },2000);
                    }

                }
            },
        },
        showPopup: {
            handler (value) {
                if (!value) {
                    this.handleCloseClaimModal();
                }
                if(value) {
                    this.transactionObject();
                    window.addEventListener('resize', this.resizeHandler);
                    this.isMobile = isMobileDevice();
                } else {
                    window.removeEventListener('resize', this.resizeHandler);
                }
            },
            immediate: true,
            deep: true,
        },
    },
    async mounted () {
        this.bnbPriceInETH = await this.$contracts[this.$metaverseId()].GymNetwork.methods.getBNBPrice().call();

        await this.getBNBBalance();
        this.timer = setInterval(() => {
            this.getBNBBalance();
        }, 3000);
    },
    destroyed() {
        clearInterval(this.timer);
    },
    methods: {
        async getBNBBalance() {
            const bnbAmountWei = await this.$readWeb3().eth.getBalance(this.authUser.walletAddress);
            this.bnbBalance = parseFloat(fromWei(bnbAmountWei)) || 0;
        },
        handleCloseClaimModal () {
            this.$store.dispatch('application/popup-control/showWeb2Popup', false);
            this.$store.dispatch('application/popup-control/web2TransactionPopupData', null);
            this.GASInBNB = 0;
            this.GASPriceInUSD = 0;
        },
        separateAndUpperCase (str) {
            if (str) {
                // use regex to find all uppercase letters that are not at the beginning of the string
                // and add a space before them
                const separated = str.replace(/([a-z0-9])([A-Z])/g, '$1 $2');

                // convert the separated string to uppercase
                return separated?.toUpperCase();
            }
        },
        async handleConfirm () {
            this.$store.dispatch('application/popup-control/showLoadingPopup', {title: 'Please Wait'}, {root: true});
            try {
                await this.$store.dispatch('application/driver/internalWalletSendTx', this.txObject);

                if (this.popupData?.claimableParcels && this.popupData?.claimableParcels.length) {
                    await MetaWorldManager.sharedInstance().claimParcels(this.popupData?.claimableParcels);
                    PopupHelper.showSuccessNotification(this.$t('Parcels successfully claimed'));
                }
            } catch (e) {
                console.log(e);
            } finally {
                this.$store.dispatch('application/popup-control/hideLoadingPopup');
                this.handleCloseClaimModal();
            }
        },
        handleReject () {
            this.handleCloseClaimModal();
            this.$emit('handleCloseClaimModal');
        },
        async transactionObject () {
            if(this.getTXArguments) {
                const txArguments = [...this.getTXArguments];
                if (this.contractName) {
                    const transaction = this.$contracts[this.$metaverseId()][this.contractName].methods[this.popupData.method](...txArguments);
                    try {
                        this.estimatingGas = true;

                        const gasWithoutMultiplier = await transaction.estimateGas({from: this.authUser.walletAddress});

                        const GASEstimated =  toBN(Math.ceil(gasWithoutMultiplier * GAS_FEE_MULTIPLIER_FOR_INTERNAL));

                        const gasPrice = toBN(await this.$readWeb3().eth.getGasPrice());

                        const totalAmountWithGas = GASEstimated.mul(gasPrice);

                        const GasEstimatedInBNB = this.$readWeb3().utils.fromWei(totalAmountWithGas.toString());

                        this.GASInBNB = GasEstimatedInBNB;

                        this.GASPriceInUSD = parseFloat(GasEstimatedInBNB) * this.bnbPriceInETH;

                        this.txObject = {
                            to: this.$contracts[this.$metaverseId()][this.contractName]._address,
                            value: 0,
                            data: transaction.encodeABI(),
                        };
                    } finally {
                        this.estimatingGas = false;
                    }
                }
            }
        },
    },
};
