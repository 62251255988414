import axios, {AxiosInstance,AxiosResponse, AxiosRequestConfig} from "axios";
import JwtService from "~/services/JwtService";
import { GlobalMakerService } from "~/core/services/GlobalMakerService";
import APIServiceError from "~/core/helpers/APIServiceError";
import { getClientRuntimeConfig } from "~/core/helpers/GlobalHelpers";
import { ERROR_STATUSES } from "../utils/Constants";

const $config = getClientRuntimeConfig();

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {

    private static handleSuspiciousActivity(bannedUntil: number) {
        const currentRoutePath = GlobalMakerService.$router.history.current;
        const data = {
            bannedUntil,
            lastRoute: currentRoutePath.fullPath,
        };
        localStorage.setItem("suspicious-activity", JSON.stringify(data));
        GlobalMakerService.$router.push('/suspicious-activity');
    }

    /**
     * @description property to share vue instance
     */
    public static axiosInstance: AxiosInstance;

    /**
     * @description initialize vue axios
     */
    public static init () {
        ApiService.axiosInstance = axios.create({ baseURL: $config.apiBaseUrl });
        ApiService.axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
            const data = config.method === 'get' ? config.params : config.data;
            if(data) {
                data.metaverse_id = GlobalMakerService.metaverseId;
            }
            return config;
        });
        ApiService.axiosInstance.interceptors.response.use(
            response => response,
            error => {
                if (error.response?.status === 403 &&
                    error.response.data?.code === ERROR_STATUSES.IP_ADDRESS_BANNED) {
                    ApiService.handleSuspiciousActivity(error.response.data.data.bannedUntil);
                    return;
                }
                throw new APIServiceError(error);
            },
        );
    }

    /**
     * @description set the default HTTP request headers
     */
    public static setHeader (): void {
        ApiService.axiosInstance.defaults.headers.common.Authorization = `${JwtService.getToken()}`;
        ApiService.axiosInstance.defaults.headers.common.Accept = "application/json";
    }

    public static setAuthorizationToken (token: string): void {
        ApiService.axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
        ApiService.axiosInstance.defaults.headers.common.Accept = "application/json";
    }

    public static deleteAuthorizationToken () {
        delete ApiService.axiosInstance.defaults.headers.common.Authorization;
    }

    /**
     * @description send the GET HTTP request
     * @param resource: string
     * @param params: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    public static query (
        resource: string,
        params: AxiosRequestConfig,
    ): Promise<AxiosResponse> {
        return ApiService.axiosInstance.get(resource, params);
    }

    /**
     * @description send the GET HTTP request
     * @param resource: string
     * @param slug: string
     * @returns Promise<AxiosResponse>
     */
    public static get (
        resource: string,
        slug = "" as string,
    ): Promise<AxiosResponse> {
        if(slug.length > 0) {
            return ApiService.axiosInstance.get(`${resource}/${slug}`);
        } else {
            return ApiService.axiosInstance.get(`${resource}`);
        }
    }

    /**
     * @description set the POST HTTP request
     * @param resource: string
     * @param params: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    public static post (
        resource: string,
        params: AxiosRequestConfig | any,
    ): Promise<AxiosResponse> {
        return ApiService.axiosInstance.post(`${resource}`, params);
    }

    /**
     * @description send the UPDATE HTTP request
     * @param resource: string
     * @param slug: string
     * @param params: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    public static update (
        resource: string,
        slug: string,
        params: AxiosRequestConfig,
    ): Promise<AxiosResponse> {
        return ApiService.axiosInstance.put(`${resource}/${slug}`, params);
    }

    /**
     * @description Send the PUT HTTP request
     * @param resource: string
     * @param params: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    public static put (
        resource: string,
        params: AxiosRequestConfig,
    ): Promise<AxiosResponse> {
        return ApiService.axiosInstance.put(`${resource}`, params);
    }

    /**
     * @description Send the DELETE HTTP request
     * @param resource: string
     * @returns Promise<AxiosResponse>
     */
    public static delete (resource: string): Promise<AxiosResponse> {
        return ApiService.axiosInstance.delete(resource);
    }
}

ApiService.init();

export default ApiService;
