
import PROVIDERS from "~/constants/providers";
import GymNewButton from "~/components/utils/form-components/GymNewButton.vue";
import {PopupHelper} from "~/core/helpers/PopupHelper";

export default {
    name: 'Web3WalletConnection',
    components: {
        GymNewButton,
    },
    props: {
        account: {
            type: String,
            required: true,
        },
    },
    emits: [
        'onStartLoading',
        'onStopLoading',
        'onWalletConnectionDone',
        'onClickBack',
    ],
    data() {
        return {
            isWalletIncorrect: false,
        };
    },
    computed: {
        providers () {
            let identifier = null;
            return PROVIDERS.filter((provider) => {
                if (provider.isInstalled && !provider.isInstalled()) {
                    return false;
                }
                if (window.ethereum && provider.identifier) {
                    identifier = window.ethereum[provider.identifier];
                }
                return (
                    provider.providerName !== "injected" || identifier
                );
            });
        },
    },
    methods: {
        handleOpenGuide (index: number) {
            if(index === 0) {
                this.$store.dispatch('application/popup-control/openMetaMaskInstallationPopup', true);
            }
        },
        async handleConnect (index) {
            this.isWalletIncorrect = false;
            const { providerName } = this.providers[index];
            this.$emit('onStartLoading');
            let provider = null;
            let addresses = null;
            try {
                try {
                    const providerData = await this.$createWeb3Provider(providerName, false);
                    provider = providerData.providerObject;
                    addresses = await this.$enableProvider(providerData);
                    if (addresses[0].toLowerCase() !== this.account.toLowerCase()) {
                        this.isWalletIncorrect = true;
                        const error = new Error("Please connect correct wallet");
                        (error as any).code = 4001;
                        throw error;
                    } 
                } catch (e) {
                    if (e.code !== 4001) {
                        throw e;
                    } else {
                        this.$emit('onStopLoading');
                        return;
                    }
                }
                this.$emit('onWalletConnectionDone', addresses[0], provider);
            } catch (e) {
                PopupHelper.showErrorAlert(this.$t('Something went wrong, please refresh the page and try again'));
                this.$emit('onStopLoading');
            }
        },
    },
}
