import {NOTIFICATION_TYPES} from "~/core/services/utils/Constants";
import {parseToDateTimeView} from "~/core/helpers/GlobalHelpers";
import {GalleryManager} from "~/core/services/gallery/GalleryManager";
import { GlobalMakerService } from "../GlobalMakerService";

export class MetaNotificationsContainer {

    static sharedInstance() {
        if(MetaNotificationsContainer.instance === null || MetaNotificationsContainer.instance === undefined) {
            MetaNotificationsContainer.instance = new MetaNotificationsContainer();
        }
        return MetaNotificationsContainer.instance;
    }

    constructor() {
        this.notifications = [];
        this.newNotifications = [];
        this.notificationsHandlers = {}
        Object.values(NOTIFICATION_TYPES).forEach(notificationType => {
                this.notificationsHandlers[notificationType] = [];
        })
    }

    setNotificationsHistory(notificationsData) {
        if (notificationsData && notificationsData.notifications && notificationsData.notifications.length) {
            this.notifications = notificationsData.notifications.map(nt => new MetaNotificationContainerItem(nt));
        }
    }

    addNotificationsToHistory(notificationsData) {
        if (notificationsData && notificationsData.notifications && notificationsData.notifications.length) {
            notificationsData.notifications.forEach(nt => {
                this.notifications.push(new MetaNotificationContainerItem(nt));
            })
        }
    }

    handleNewNotification(notificationsData) {
        if (notificationsData && notificationsData.notifications && notificationsData.notifications.length) {
            notificationsData.notifications.forEach(nt => {
                let notification = new MetaNotificationContainerItem(nt);

                let store = GlobalMakerService.$store;
                let auth = GlobalMakerService.$auth;
                let gm = GalleryManager.sharedInstance().gallery;
                if (notification.type === NOTIFICATION_TYPES.IMAGE_APPROVED_NOTIFICATION) {
                    gm.approveImage(notification.imageId);
                } else if(notification.type === NOTIFICATION_TYPES.IMAGE_REJECTED_NOTIFICATION) {
                    gm.rejectImage(notification.imageId);
                } else if(notification.type === NOTIFICATION_TYPES.ACCOUNT_UPDATED_NOTIFICATION) {
                    auth.$storage.setState('user', {
                        ...auth.user,
                        ...nt.user_updated_data
                    });
                    store.commit("application/driver/UPDATE_AUTH_USER", {
                        isEmailVerified: true,
                        emailVerificationDate: nt.email_verification_date,
                    });
                }
                this.newNotifications.unshift(notification);
                this.notifications.unshift(notification);

                this.notificationsHandlers[notification.type]?.forEach((handler) => {
                    handler(notification);
                })
            })
        }
    }

    emptyNewNotifications() {
        this.newNotifications = [];
    }

    emptyNotifications() {
        this.notifications = [];
    }

    subscribe(notification, newHandler) {
        this.notificationsHandlers[notification].push(newHandler);
    }

    unsubscribe(notification, newHandler) {
        if (!this.notificationsHandlers[notification].length) return;
        this.notificationsHandlers[notification] = this.notificationsHandlers[notification].filter((item) => item !== newHandler);
    }
}

export class MetaNotificationContainerItemBase {
    constructor(obj) {
        this.uuid = obj.uuid;
        this.userId = obj.user_id;
        this.type = obj.type;
        this.date = new Date(obj.date);
    }

    getDateInterval() {
        let now = Math.floor(new Date().getTime() / 1000);
        let notificationDate = Math.floor(this.date.getTime() / 1000);
        return parseToDateTimeView(now - notificationDate);
    }
}

export class MetaNotificationContainerItem extends MetaNotificationContainerItemBase {
    constructor(obj) {
        super(obj);
        this.imageId = obj.image_id;

        switch (obj.type) {
            case NOTIFICATION_TYPES.IMAGE_REJECTED_NOTIFICATION:
                this.text = 'Your image was rejected';
                this.reason = obj.reason;
                break;
            case NOTIFICATION_TYPES.IMAGE_APPROVED_NOTIFICATION:
                this.text = 'Your image was approved';
                break;
            case NOTIFICATION_TYPES.ACCOUNT_UPDATED_NOTIFICATION:
                this.text = 'Your account was updated';
                break;
            case NOTIFICATION_TYPES.TEXT_NOTIFICATION:
                this.text = obj.text;
                break;
            case NOTIFICATION_TYPES.BALANCE_UPDATED_NOTIFICATION:
                this.text = 'Your payment processing is complete. Your NFT balance has been updated!';
                break;
            case NOTIFICATION_TYPES.TEXT_TEMPLATE_NOTIFICATION:
                this.text = obj.template || 'Your payment is received but is incomplete. Please pay AMOUNT CURRENCY more to complete the payment.';
                this.values = obj.values;
                break;
        }
    }
}
