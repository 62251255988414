const PASSWORD_ERR = {
    REQUIRED: 'Password is required',
    CONFIRM_REQUIRED: 'Password confirmation is required',
    CONFIRM: 'Passwords do not match',
    ALPHA_NUM: 'Password must contain at least one uppercase letter, one lowercase letter and one number',
    LENGTH: 'Password must be at least 8 characters',
    MAX_LENGTH: 'Password must not exceed 24 characters',
    SPECIAL_CHARS: 'Password must contain at least one special character',
    SPACE: 'Password cannot contain spaces',
};

const EMAIL_ERR = {
    REQUIRED: 'Email is required',
    CONFIRM_REQUIRED: 'Email confirmation is required',
    CONFIRM: "Emails don't match",
    INVALID: 'Please enter a valid email',
    EMAIL_MATCHES_CURRENT: 'New email must be different from current email',
    EXISTS: 'Email already exists',
};

const WALLET_ERR = {
    INVALID: 'Invalid wallet format',
};

const TOKEN_ERR = {
    REQUIRED: 'Token address is required',
    INVALID: 'Invalid token address',
    INVALID_CONTRACT: 'Provided address is not a supported token address',
    ALREADY_IMPORTED: 'Token already imported',
};

const REFERRAL_ERR = {
    INVALID: 'Please enter a valid referral ID',
};

const TOS_ERR = {
    REQUIRED: 'Please accept Terms & Conditions',
};

const AFFILIATE_TOS_ERR = {
    REQUIRED: 'Please accept Affiliate Terms & Conditions',
};

const USERNAME_ERR = {
    REQUIRED: 'Username is required',
    MAX_LENGTH: 'Username must not exceed 255 characters',
    ALREADY_TAKEN: 'Username is already taken. Please choose a different username.',
    INVALID: 'Username must be at least 8 characters, starting with a letter. Use only lowercase letters, numbers, hyphens, dots, and underscores.',
};

const USER_ERR = {
    NO_WALLET_FOUND: 'No wallet found for this user',
    NOT_EXISTS: 'Email does not exist',
    NO_GIFT: 'You can\'t buy a gift for yourself.'
};

const PURCHASE_PERMISSIONS = {
    CANNOT_PURCHASE: 'You cannot purchase this product for this user',
};

const CF_TURNSTILE_ERR = {
    REQUIRED: 'Please complete the captcha',
    INVALID: 'Invalid captcha',
};

const CARD_PIN_CODE = {
    REQUIRED: 'PIN Code is required',
    CONFIRM_REQUIRED: 'PIN Code confirmation is required',
    PIN_MATCHES: 'PIN Code does not match. Please re-enter PIN',
    LENGTH: 'PIN must be 4 digits long',
    NUMBER: 'The PIN should contain only numerical digits',
}

export default {
    PURCHASE_PERMISSIONS,
    PASSWORD_ERR,
    EMAIL_ERR,
    WALLET_ERR,
    TOKEN_ERR,
    REFERRAL_ERR,
    TOS_ERR,
    AFFILIATE_TOS_ERR,
    USERNAME_ERR,
    USER_ERR,
    CARD_PIN_CODE,
    CF_TURNSTILE_ERR,
};
