
import CloudflareTurnstile from './CloudflareTurnstile.vue';
import GymNewButton from '~/components/utils/form-components/GymNewButton.vue';
import createConfirmValidator from '@/core/validators/confirm';
import passwordValidators from '@/core/validators/password';
import ERROR_MESSAGES from '@/core/validators/error-messages';
import AuthService from '~/core/services/api-interaction/AuthService';
import {MetaWorldManager} from "@/core/services/map/MetaWorldManager";
import GlobalTOSPopup from "@/components/popups/GlobalTOSPopup";
import { emailExists } from "@/core/validators/email";
import {PopupHelper} from "@/core/helpers/PopupHelper";
import {isValidUsername, usernameExists} from "@/core/validators/username";
import { BinaryTreeService } from '~/pages/partner-area/my-team/services/BinaryTreeService';
import { createEventName, GOOGLE_ANALYTICS_EVENT_NAMES, GOOGLE_ANALYTICS_EVENT_TYPES } from "~/constants/gtag-events";
import { PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH } from "~/constants/constants";

export default {
    name: "SignUpWithEmail",
    components: {
        GlobalTOSPopup,
        GymNewButton,
        CloudflareTurnstile,
    },
    props: {
        isMobile: {
            type: Boolean,
            default: false,
        },
        loaderVisible: {
            type: Boolean,
            default: false,
        },
        initialFormData: {
            type: Object,
            default: null,
        },
    },
    emits: ['onLoading', 'onError', 'onHaveAccount', 'onSignUp'],
    data () {
        const REGISTRATION_TYPES = {
            WITH_CODE: 'withCode',
            WITHOUT_CODE: 'withoutCode',
        };
        const signUpForm = {
            username: '',
            email: '',
            confirmEmail: '',
            password: '',
            confirmPassword: '',
            turnstile_response: null,
        };
        const refIdForm = {
            referralId: null,
            notUSCitizen: false,
            notUAECitizen: false,
        };

        return {
            signUpForm,
            refIdForm,
            refIdFormRules: {
                referralId: [
                    { required: true, message: this.$t('Referral ID is required'), trigger: 'blur' },
                    { validator: this.validateReferralId, trigger: ['blur', 'change'], message: this.$t(ERROR_MESSAGES.REFERRAL_ERR.INVALID)},
                    { validator: this.checkSpace, trigger: ['blur', 'change'], message: this.$t(ERROR_MESSAGES.REFERRAL_ERR.INVALID)},
                ],
            },
            areActionsDisabled: false,
            sponsorInfo: null,
            REGISTRATION_TYPES,
            registrationType: REGISTRATION_TYPES.WITH_CODE,
            isReferralIdValid: false,
            isGlobalTOSPopupVisible: false,
            isRefIdDetected: false,
            refIdError: false,
            formSubmitted: false,
            showRefIdError: false,
            timer: null,
            sponsorUsername: '',
        };
    },
    computed: {
        isCheckboxesValid () {
            return this.refIdForm.notUSCitizen && this.refIdForm.notUAECitizen
        },
        validationRules () {
            const confirmEmailValidator = createConfirmValidator('email', this.signUpForm, this.$t(ERROR_MESSAGES.EMAIL_ERR.CONFIRM));
            const confirmPasswordValidator = createConfirmValidator('password', this.signUpForm, this.$t(ERROR_MESSAGES.PASSWORD_ERR.CONFIRM, true));
            const { passwordAlphaNumValidator, passwordSpecialCharsValidator, passwordSpaceValidator } = passwordValidators;

            return {
                username: [
                    { required: true, message: this.$t(ERROR_MESSAGES.USERNAME_ERR.REQUIRED), trigger: 'blur' },
                    { max: 255, message: this.$t(ERROR_MESSAGES.USERNAME_ERR.MAX_LENGTH), trigger: ['blur', 'change'] },
                    { validator: isValidUsername, message: this.$t(ERROR_MESSAGES.USERNAME_ERR.INVALID), trigger: ['change', 'blur']},
                    { validator: usernameExists, trigger: ['blur', 'change']},
                ],
                email: [
                    { required: true, message: this.$t(ERROR_MESSAGES.EMAIL_ERR.REQUIRED), trigger: 'blur' },
                    { type: 'email', message: this.$t(ERROR_MESSAGES.EMAIL_ERR.INVALID), trigger: ['blur','change'] },
                    { validator: emailExists, trigger: ['blur', 'change']},
                ],
                confirmEmail: [
                    { validator: confirmEmailValidator, message: this.$t(ERROR_MESSAGES.EMAIL_ERR.CONFIRM), trigger: ['blur', 'change']},
                ],
                password: [
                    { required: true, message: this.$t(ERROR_MESSAGES.PASSWORD_ERR.REQUIRED), trigger: 'blur' },
                    { min: PASSWORD_MIN_LENGTH, message: this.$t(ERROR_MESSAGES.PASSWORD_ERR.LENGTH), trigger: ['blur', 'change'] },
                    { max: PASSWORD_MAX_LENGTH, message: this.$t(ERROR_MESSAGES.PASSWORD_ERR.MAX_LENGTH), trigger: ['blur', 'change'] },
                    { validator: passwordSpecialCharsValidator, message: this.$t(ERROR_MESSAGES.PASSWORD_ERR.SPECIAL_CHARS), trigger: ['blur','change'] },
                    { validator: passwordAlphaNumValidator, message: this.$t(ERROR_MESSAGES.PASSWORD_ERR.ALPHA_NUM), trigger: ['blur', 'change'] },
                    { validator: passwordSpaceValidator, message: this.$t(ERROR_MESSAGES.PASSWORD_ERR.SPACE), trigger: ['blur', 'change'] },
                ],
                confirmPassword: [
                    { validator: confirmPasswordValidator, message: this.$t(ERROR_MESSAGES.PASSWORD_ERR.CONFIRM), trigger: ['blur', 'change'] },
                ],
                turnstile_response: [
                    { required: true, message: this.$t(ERROR_MESSAGES.CF_TURNSTILE_ERR.REQUIRED), trigger: 'blur' },
                ],
            };
        },
    },
    watch: {
        'refIdForm.referralId': {
            async handler (val) {
                const isValidRef = await BinaryTreeService.validateReferralId(val);
                if (isValidRef) {
                    this.sponsorInfo = {
                        code: val,
                    };
                } else {
                    this.sponsorInfo = null;
                }
            },
        },
        isGlobalTOSPopupVisible(newValue) {
            const signUpPopup = document.querySelector('.sign-in-popup.el-dialog');
            if (signUpPopup) signUpPopup.style.display = newValue ? 'none' : 'flex';
        },
    },
    async mounted () {
        this.$gtag.event(GOOGLE_ANALYTICS_EVENT_NAMES.PAGE_VIEW, { page_title: 'Registration' });

        if(this.initialFormData) {
            this.signUpForm = this.initialFormData;
        }

        if (this.$route?.query?.ref) {
            try {
                const isValidRef = await BinaryTreeService.validateReferralId(this.$route?.query?.ref);
                if (isValidRef) {
                    this.sponsorInfo = {};
                    this.sponsorInfo.code = this.$route?.query?.ref;
                    this.refIdForm.referralId = this.$route?.query?.ref;
                    this.isRefIdDetected = true;
                    this.sponsorUsername = BinaryTreeService.sponsorUsername || this.refIdForm.referralId;
                } else {
                    this.showRefIdError = true;
                }
            } catch (e) {
                PopupHelper.showErrorAlert(this.$t(e.message));
            }
        }
    },
    methods: {
        validateSignUpForm (invalidFields, isValid) {
            if (invalidFields === 'referralId') {
                this.refIdError = !isValid;
            }
        },
        async validateRefForm () {
            return new Promise((resolve) => {
                this.$refs.refIdFormRef.validate().then(valid => {
                    if (valid) {
                        this.refIdError = false;
                        this.showRefIdError = false;
                        resolve(true);
                    } else {
                        this.refIdError = true;
                        resolve(false);
                    }
                }).catch(_ => {
                    this.refIdError = true;
                    resolve(false);
                });
            });
        },
        async handleEmailSignUp () {
            this.formSubmitted = true;
            const isRefFormValid = await this.validateRefForm();
            this.$refs.signUpForm.validate().then(async valid => {
                if (valid && isRefFormValid && this.isCheckboxesValid) {
                    this.isGlobalTOSPopupVisible = true;
                }
            });
        },
        async handleAcceptRegister() {
            this.isGlobalTOSPopupVisible = false;

            this.startLoading();
            try {
                this.signUpForm.referral_id = this.refIdForm.referralId;
                const formData = Object.assign({}, this.signUpForm);

                delete formData.confirmEmail;
                delete formData.confirmPassword;

                await AuthService.signUpWithEmail(formData);

                const eventName = createEventName(
                    GOOGLE_ANALYTICS_EVENT_NAMES.REGISTRATION,
                    GOOGLE_ANALYTICS_EVENT_TYPES.SUCCESS
                );
                this.$gtag.event(eventName);

                this.$refs.cfTurnstile.reset();
                const newToken = await this.waitFotCloudFlareTurnstile();
                formData.turnstile_response = newToken;

                this.$auth.loginWith('local', { data: formData });
                this.$emit('onSignUp', this.signUpForm);
            } catch (err) {
                this.$refs.cfTurnstile.reset();
                this.signUpForm.turnstile_response = null;
                this.onError(err);
            }
        },
        waitFotCloudFlareTurnstile() {
            return new Promise((resolve) => {
                this.$refs.cfTurnstile.$on('verify', (token) => {
                    resolve(token);
                });
            });
        },
        startLoading () {
            this.areActionsDisabled = true;
            this.$emit('onLoading');
        },
        onError (errorObject) {
            this.areActionsDisabled = false;
            this.$emit('onError', {
                error: errorObject,
                popupData: {
                    title: "Couldn't sign you up.",
                    message: errorObject.message,
                },
            });
        },
        async handleRegistrationTypeChange (type) {
            if (type === this.REGISTRATION_TYPES.WITHOUT_CODE) {
                this.sponsorInfo = await MetaWorldManager.sharedInstance().getSponsorInfo();
                this.refIdForm.referralId = this.sponsorInfo.code;
            } else {
                this.refIdForm.referralId = '' ;
            }
        },
        showGlobalTOSPopup (isChecked) {
            if (isChecked) {
                this.isGlobalTOSPopupVisible = true;
            }
        },
        showAffiliateTOSPopup (isChecked) {
            if (isChecked) {
                this.$store.dispatch('application/popup-control/setAffiliateTmsPopupData', {
                    standalone: false,
                });
            }
        },
        checkValidRefId (value) {
            this.showRefIdError = false;
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(() => {
                BinaryTreeService.validateReferralId(value);
            }, 800);
        },
        async validateReferralId(rule, value, callback) {
            const isRefValid =  await BinaryTreeService.validateReferralId(value);
            if (!isRefValid) {
                callback(new Error(ERROR_MESSAGES.REFERRAL_ERR.INVALID));
            }
            callback();
        },
        checkSpace (rule, value, callback) {
           const hasSpaces = /\s/.test(value);
            if (hasSpaces) {
                callback(new Error(ERROR_MESSAGES.REFERRAL_ERR.INVALID));
            }
            callback();
        }
    },
};
