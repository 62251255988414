
import {defineComponent} from 'vue';
import {mapActions} from 'vuex';
import GymNewButton from '~/components/utils/form-components/GymNewButton.vue';
import InfoTooltip from '~/components/utils/InfoTooltip.vue';
import {MetaUser} from '~/core/models/MetaUser';
import InfoAlertCard from '~/components/utils/cards/info-alert-card/InfoAlertCard.vue';
import {isCurrentUserEmail, validateEmailAndGetWalletAddress} from '~/core/validators/user';
import ERROR_MESSAGES from '@/core/validators/error-messages';
import MysteryBoxTx from '~/core/components/flows/mystery-box/models/MysteryBoxTx';
import { InfoText } from '~/core/types/purchase-popup-2/InfoText';
import { isValidEmail } from '~/core/validators/email';

const OWNERS = {
    NO_ONE: 'no_one',
    CURRENT_USER: 'me',
    ANOTHER_USER: 'another_user',
}

const PRODUCT_OWNER_OPTIONS = [
    {label: 'No owner', value: OWNERS.NO_ONE},
    {label: 'Purchase for myself', value: OWNERS.CURRENT_USER},
    {label: 'Purchase for another user', value: OWNERS.ANOTHER_USER},
];

export type ProductOwnerSelectionComponent = {
    infoTexts: InfoText[],
    isDropdownOpen: boolean,
    initialProductOwner: typeof OWNERS[keyof typeof OWNERS],
    user: MetaUser,
    productOwnerOptions: typeof PRODUCT_OWNER_OPTIONS,
    productOwner: typeof PRODUCT_OWNER_OPTIONS[keyof typeof PRODUCT_OWNER_OPTIONS],
    isEmailValid: boolean,
    ownerWalletAddress: string,
    emailValidationForm: { ownerEmailAddress: string },
    isWalletAddressLoading: boolean,
    lastEmail: string,
    isOwnerAnotherUser: boolean,
    validationRules: { ownerEmailAddress: object[] },
    isSixDayActiveOnContract: (address: string) => Promise<boolean>,
    isValueAnotherUser: (value: string) => boolean,
    onValidate: (field: string, isValid: boolean) => void,
    onEmailChange: (value: string) => void,
    constructProductOwnerAndEmit: () => void,
}

export default defineComponent<keyof ProductOwnerSelectionComponent, ProductOwnerSelectionComponent>({
    components: {
        GymNewButton,
        InfoTooltip,
        InfoAlertCard,
    },
    props: {
        initialProductOwner: {
            type: String,
            default: OWNERS.CURRENT_USER,
        },
        user: {
            type: MetaUser,
            required: true,
        },
        canBuyFor: {
            type: Function,
            required: false,
            default: null,
        },
        transactionObject: {
            type: MysteryBoxTx,
            required: true,
        },
        infoTexts: {
            type: Array as () => Array<InfoText>,
            required: false,
            default: null,
        },
    },
    emits: [
        'onStartLoading',
        'onStopLoading',
        'onProductOwnerSelected',
        'onClickBack',
    ],
    data() {

        const emailValidationForm = {
            ownerEmailAddress: '',
        };

        const $props = this.$props as unknown as ProductOwnerSelectionComponent;

        return {
            OWNERS,
            productOwnerOptions: PRODUCT_OWNER_OPTIONS,
            productOwner: PRODUCT_OWNER_OPTIONS.find(item => item.value === $props.initialProductOwner),
            isEmailValid: false,
            ownerWalletAddress: null,
            emailValidationForm,
            isWalletAddressLoading: false,
            lastEmail: '',
            permissionTimeout: null,
            disableForOthers: true,
            disableForMe: true,
            isDropdownOpen: false,
            inputError: '',
        };
    },
    computed: {
        isOwnerAnotherUser() {
            return this.productOwner.value === OWNERS.ANOTHER_USER;
        },
        validationRules() {
            const isMe = isCurrentUserEmail.bind(null, this.user.email);
            const validateAndGetAddress = async (_field, value, callback) => {
                this.inputError = '';
                this.isWalletAddressLoading = true;
                let error = null;
                const address = await validateEmailAndGetWalletAddress(_field, value, (err) => {
                    error = err;
                });
                if (error) {
                    if (this.emailValidationForm.ownerEmailAddress === value) {
                        callback(error);
                    } else if (!isValidEmail(this.emailValidationForm.ownerEmailAddress)) {
                        callback(this.$t(ERROR_MESSAGES.EMAIL_ERR.INVALID));
                    } else if (this.emailValidationForm.ownerEmailAddress.toLowerCase() === this.user.email.toLowerCase()) {
                        callback(this.$t(ERROR_MESSAGES.USER_ERR.NO_GIFT));
                    } else {
                        callback();
                    }
                } else if (this.canBuyFor && address) {
                    await this.checkBuyPermission(address, callback);
                } else {
                    this.ownerWalletAddress = address || '';
                }
                if (this.emailValidationForm.ownerEmailAddress === value ||
                    !isValidEmail(this.emailValidationForm.ownerEmailAddress) ||
                    this.emailValidationForm.ownerEmailAddress === this.user.email) {
                    this.isWalletAddressLoading = false;
                }
            };

            return {
                ownerEmailAddress: [
                    {required: true, message: this.$t(ERROR_MESSAGES.EMAIL_ERR.REQUIRED), trigger: ['blur', 'change']},
                    {type: 'email', message: this.$t(ERROR_MESSAGES.EMAIL_ERR.INVALID), trigger: ['blur', 'change']},
                    {validator: isMe, trigger: ['blur', 'change']},
                    {validator: validateAndGetAddress, trigger: ['blur', 'change']},
                ],
            };
        },
    },
    watch: {
        disableForMe: {
            handler(val) {
                if (val) {
                    if (this.disableForOthers) {
                        this.productOwner = this.productOwnerOptions.find(item => item.value === OWNERS.NO_ONE);
                    } else {
                        this.productOwner = this.productOwnerOptions.find(item => item.value === OWNERS.ANOTHER_USER);
                    }
                } else if (this.productOwner.value === OWNERS.NO_ONE) {
                    this.productOwner = this.productOwnerOptions.find(item => item.value === OWNERS.CURRENT_USER);
                }
            },
            immediate: true,
        },
        disableForOthers: {
            handler(val) {
                if (val) {
                    if (this.disableForMe) {
                        this.productOwner = this.productOwnerOptions.find(item => item.value === OWNERS.NO_ONE);
                    } else {
                        this.productOwner = this.productOwnerOptions.find(item => item.value === OWNERS.CURRENT_USER);
                    }
                } else if (this.productOwner.value === OWNERS.NO_ONE) {
                    this.productOwner = this.productOwnerOptions.find(item => item.value === OWNERS.ANOTHER_USER);
                }
            },
            immediate: true,
        },
    },
    async mounted() {
        this.setupInitialBuyPermission();
    },
    beforeDestroy() {
        clearTimeout(this.permissionTimeout);
    },
    methods: {
        ...mapActions('user', ['isSixDayActiveOnContract']),
        isValueAnotherUser(value: string) {
            return value === OWNERS.ANOTHER_USER;
        },
        onValidate(_field: string, isValid: boolean) {
            this.isEmailValid = isValid;
        },
        onEmailChange(val: string) {
            if (val === this.lastEmail) return;
            this.lastEmail = val;
            this.isEmailValid = false;
            this.ownerWalletAddress = '';
        },
        async constructProductOwnerAndEmit() {
            this.$emit('onStartLoading');
            try {
                let walletAddress = this.user.walletAddress || this.user.relatedWalletAddress;
                if (this.productOwner.value === OWNERS.ANOTHER_USER) {
                    walletAddress = this.ownerWalletAddress;
                }
                this.transactionObject.setAccount(walletAddress);
                await this.transactionObject.updatePriceInfo();
                await this.transactionObject.estimateGas();
                this.$emit('onProductOwnerSelected');
                this.$emit('onStopLoading');
            } catch (e) {
                this.$emit('onStopLoading', e);
            }
        },
        async checkBuyPermission(address, callback) {
            try {
                const result = await this.canBuyFor(address);
                if (result) {
                    callback();
                    this.ownerWalletAddress = address || '';
                } else {
                    this.ownerWalletAddress = '';
                    callback(this.$t(ERROR_MESSAGES.PURCHASE_PERMISSIONS.CANNOT_PURCHASE));
                }
            } catch(e) {
                this.ownerWalletAddress = '';
                callback(this.$t(e.message));
            }
        },
        async setupInitialBuyPermission() {
            if (this.canBuyFor) {
                this.$emit('onStartLoading');
                try {
                    const promises = [
                        this.canBuyFor(false),
                        this.canBuyFor(true),
                    ];
                    const [canBuyForOthers, canBuyForMe] = await Promise.all(promises);
                    this.disableForOthers = !canBuyForOthers;
                    this.disableForMe = !canBuyForMe;
                    this.runBuyPermissionInterval();
                    this.$emit('onStopLoading');
                } catch (e) {
                    this.$emit('onStopLoading', e);
                    this.disableForOthers = true;
                    this.disableForMe = true;
                }
            } else {
                this.disableForOthers = false;
                this.disableForMe = false;
            }
        },
        runBuyPermissionInterval() {
            this.permissionTimeout = setTimeout(async () => {
                try {
                    this.disableForOthers = !await this.canBuyFor(false);
                } catch (e) {
                    this.disableForOthers = true;
                }
                if (this.ownerWalletAddress) {
                    await this.checkBuyPermission(this.ownerWalletAddress, (val) => {
                        this.inputError = val;
                    });
                }
                try {
                    this.disableForMe = !await this.canBuyFor(true);
                } catch (e) {
                    this.disableForMe = true;
                }
                this.runBuyPermissionInterval();
            }, 10000);
        },
    },
});
