
import {defineComponent} from 'vue';
import MysteryBoxPurchaseCore from '~/core/components/flows/mystery-box/components/mystery-box-purchase-core/MysteryBoxPurchaseCore.vue';
import {MetaUser} from '~/core/models/MetaUser';
import {FinishReasonEnum} from '~/core/components/flows/mystery-box/constants/FinishReasonEnum';
import {MYSTERY_BOX_PURCHASE_STEPS} from '~/core/components/flows/mystery-box/constants/MysteryBoxPurchaseSteps';
import {MysteryBoxPurchase} from '~/core/components/flows/mystery-box/types/MysteryBoxPurchase';

export type PurchasePopupComponent = {
    popupVisible: boolean;
    user: MetaUser;
    handleClosePurchasePopup: () => void;
}

export default defineComponent<keyof PurchasePopupComponent, PurchasePopupComponent>({
    name: "MysteryBoxPurchasePopup",
    components: {
        MysteryBoxPurchaseCore,
    },
    props: {
        popupVisible: {
            type: Boolean,
            default: false,
        },
        purchase: {
            type: Object as () => MysteryBoxPurchase,
            required: true,
        },
        user: {
            type: MetaUser,
            required: true,
        },
    },
    emits: ['onPopupClose'],
    data() {
        return {
            previousStep: -1,
            currentStep: MYSTERY_BOX_PURCHASE_STEPS.SELECT_PRODUCT_OWNER,
            finishReason: FinishReasonEnum.CLOSED_BY_USER,
        };
    },
    methods: {
        handleStepChanged(step: number) {
            this.previousStep = this.currentStep;
            this.currentStep = step;
        },
        handleClosePurchasePopup() {
            const finishReason = this.finishReason;
            let step = this.currentStep;
            if (this.currentStep === MYSTERY_BOX_PURCHASE_STEPS.TRANSACTION_REJECTED) {
                step = this.previousStep;
            }

            this.$store.dispatch('application/mystery-box/hide', {
                finishReason,
                step,
            });

            this.$emit('onPopupClose');
        },
        updateFinishReason(reason: FinishReasonEnum) {
            this.finishReason = reason;
        },
    },
});
