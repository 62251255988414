
import {PRODUCT_TYPES} from "~/core/services/utils/Constants";
import {MysteryBoxPrize} from "~/core/components/flows/mystery-box/models/MysteryBoxPrize";

export default {
    name: "NftImageComponent",
    props: {
        prizeInfo: {
            type: MysteryBoxPrize,
            default: null,
        },
    },
    data() {
        return {
            PRODUCT_TYPES,
        };
    },
    computed: {
        getBundleDataClass() {
            if (!this.prizeInfo) {
                return '';
            }
            if (this.prizeInfo.nftType === PRODUCT_TYPES.BUNDLE) {
                if (!this.prizeInfo.minerCount && this.prizeInfo.parcelCount) {
                    return `image-${this.PRODUCT_TYPES.PARCEL}`;
                } else {
                    return `image-basic-${PRODUCT_TYPES.BUNDLE}`;
                }
            } else {
                return `image-${this.prizeInfo.nftType}`;
            }
        }
    }
}
