
import {MetaUser} from "~/core/models/MetaUser";
import {copyToClipboard, getHidedAccountAddress} from "~/core/helpers/GlobalHelpers";

export default {
    name: "ProfileDropdown",
    props: {
        user: MetaUser,
        account: String,
    },
    data () {
        return {
            showWeb3Disconnect: false,
            copied: false,
        };
    },
    computed: {
        getHidedAccount () {
            return getHidedAccountAddress(this.user.walletAddress);
        },
        copyIcon () {
            return (this.copied) ? require('~/assets/images/icons/upgraded.svg') : require('~/assets/images/icons/copy.svg');
        },
    },
    methods: {
        hideMenu () {
            const menuElement = this.$refs['profile-dropdown'];
            if (!menuElement) return;
            menuElement?.hide();
        },
        handleCopyWalletAddress (account) {
            copyToClipboard(account);
            this.copied = true;
            setTimeout(() => {
                this.copied = false;
            }, 2000);
        },
        async logOut () {
            this.hideMenu();
            await this.$auth.logout();
            await this.$store.dispatch('application/driver/logOut');
            this.$router.push('/nfts/products');
        },
        goToAccount () {
            this.hideMenu();
            this.$router.push('/account');
        },
        contactSupport () {
            this.hideMenu();
            window.open('mailto:support@gymstreet.io');
        },
    },
};
