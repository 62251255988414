

import {defineComponent} from 'vue'
import GymNewButton from '~/components/utils/form-components/GymNewButton.vue';
import {MetaUser} from "~/core/models/MetaUser";
import MysteryBoxTx from "~/core/components/flows/mystery-box/models/MysteryBoxTx";

export default defineComponent({
    name: 'ResultsCheckingError',
    components: {
        GymNewButton,
    },
    props: {
        user: {
            type: MetaUser,
            required: true,
        },
        transactionObject: {
            type: MysteryBoxTx,
            required: true,
        },
    },
    emits: ['onContactSupport'],
    methods: {
        handleContactSupport() {
            this.$emit('onContactSupport');
        },
    },
});

