

import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import TotalWidget from '../../total-widget/TotalWidget.vue';
import NftImageComponent from './components/nft-image/NftImageComponent.vue';
import StepIndicator from '~/components/utils/indicators/step-indicator/StepIndicator.vue';
import GymNewButton from '~/components/utils/form-components/GymNewButton.vue';
import { toCurrencyFormat, toNumericFormat } from '@/core/helpers/GlobalHelpers';
import { PaymentMethodEnum } from '~/core/components/flows/mystery-box/constants/PaymentMethodEnum';
import { UserBalance } from '~/core/components/flows/mystery-box/models/UserBalance';
import MysteryBoxTx from '~/core/components/flows/mystery-box/models/MysteryBoxTx';

// TODO remove unused props, data and methods
export default defineComponent({
    name: 'PurchaseSummary',
    components: {
        NftImageComponent,
        StepIndicator,
        GymNewButton,
        TotalWidget,
    },
    props: {
        purchase: {
            type: Object,
            required: true,
        },
        userBalance: {
            type: UserBalance,
            default: null,
        },
        transactionObject: {
            type: MysteryBoxTx,
            required: true,
        },
        paymentMethod: {
            type: String,
            required: true,
        },
    },
    emits: [
        'onCryptoSentClick',
        'onClickBack',
        'onStartLoading',
        'onStopLoading',
        'onMissingCryptoAmount',
        'onSuccessFullPayment',
    ],
    data () {
        return {
            gasFeeVisible: false,
            PaymentMethodEnum,
            toCurrencyFormat,
        };
    },
    computed: {
        ...mapGetters('application/purchase-control', [
            'externalPayment',
        ]),
        totalAmount() {
            return this.transactionObject.priceInfo.totalAmount;
        },
        isPaymentMethodInternal () {
            return this.paymentMethod === PaymentMethodEnum.INTERNAL;
        },
        currentStep () {
            if (this.transactionObject.isEnabled) {
                return 2;
            }
            return 1;
        },
        hasEnoughBNBFeeBalance () {
            return this.userBalance.bnb.value >= this.transactionObject.priceInfo.gasAmount.value;
        },
    },
    watch: {
        'transactionObject.isEnabled': {
            handler () {
                setTimeout(() => {
                    this.gasFeeVisible = true;
                }, 100);
            },
            immediate: true,
        },
    },
    mounted () {
        setTimeout(() => {
            this.gasFeeVisible = true;
        }, 100);
    },
    methods: {
        toNumericFormat,
        async handleEnablePayment () {
            this.gasFeeVisible = false;
            try {
                this.$emit('onStartLoading');
                await this.transactionObject.approve();
                await this.transactionObject.checkAllowance(this.totalAmount.value);
                this.transactionObject.clearCache();
                await this.transactionObject.estimateGas();
                this.$emit('onStopLoading');
            } catch (e) {
                this.$emit('onStopLoading', e);
            } finally {
                this.gasFeeVisible = true;
            }
        },
        async handlePay () {
            this.gasFeeVisible = false;
            try {
                this.$emit('onStartLoading');
                await this.transactionObject.send();
                this.$emit('onSuccessFullPayment');
                this.$emit('onStopLoading');
            } catch (e) {
                this.$emit('onStopLoading', e);
            } finally {
                this.gasFeeVisible = true;
            }
        },
    },
});

