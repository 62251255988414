export const DEFAULT_ADDRESS = "0x49A6DaD36768c23eeb75BD253aBBf26AB38BE4EB";
export const DEFAULT_DISCOUNTS = {
    1: [16268, 16788, 27778, 29980, 34432, 40825, 13876, 28587],
    2: [16877, 19085, 24390, 31781],
};
export const turnoverPoolsQualificationMonthly = [
    {
        name:'Pool 1',
        qualification:10000,
        half:5000,
    },
    {
        name:'Pool 2',
        qualification:25000,
        half:12500,
    },
    {
        name:'Pool 3',
        qualification:50000,
        half:25000,
    },
    {
        name:'Pool 4',
        qualification:100000,
        half:50000,
    },
    {
        name:'Pool 5',
        qualification:150000,
        half: 75000,
    },
];

export const turnoverPoolsQualificationGymStreet = [
    {
        name:'Pool 1',
        qualification:400000,
        half:200000,
    },
    {
        name:'Pool 2',
        qualification:800000,
        half:400000,
    },
    {
        name:'Pool 3',
        qualification:1000000,
        half:500000,
    },
];

export const taxPoolsSixMonth = [
    {
        name: "Pool 1",
        participants: 0,
        qualification: 400000,
        rewards: "0.2%",
        contributions: {
            farming: 16869.384565537945,
            land: 0,
            maintenance: 0,
            miner: 0,
            singlePool: 56231.28217312813,
            vault: 36550.33326133283,
        },
    },
    {
        name: "Pool 2",
        participants: 0,
        qualification: 2000000,
        rewards: "0.2%",
        contributions: {
            farming: 16869.384565537945,
            land: 0,
            maintenance: 0,
            miner: 0,
            singlePool: 56231.28217312813,
            vault: 36550.33326133283,
        },
    },
    {
        name: "Pool 3",
        participants: 0,
        qualification: 10000000,
        rewards: "0.2%",
        contributions: {
            farming: 16869.384565537945,
            land: 0,
            maintenance: 0,
            miner: 0,
            singlePool: 56231.28217312813,
            vault: 36550.33326133283,
        },
    },
    {
        name: "Pool 4",
        participants: 0,
        qualification: 20000000,
        rewards: "0.2%",
        contributions: {
            farming: 33738.76913107589,
            land: 0,
            maintenance: 0,
            miner: 0,
            singlePool: 112462.56434625626,
            vault: 73100.66652266566,
        },
    },
];

export const turnoverPoolsQualification = [
    {
        name:'Pool 1',
        qualification:50000,
        half:25000,
    },
    {
        name:'Pool 2',
        qualification:100000,
        half:50000,
    },
    {
        name:'Pool 3',
        qualification:150000,
        half: 75000,
    },
];

export const turnoverPoolsData = [
    {
        name: "Pool 1",
        participants: 0,
        qualification: 10000,
        rewards: "0.5%",
        salesContribution: 0,
        rewardsPerUser: 0,
    },
    {
        name: "Pool 2",
        participants: 0,
        qualification: 25000,
        rewards: "0.5%",
        salesContribution: 0,
        rewardsPerUser: 0,
    },
    {
        name: "Pool 3",
        participants: 0,
        qualification: 50000,
        rewards: "0.5%",
        salesContribution: 0,
        rewardsPerUser: 0,
    },
    {
        name: "Pool 4",
        participants: 0,
        qualification: 100000,
        rewards: "0.5%",
        salesContribution: 0,
        rewardsPerUser: 0,
    },
    {
        name: "Pool 5",
        participants: 0,
        qualification: 150000,
        rewards: "1%",
        salesContribution: 0,
        rewardsPerUser: 0,
    },
];

export const turnoverPoolsMonthlyData = [
    {
        name: "Pool 1",
        participants: 0,
        qualification: 50000,
        rewards: "0.05%",
        salesContribution: 0,
        rewardsPerUser: 0,
    },
    {
        name: "Pool 2",
        participants: 0,
        qualification: 100000,
        rewards: "0.05%",
        salesContribution: 0,
        rewardsPerUser: 0,
    },
    {
        name: "Pool 3",
        participants: 0,
        qualification: 150000,
        rewards: "0.1%",
        salesContribution: 0,
        rewardsPerUser: 0,
    },
];

export const turnoverPoolsSixMonthData = [
    {
        name: "Pool 1",
        participants: 0,
        qualification: 900000,
        rewards: "0.2%",
        salesContribution: 0,
        rewardsPerUser: 0,
    },
    {
        name: "Pool 2",
        participants: 0,
        qualification: 2000000,
        rewards: "0.2%",
        salesContribution: 0,
        rewardsPerUser: 0,
    },
    {
        name: "Pool 3",
        participants: 0,
        qualification: 3000000,
        rewards: "0.2%",
        salesContribution: 0,
        rewardsPerUser: 0,
    },
    {
        name: "Pool 4",
        participants: 0,
        qualification: 5000000,
        rewards: "0.2%",
        salesContribution: 0,
        rewardsPerUser: 0,
    },
];

export const turnoverPoolsSixMonthQualification = [
    {
        name:'Pool 1',
        qualification:900000,
        half:450000,
    },
    {
        name:'Pool 2',
        qualification:2000000,
        half:1000000,
    },
    {
        name:'Pool 3',
        qualification:3000000,
        half: 1500000,
    },
    {
        name:'Pool 4',
        qualification:5000000,
        half: 2500000,
    },
];

export const PartnerAreaPages = [
    'partner-area-career-status',
    'partner-area-my-team',
    'partner-area-partner-statistic',
    'partner-area-level-pool',
    'partner-area-turnover-pool',
    'partner-area-nft-rank-rewards',
    'partner-area-global-line-bonus',
    'partner-area-incentives-fast-start',
    'partner-area-incentives-cash-promo',
    'partner-area-incentives-travel-incentive',
    'partner-area-incentives-turnover-incentive',
    'partner-area-career-status-statistics',
    'partner-area-career-status-feature-voting',
    'partner-area-incentives-spring-incentive',
    'partner-area-incentives-product-autoship-incentive',
    'partner-area-incentives-global-pool',
];

export const binaryRanks = [
    { name: 'Member', rank: 0, CVinWeakLeg: 0, turnover: 0, subscriptionPriceMonthly: 10, subscriptionPriceYearly: 100, nftOwnInvest: 0, nftTurnoverInWeakLeg: 0, requiredPartners: 0, binaryCommissions: 0, maxWeeklyPayout: 0, requiredDepositWeeklyCap: 100, spDeposit: 0, plusMaxWeeklyPayout: 0, canVote: false },
    { name: 'Trainee', rank: 1, CVinWeakLeg: 70, turnover: 200, subscriptionPriceMonthly: 10, subscriptionPriceYearly: 100, nftOwnInvest: 100, nftTurnoverInWeakLeg: 200, requiredPartners: 2, binaryCommissions: 8, maxWeeklyPayout: 250, requiredDepositWeeklyCap: 100, spDeposit: 10000, plusMaxWeeklyPayout: 300, canVote: false },
    { name: 'Rookie', rank: 2, CVinWeakLeg: 175, turnover: 500, subscriptionPriceMonthly: 10, subscriptionPriceYearly: 100, nftOwnInvest: 100, nftTurnoverInWeakLeg: 500, requiredPartners: 2, binaryCommissions: 8, maxWeeklyPayout: 500, requiredDepositWeeklyCap: 200, spDeposit: 15000, plusMaxWeeklyPayout: 600, canVote: false  },
    { name: 'Silver', rank: 3, CVinWeakLeg: 1750, turnover: 5000, subscriptionPriceMonthly: 10, subscriptionPriceYearly: 100, nftOwnInvest: 200, nftTurnoverInWeakLeg: 5000, requiredPartners: 2, binaryCommissions: 9, maxWeeklyPayout: 1000, requiredDepositWeeklyCap: 300, spDeposit: 75000, plusMaxWeeklyPayout: 1250, cashBonus: 100, canVote: false, promoCashBonus: 200  },
    { name: 'Gold', rank: 4, CVinWeakLeg: 8750, turnover: 25000, subscriptionPriceMonthly: 20, subscriptionPriceYearly: 200, nftOwnInvest: 500, nftTurnoverInWeakLeg: 25000, requiredPartners: 2, binaryCommissions: 10, maxWeeklyPayout: 2500, requiredDepositWeeklyCap: 400, spDeposit: 100000, plusMaxWeeklyPayout: 3000, cashBonus: 250, canVote: false, promoCashBonus: 1000  },
    { name: 'Platin', rank: 5, CVinWeakLeg: 35000, turnover: 100000, subscriptionPriceMonthly: 20, subscriptionPriceYearly: 200, nftOwnInvest: 1000, nftTurnoverInWeakLeg: 100000, requiredPartners: 2, binaryCommissions: 10, maxWeeklyPayout: 3500, requiredDepositWeeklyCap: 500, spDeposit: 150000, plusMaxWeeklyPayout: 4500, cashBonus: 1000, canVote: true, promoCashBonus: 2000  },
    { name: 'Double Platin', rank: 6, CVinWeakLeg: 87500, turnover: 250000, subscriptionPriceMonthly: 20, subscriptionPriceYearly: 200, nftOwnInvest: 2000, nftTurnoverInWeakLeg: 250000, requiredPartners: 3, binaryCommissions: 10, maxWeeklyPayout: 5000, requiredDepositWeeklyCap: 1000, spDeposit: 500000, plusMaxWeeklyPayout: 6500, cashBonus: 1500, canVote: true, promoCashBonus: 3000  },
    { name: 'Diamond', rank: 7, CVinWeakLeg: 350000, turnover: 1000000, subscriptionPriceMonthly: 50, subscriptionPriceYearly: 500, nftOwnInvest: 7000, nftTurnoverInWeakLeg: 1000000, requiredPartners: 3, binaryCommissions: 10, maxWeeklyPayout: 12500, requiredDepositWeeklyCap: 5000, spDeposit: 1250000, plusMaxWeeklyPayout: 15000, cashBonus: 2000, canVote: true, promoCashBonus: 4000  },
    { name: 'Double Diamond', rank: 8, CVinWeakLeg: 875000, turnover: 2500000, subscriptionPriceMonthly: 50, subscriptionPriceYearly: 500, nftOwnInvest: 10000, nftTurnoverInWeakLeg: 2500000, requiredPartners: 4, binaryCommissions: 10, maxWeeklyPayout: 25000, requiredDepositWeeklyCap: 10000, spDeposit: 1250000, plusMaxWeeklyPayout: 30000, cashBonus: 4000, canVote: true, promoCashBonus: 8000 },
    { name: 'Red Diamond', rank: 9, CVinWeakLeg: 2100000, turnover: 6000000, subscriptionPriceMonthly: 100, subscriptionPriceYearly: 1000, nftOwnInvest: 20000, nftTurnoverInWeakLeg: 6000000, requiredPartners: 5, binaryCommissions: 10, maxWeeklyPayout: 30000, requiredDepositWeeklyCap: 12000, spDeposit: 1250000, plusMaxWeeklyPayout: 40000, cashBonus: 7500, canVote: true, promoCashBonus: 15000 },
    { name: 'White Diamond', rank: 10, CVinWeakLeg: 8750000, turnover: 25000000, subscriptionPriceMonthly: 100, subscriptionPriceYearly: 1000, nftOwnInvest: 30000, nftTurnoverInWeakLeg: 25000000, requiredPartners: 5, binaryCommissions: 10, maxWeeklyPayout: 50000, requiredDepositWeeklyCap: 15000, spDeposit: 1250000, plusMaxWeeklyPayout: 65000, cashBonus: 10000, canVote: true, promoCashBonus: 20000 },
    { name: 'Crown Diamond', rank: 11, CVinWeakLeg: 21000000, turnover: 60000000, subscriptionPriceMonthly: 100, subscriptionPriceYearly: 1000, nftOwnInvest: 30000, nftTurnoverInWeakLeg: 60000000, requiredPartners: 5, binaryCommissions: 10, maxWeeklyPayout: 150000, requiredDepositWeeklyCap: 20000, spDeposit: 2500000, plusMaxWeeklyPayout: 185000, cashBonus: 75000, canVote: true, promoCashBonus: 150000 },
];

export const affiliatePartnerRank = {
    name: 'Affiliate',
    rank: 0,
    CVinWeakLeg: 0,
    turnover: 0,
    subscriptionPriceMonthly: 0,
    subscriptionPriceYearly: 0,
    nftOwnInvest: 100,
    nftTurnoverInWeakLeg: 0,
    requiredPartners: 0,
    binaryCommissions: 0,
    maxWeeklyPayout: 0,
    spDeposit: 0,
    plusMaxWeeklyPayout: 0
};

export const binaryRanksRequiredPartners = [
    '1 Left, 1 Right',
    '1 Left, 1 Right',
    '1 Left, 1 Right',
    '2 Silver (1 in different leg)',
    '2 Gold (1 in different leg)',
    '3 Platinum (1 in different leg)',
    '3 Double Platinum (1 in different leg)',
    '4 Diamond (1 in different leg)',
    '5 Double Diamond (1 in different leg)',
    '5 Red Diamond (1 in different leg)',
    '5 White Diamond (1 in different leg)',
]

export const RANKS_CONST =  {
    MEMBER: 'Member',
    TRAINEE: 'Trainee',
    ROOKIE: 'Rookie',
    SILVER: 'Silver',
    GOLD: 'Gold',
    PLATIN: 'Platin',
    DOUBLE_PLATIN: 'Double Platin',
    DIAMOND: 'Diamond',
    DOUBLE_DIAMOND: 'Double Diamond',
    RED_DIAMOND: 'Red Diamond',
    WHITE_DIAMOND: 'White Diamond',
    CROWN_DIAMOND: 'Crown Diamond',
}

export const RANKS_ORDER = {
    [RANKS_CONST.MEMBER]: 0,
    [RANKS_CONST.TRAINEE]: 1,
    [RANKS_CONST.ROOKIE]: 2,
    [RANKS_CONST.SILVER]: 3,
    [RANKS_CONST.GOLD]: 4,
    [RANKS_CONST.PLATIN]: 5,
    [RANKS_CONST.DOUBLE_PLATIN]: 6,
    [RANKS_CONST.DIAMOND]: 7,
    [RANKS_CONST.DOUBLE_DIAMOND]: 8,
    [RANKS_CONST.RED_DIAMOND]: 9,
    [RANKS_CONST.WHITE_DIAMOND]: 10,
    [RANKS_CONST.CROWN_DIAMOND]: 11,
};
export const GGYMNET_STAKING = [
    0.07692307692,
    0.09090909091,
    0.1052631579,
    0.125,
    0.1538461538,
    0.2
]

export const RANKS_BENEFITS = {
    [RANKS_CONST.MEMBER]: { binary: null, mbLevel: null },
    [RANKS_CONST.TRAINEE]: { binary: 8, mbLevel: 1 },
    [RANKS_CONST.ROOKIE]: { binary: 8, mbLevel: 2 },
    [RANKS_CONST.SILVER]: { binary: 9, mbLevel: 3 },
    [RANKS_CONST.GOLD]: { binary: 10, mbLevel: 4 },
    [RANKS_CONST.PLATIN]: { binary: 10, mbLevel: 5 },
    [RANKS_CONST.DOUBLE_PLATIN]: { binary: 10, mbLevel: 6 },
    [RANKS_CONST.DIAMOND]: { binary: 10, mbLevel: 7 },
    [RANKS_CONST.DOUBLE_DIAMOND]: { binary: 10, mbLevel: 8 },
    [RANKS_CONST.RED_DIAMOND]: { binary: 10, mbLevel: 9 },
    [RANKS_CONST.WHITE_DIAMOND]: { binary: 10, mbLevel: 10 },
    [RANKS_CONST.CROWN_DIAMOND]: { binary: 10, mbLevel: 11 },
};

export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_MAX_LENGTH = 24;

export const KYCSupportedLanguages= [
    {
        label: 'English',
        idx: 0
    },
    {
        label: 'Spanish',
        idx: 1
    }
]

const TEXT_FOR_NO_PAID_FEE = 'Please activate your card and top up the balance to cover the monthly fee. If the balance isn\'t updated, your card will be deactivated and you\'ll need to purchase a new one.';
const TEXT_TOP_UP_MONTHLY_FEE = 'Please top up the balance to cover the monthly fee. If the balance isn\'t updated, your card will be deactivated and you\'ll need to purchase a new one.';

export const cashFtUserNotPaidFee = new Map([
    [35899, TEXT_TOP_UP_MONTHLY_FEE],
    [36087, TEXT_TOP_UP_MONTHLY_FEE],
    [36043, TEXT_FOR_NO_PAID_FEE],
    [35897, TEXT_FOR_NO_PAID_FEE],
    [25118, TEXT_TOP_UP_MONTHLY_FEE],
])
