import WalletAddress from "~/pages/finances/wallet/models/WalletAddress";
import Asset from './Asset';
import {toBN, toWei, fromWei} from "~/core/helpers/GlobalHelpers";
import ApiService from "~/core/services/api-interaction/ApiService";
import { fileCoinWeb3 } from "~/pages/finances/miner-rewards/services/FileCoinService";
import { MetaInventoryContainer } from "~/core/models/MetaInventoryContainer";
import {newDelegatedEthAddress, checkAddressString} from '@glif/filecoin-address';
import {FileCoinNetwork} from "~/pages/finances/wallet/constants/WalletNetworkConstants";
const currency = 'FIL';
const icon = require('~/assets/images/icons/currencies/fil.png');

const forwarderAddress = "0x2B3ef6906429b580b7b2080de5CA893BC282c225";
const contractABI = [
    {
        "constant": true,
        "inputs": [
            {
                "name": "destination",
                "type": "bytes",
            },
        ],
        "name": "forward",
        "outputs": [],
        "payable": true,
        "stateMutability": "payable",
        "type": "function"
    }
];

export default class FILAsset extends Asset {
    private static forwarderContract = new fileCoinWeb3.eth.Contract(contractABI as any, forwarderAddress);
    private isWeb3User: boolean = false;
    private userWallet: string = '';
    contractAddress: string = '';

    constructor (value: number, rate: number, isInsufficient: boolean, metaWorldManager: any) {
        super(null, null, currency, icon, value, rate, isInsufficient, metaWorldManager);
        const mic = MetaInventoryContainer.sharedInstance();
        this.isWeb3User = !mic.authUserData.isFullyWeb2User;
        this.userWallet = mic.authUserData.walletAddress;
        this.contractAddress = metaWorldManager.getWeb3Contract('FileCoin').options.address;
    }

    async estimateGas(
        gasPrice_placeholder: number,
        addressFrom: WalletAddress,
        addressTo: WalletAddress,
    ): Promise<FILAsset> {
        const filAddressToSendTo = addressTo.startsWith('0x') ? newDelegatedEthAddress(addressTo).toString() : addressTo;
        const toaddress = checkAddressString(filAddressToSendTo);
        const web3 = fileCoinWeb3;
        const weiAmount = toWei(this._value.toString());
        const gasPrice = toBN(await web3.eth.getGasPrice());
        const transaction = {
            from: addressFrom,
            value: weiAmount,
            to: forwarderAddress,
        };
        const gasEstimation = await FILAsset.forwarderContract.methods.forward(Buffer.from(toaddress.bytes)).estimateGas(transaction);
        const gasAmount = toBN(gasEstimation).mul(gasPrice);
        const amount = fromWei(gasAmount.toString());
        const parsedAmount = amount === 0 ? 0 : parseFloat(amount);
        return new FILAsset(
            parsedAmount,
            this._rate,
            false,
            this._metaWorldManager
        );
    }

    async estimateSwapGas(
        path: string[],
        walletAddress: string,
        amount: number,
        amountOutMin: number = 0,
    ) {
        throw new Error('not implemented');
    }



    async send (to: WalletAddress): Promise<unknown> {
        const filAddressToSendTo = to.startsWith('0x') ? newDelegatedEthAddress(to).toString() : to;
        const toAddress = checkAddressString(filAddressToSendTo);
        if (this.isWeb3User) {
            const ethereum = this._metaWorldManager.ethereum();
            if (!ethereum) {
                throw new Error('No ethereum provider to send Filecoin');
            }
            if (ethereum.chainId !== FileCoinNetwork.chainId) {
                throw new Error('Wrong network');
            }
            if (ethereum.selectedAddress?.toLowerCase() !== this.userWallet.toLowerCase()) {
                throw new Error('Wrong wallet');
            }
            const encodedAbi = FILAsset.forwarderContract.methods.forward(Buffer.from(toAddress.bytes)).encodeABI();
            const txParams = {
                from: this.userWallet,
                to: forwarderAddress,
                value: fileCoinWeb3.utils.numberToHex(toWei(this._value.toString())),
                data: encodedAbi,
                gas: undefined,
                gasPrice: undefined,
            };
            const gasPrice = toBN(await fileCoinWeb3.eth.getGasPrice());
            const transaction = {
                from: this.userWallet,
                to: forwarderAddress,
                value: fileCoinWeb3.utils.numberToHex(toWei(this._value.toString())),
            };
            const gasEstimation = await FILAsset.forwarderContract.methods.forward(Buffer.from(toAddress.bytes)).estimateGas(transaction);
            txParams.gas = fileCoinWeb3.utils.numberToHex(gasEstimation);
            txParams.gasPrice = fileCoinWeb3.utils.numberToHex(gasPrice);
            const result = await ethereum.request({
                method: 'eth_sendTransaction',
                params: [txParams],
            });
            return result;
        } else {
            const amount = this._value.toString();
            const sendTransaction = { amount, to: filAddressToSendTo };
            return await ApiService.post('internal-wallet/send-filecoin', sendTransaction);
        }
    }

    subtract(subtrahend: number): FILAsset {
        const parentAmt = super.subtract(subtrahend);
        return new FILAsset(
            subtrahend,
            this._rate,
            parentAmt.isInsufficient,
            this._metaWorldManager,
        );
    }

    copyRate(asset: FILAsset): FILAsset {
        return new FILAsset(
            this.value,
            asset._rate,
            this._isInsufficient,
            this._metaWorldManager,
        );
    }
};
