
    import GymNewButton from '~/components/utils/form-components/GymNewButton.vue';
    import createConfirmValidator from '@/core/validators/confirm';
    import passwordValidators from '@/core/validators/password';
    import ERROR_MESSAGES from '@/core/validators/error-messages';
    import AuthService from '~/core/services/api-interaction/AuthService';
    import { PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH } from "~/constants/constants";

    export default {
        name: "ResetPassword",
        components: {
            GymNewButton,
        },
        emits: ['onLoading', 'onError', 'onPasswordReset'],
        props: {
            isMobile: {
                type: Boolean,
                default: false
            },
            initialFormData: {
                type: Object,
                default: null,
            },
        },
        data() {
            const resetPasswordForm = {
                password: '',
                passwordConfirm: '',
            };
            const confirmPasswordValidator = createConfirmValidator('password', resetPasswordForm, this.$t(ERROR_MESSAGES.PASSWORD_ERR.CONFIRM));
            const { passwordAlphaNumValidator, passwordSpecialCharsValidator, passwordSpaceValidator } = passwordValidators;

            return {
                resetPasswordForm,
                validationRules: {
                    password: [
                        { required: true, message: this.$t(ERROR_MESSAGES.PASSWORD_ERR.REQUIRED), trigger: ['blur', 'change'] },
                        { min: PASSWORD_MIN_LENGTH, message: this.$t(ERROR_MESSAGES.PASSWORD_ERR.LENGTH), trigger: ['blur', 'change'] },
                        { max: PASSWORD_MAX_LENGTH, message: this.$t(ERROR_MESSAGES.PASSWORD_ERR.MAX_LENGTH), trigger: ['blur', 'change'] },
                        { validator: passwordAlphaNumValidator, message: this.$t(ERROR_MESSAGES.PASSWORD_ERR.ALPHA_NUM), trigger: ['blur', 'change'] },
                        { validator: passwordSpecialCharsValidator, message: this.$t(ERROR_MESSAGES.PASSWORD_ERR.SPECIAL_CHARS), trigger: ['blur', 'change'] },
                        { validator: passwordSpaceValidator, message: this.$t(ERROR_MESSAGES.PASSWORD_ERR.SPACE), trigger: ['blur', 'change'] },
                    ],
                    confirmPassword: [
                        { required: true, message: this.$t(ERROR_MESSAGES.PASSWORD_ERR.CONFIRM_REQUIRED), trigger: 'blur' },
                        { min: PASSWORD_MIN_LENGTH, message: this.$t(ERROR_MESSAGES.PASSWORD_ERR.LENGTH), trigger: ['blur', 'change'] },
                        { max: PASSWORD_MAX_LENGTH, message: this.$t(ERROR_MESSAGES.PASSWORD_ERR.MAX_LENGTH), trigger: ['blur', 'change'] },
                        { validator: passwordAlphaNumValidator, message: this.$t(ERROR_MESSAGES.PASSWORD_ERR.ALPHA_NUM), trigger: ['blur', 'change'] },
                        { validator: passwordSpecialCharsValidator, message: this.$t(ERROR_MESSAGES.PASSWORD_ERR.SPECIAL_CHARS), trigger: ['blur', 'change'] },
                        { validator: passwordSpaceValidator, message: this.$t(ERROR_MESSAGES.PASSWORD_ERR.SPACE), trigger: ['blur', 'change'] },
                        { validator: confirmPasswordValidator, message: this.$t(ERROR_MESSAGES.PASSWORD_ERR.CONFIRM), trigger: ['blur', 'change'] },
                    ],
                },
                recoverToken: null,
                areActionsDisabled: false,
            }
        },
        mounted() {
            if(this.initialFormData) {
                this.resetPasswordForm = this.initialFormData;
            }
            this.recoverToken = this.$route.query.token;
        },
        methods: {
            onConfirm() {
                this.$refs.resetPasswordForm.validate().then(async valid => {
                    if (valid) {
                        this.startLoading();
                        try {
                            await AuthService.resetPassword(this.resetPasswordForm.password, this.recoverToken);
                            this.$router.push('/');
                            this.$emit('onPasswordReset', this.resetPasswordForm);
                        } catch (e) {
                            this.onError(e);
                        }
                    }
                }).catch(() => {
                    console.log('Form validation error');
                });
            },
            startLoading() {
                this.areActionsDisabled = true;
                this.$emit('onLoading');
            },
            onError(errorObject) {
                this.areActionsDisabled = false;
                this.$emit('onError', {
                    error: errorObject,
                    popupData: {
                        title: "Couldn't reset your password.",
                        message: errorObject.message,
                    }
                });
            },
        }
    }
