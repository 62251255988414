

import {METAVERSE_IDS, METAVERSES} from '@/core/services/utils/MetaverseConstants';
import { mapGetters } from 'vuex';

export default {
    name: 'MetaverseSwitcher',
    data() {
        return {
            metaverseIds: Object.values(METAVERSE_IDS),
            METAVERSES,
            showDropdown: false,
        };
    },
    computed: {
        ...mapGetters('map/mapModule', ['metaverseId']),
    },
    methods: {
        switchMetaverse(metaverse) {
            if(metaverse !== this.$metaverseId()) {
                this.$setMetaverseId(metaverse);
            }
        },
        toggleDropdown() {
            this.showDropdown = !this.showDropdown
        },
        closeDropdown() {
            this.showDropdown = false
        }
    }
}
