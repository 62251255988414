
const lngKeys = {
    en: 'English',
    de: 'Deutsch',
    es: "Español",
    fr: "Français",
    jp: '日本語',
    ko: "한국어",
    pt: "Português",
    ru: "Русский",
    th: "ไทย",
    vn: "Tiếng Việt",
    zh: "中文",
}

const flagKeys = {
    en: 'gb',
    de: 'de',
    es: "es",
    fr: "fr",
    jp: 'jp',
    ko: 'kr',
    pt: "pt",
    ru: "ru",
    th: "th",
    vn: "vn",
    zh: 'cn'
}

export default {
    name: 'LocaleSwitcher',
    data: () => ({
        currentLocale: '',
        availableLocales: [],
        showDropdown: false,
        lngKeys,
        flagKeys,
        lngLocales: null
    }),

    mounted() {
        setTimeout(() => {
            this.currentLocale = this.$i18n.locale ? this.$i18n.locale : this.$i18n.defaultLocale
            this.availableLocales = this.$i18n.availableLocales
        }, 0)
    },

    methods: {
        switchLocale(locale) {
            if (process.browser) {
                localStorage.setItem('locale', locale);
            }
            this.showDropdown = !this.showDropdown
            this.$i18n.locale = locale
            this.currentLocale = locale
        },

        toggleDropdown() {
            this.showDropdown = !this.showDropdown;
        },
        closeDropdown() {
            this.showDropdown = false;
        }
    },

    watch: {
        '$i18n.locale': {
            handler() {
                this.currentLocale = this.$i18n.locale ? this.$i18n.locale : this.$i18n.defaultLocale
            }
        }
    }
}
