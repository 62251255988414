import {MetaWorldManager} from "~/core/services/map/MetaWorldManager";
import {getClientRuntimeConfig, toWei} from "~/core/helpers/GlobalHelpers";
import ApiService from "~/core/services/api-interaction/ApiService";
import {PopupHelper} from "~/core/helpers/PopupHelper";
import {SubscriptionService} from "~/pages/nfts/products/services/SubscriptionService";
import {MetaInventoryContainer} from "~/core/models/MetaInventoryContainer";

const $config = getClientRuntimeConfig();
const BINARY_API = `${$config.binaryTreeApi}/api/subscription`;

let subscriptionExpiresAtTimer = null;

export const state = () => {
    return {
        isSubscriptionLoading: false,
        isClosed: true,
    };
};

export const actions = {
    approveAllowance ({ dispatch }, amount) {
        return dispatch(
            "transactions/send",
            {
                callback: this.$write[this.$metaverseId()].USDT.methods.approve(this.$contracts[this.$metaverseId()].BinarySubscription._address, toWei(amount)),
            },
            { root: true },
        );
    },
    async getExpiration ({commit, dispatch}) {
        try {
            const expirationData = await ApiService.get(`${BINARY_API}/getexpiration`);

            console.log('expirationData', expirationData)
        } catch (e) {

        }
    },

    buySubscription ({commit, dispatch}, {amount, months}) {
        commit('SET_BINARY_SUBSCRIPTION_LOADING', true);
        const address = MetaWorldManager.sharedInstance().getWalletAddressForFetchInfo();
        return dispatch(
            "transactions/send",
            {
                callback: this.$write[this.$metaverseId()].BinarySubscription.methods.purchaseSubscription(
                    address,
                    toWei(amount),
                    months,
                ),
            },
            { root: true },
        ).then(() => {
            PopupHelper.showSuccessNotification('Transaction Success');
            return dispatch("getExpiration");

        }).finally(() => {
            commit('SET_BINARY_SUBSCRIPTION_LOADING', false);
        });
    },

    setClosedEvent ({ commit }, value) {
        commit('SET_CLOSED_EVENT', value);
    },

    async updateUserSubscriptionExpires({ commit }) {
        const currentUserSubscriptionExpiresAt = MetaInventoryContainer.sharedInstance().authUserData?.subscriptionExpiresAt;

        subscriptionExpiresAtTimer = setInterval(async () => {
            const userData = await SubscriptionService.getBinaryUsers();

            if (
                (currentUserSubscriptionExpiresAt && userData.subscription_expires_at > currentUserSubscriptionExpiresAt) ||
                !currentUserSubscriptionExpiresAt && userData.subscription_expires_at
            ) {
                await commit('application/driver/UPDATE_AUTH_USER', {
                    subscriptionExpiresAt: userData.subscription_expires_at,
                    activeSubscriptionAbonement: userData?.user?.activeSubscriptionAbonement
                }, { root: true });
                clearInterval(subscriptionExpiresAtTimer);
            }
        }, 2000);

        setTimeout(() => {
            clearInterval(subscriptionExpiresAtTimer);
        }, 60000);
    },
};

export const mutations = {
    SET_BINARY_SUBSCRIPTION_LOADING (state, value) {
        state.isSubscriptionLoading = value;
    },
    SET_CLOSED_EVENT (state, value) {
        state.isClosed = value;
    },
};

export const getters = {
    isSubscriptionLoading: ({isSubscriptionLoading}) => isSubscriptionLoading,
    isClosed: ({isClosed}) => isClosed,
};
