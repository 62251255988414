import {
    FILECOIN_CLAIM_IN_PROCESS,
    MINER_REWARDS_CLAIM
} from "~/pages/finances/miner-rewards/constants/ClaimStorageConstants";
import { WITHDRAW_STATUSES_PENDING } from "~/pages/finances/commissions/constants/ClaimStorageConstants";

export class DataService {
    static storeToLocalStorage(key: string, data: any) {
        if (process.client) {
            localStorage.setItem(key, JSON.stringify(data))
        }
    }

    static getFromLocalStorage(key: string) {
        if (process.client) {
            let data = localStorage.getItem(key)
            if (!data) return
            try {
                return JSON.parse(data);
            } catch (e) {
                console.log(e)
                return null;
            }
        }
    }

    static removeFromLocalStorage(key: string) {
        if (process.client) {
            localStorage.removeItem(key);
        }
    }

    static isExistsInLocalStorage(key: string): boolean {
        if (process.client) {
            return !!localStorage.getItem(key);
        }

        return false;
    }

    static removeAllClaimStatusesFromLocalStorage() {
        DataService.removeFromLocalStorage(MINER_REWARDS_CLAIM);
        DataService.removeFromLocalStorage(FILECOIN_CLAIM_IN_PROCESS);
        DataService.removeFromLocalStorage(WITHDRAW_STATUSES_PENDING);
    }
}
