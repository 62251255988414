export const AffiliatePartnersLegsLinks = () => import('../../components/affiliateLegsLinks/AffiliatePartnersLegsLinks.vue' /* webpackChunkName: "components/affiliate-partners-legs-links" */).then(c => wrapFunctional(c.default || c))
export const BecomeAffiliatePartner = () => import('../../components/becomeAffiliatePartner/BecomeAffiliatePartner.vue' /* webpackChunkName: "components/become-affiliate-partner" */).then(c => wrapFunctional(c.default || c))
export const BonusHistoryTable = () => import('../../components/bonusHistoryTable/BonusHistoryTable.vue' /* webpackChunkName: "components/bonus-history-table" */).then(c => wrapFunctional(c.default || c))
export const BuyProductsForReceiveBenefits = () => import('../../components/buyProductsForReceiveBenefits/BuyProductsForReceiveBenefits.vue' /* webpackChunkName: "components/buy-products-for-receive-benefits" */).then(c => wrapFunctional(c.default || c))
export const CustomerAffiliateRoleModal = () => import('../../components/customerAffiliateRoleModal/CustomerAffiliateRoleModal.vue' /* webpackChunkName: "components/customer-affiliate-role-modal" */).then(c => wrapFunctional(c.default || c))
export const GlobalPopups = () => import('../../components/globalPopups/GlobalPopups.vue' /* webpackChunkName: "components/global-popups" */).then(c => wrapFunctional(c.default || c))
export const HeaderGYMHeader = () => import('../../components/header/GYMHeader.vue' /* webpackChunkName: "components/header-g-y-m-header" */).then(c => wrapFunctional(c.default || c))
export const NftIncentiveCardNFTIncentiveCard = () => import('../../components/nftIncentiveCard/NFTIncentiveCard.vue' /* webpackChunkName: "components/nft-incentive-card-n-f-t-incentive-card" */).then(c => wrapFunctional(c.default || c))
export const NftRaceProgressNFTRaceProgress = () => import('../../components/nftRaceProgress/NFTRaceProgress.vue' /* webpackChunkName: "components/nft-race-progress-n-f-t-race-progress" */).then(c => wrapFunctional(c.default || c))
export const PopupsAcceptAffiliateTOSPopup = () => import('../../components/popups/AcceptAffiliateTOSPopup.vue' /* webpackChunkName: "components/popups-accept-affiliate-t-o-s-popup" */).then(c => wrapFunctional(c.default || c))
export const PopupsAssignImagePopup = () => import('../../components/popups/AssignImagePopup.vue' /* webpackChunkName: "components/popups-assign-image-popup" */).then(c => wrapFunctional(c.default || c))
export const PopupsComfirmationPopup = () => import('../../components/popups/ComfirmationPopup.vue' /* webpackChunkName: "components/popups-comfirmation-popup" */).then(c => wrapFunctional(c.default || c))
export const PopupsFileUploadPopup = () => import('../../components/popups/FileUploadPopup.vue' /* webpackChunkName: "components/popups-file-upload-popup" */).then(c => wrapFunctional(c.default || c))
export const PopupsFinalizeRegistrationPopup = () => import('../../components/popups/FinalizeRegistrationPopup.vue' /* webpackChunkName: "components/popups-finalize-registration-popup" */).then(c => wrapFunctional(c.default || c))
export const PopupsForceEmailConfirmationPopup = () => import('../../components/popups/ForceEmailConfirmationPopup.vue' /* webpackChunkName: "components/popups-force-email-confirmation-popup" */).then(c => wrapFunctional(c.default || c))
export const PopupsGalleryGuidePopup = () => import('../../components/popups/GalleryGuidePopup.vue' /* webpackChunkName: "components/popups-gallery-guide-popup" */).then(c => wrapFunctional(c.default || c))
export const PopupsGlobalTOSPopup = () => import('../../components/popups/GlobalTOSPopup.vue' /* webpackChunkName: "components/popups-global-t-o-s-popup" */).then(c => wrapFunctional(c.default || c))
export const PopupsGymSignInPopup = () => import('../../components/popups/GymSignInPopup.vue' /* webpackChunkName: "components/popups-gym-sign-in-popup" */).then(c => wrapFunctional(c.default || c))
export const PopupsLoadingPopup = () => import('../../components/popups/LoadingPopup.vue' /* webpackChunkName: "components/popups-loading-popup" */).then(c => wrapFunctional(c.default || c))
export const PopupsMetaMaskInstallationPopup = () => import('../../components/popups/MetaMaskInstallationPopup.vue' /* webpackChunkName: "components/popups-meta-mask-installation-popup" */).then(c => wrapFunctional(c.default || c))
export const PopupsMinimapMobilePopup = () => import('../../components/popups/MinimapMobilePopup.vue' /* webpackChunkName: "components/popups-minimap-mobile-popup" */).then(c => wrapFunctional(c.default || c))
export const PopupsNFTActionPopup = () => import('../../components/popups/NFTActionPopup.vue' /* webpackChunkName: "components/popups-n-f-t-action-popup" */).then(c => wrapFunctional(c.default || c))
export const PopupsPromptPopup = () => import('../../components/popups/PromptPopup.vue' /* webpackChunkName: "components/popups-prompt-popup" */).then(c => wrapFunctional(c.default || c))
export const PopupsSelectedParcelInfoResponsiveDrawer = () => import('../../components/popups/SelectedParcelInfoResponsiveDrawer.vue' /* webpackChunkName: "components/popups-selected-parcel-info-responsive-drawer" */).then(c => wrapFunctional(c.default || c))
export const PopupsSixDayIncentivePopup = () => import('../../components/popups/SixDayIncentivePopup.vue' /* webpackChunkName: "components/popups-six-day-incentive-popup" */).then(c => wrapFunctional(c.default || c))
export const PopupsSuccessPopup = () => import('../../components/popups/SuccessPopup.vue' /* webpackChunkName: "components/popups-success-popup" */).then(c => wrapFunctional(c.default || c))
export const PopupsUsernamePopup = () => import('../../components/popups/UsernamePopup.vue' /* webpackChunkName: "components/popups-username-popup" */).then(c => wrapFunctional(c.default || c))
export const PopupsViewParcelCoordinatesPopup = () => import('../../components/popups/ViewParcelCoordinatesPopup.vue' /* webpackChunkName: "components/popups-view-parcel-coordinates-popup" */).then(c => wrapFunctional(c.default || c))
export const TurnoverPoolTabs = () => import('../../components/turnoverPool/TurnoverPoolTabs.vue' /* webpackChunkName: "components/turnover-pool-tabs" */).then(c => wrapFunctional(c.default || c))
export const UpgradeWeeklyCapDepositSinglePoolInput = () => import('../../components/upgradeWeeklyCap/DepositSinglePoolInput.vue' /* webpackChunkName: "components/upgrade-weekly-cap-deposit-single-pool-input" */).then(c => wrapFunctional(c.default || c))
export const UpgradeWeeklyCapDepositToSP = () => import('../../components/upgradeWeeklyCap/DepositToSP.vue' /* webpackChunkName: "components/upgrade-weekly-cap-deposit-to-s-p" */).then(c => wrapFunctional(c.default || c))
export const UpgradeWeeklyCapDepositToSPLockedUnlocked = () => import('../../components/upgradeWeeklyCap/DepositToSPLockedUnlocked.vue' /* webpackChunkName: "components/upgrade-weekly-cap-deposit-to-s-p-locked-unlocked" */).then(c => wrapFunctional(c.default || c))
export const UpgradeWeeklyCapDepositWeb2Modal = () => import('../../components/upgradeWeeklyCap/DepositWeb2Modal.vue' /* webpackChunkName: "components/upgrade-weekly-cap-deposit-web2-modal" */).then(c => wrapFunctional(c.default || c))
export const UpgradeWeeklyCapDepositWeb2ModalLockedUnlocked = () => import('../../components/upgradeWeeklyCap/DepositWeb2ModalLockedUnlocked.vue' /* webpackChunkName: "components/upgrade-weekly-cap-deposit-web2-modal-locked-unlocked" */).then(c => wrapFunctional(c.default || c))
export const UpgradeWeeklyCapLockingPeriodSlider = () => import('../../components/upgradeWeeklyCap/LockingPeriodSlider.vue' /* webpackChunkName: "components/upgrade-weekly-cap-locking-period-slider" */).then(c => wrapFunctional(c.default || c))
export const UpgradeWeeklyCapContainer = () => import('../../components/upgradeWeeklyCap/UpgradeWeeklyCapContainer.vue' /* webpackChunkName: "components/upgrade-weekly-cap-container" */).then(c => wrapFunctional(c.default || c))
export const UtilsClipboardCopier = () => import('../../components/utils/ClipboardCopier.vue' /* webpackChunkName: "components/utils-clipboard-copier" */).then(c => wrapFunctional(c.default || c))
export const UtilsCommissionDistributionCountdown = () => import('../../components/utils/CommissionDistributionCountdown.vue' /* webpackChunkName: "components/utils-commission-distribution-countdown" */).then(c => wrapFunctional(c.default || c))
export const UtilsConfirmModal = () => import('../../components/utils/ConfirmModal.vue' /* webpackChunkName: "components/utils-confirm-modal" */).then(c => wrapFunctional(c.default || c))
export const UtilsContractAddress = () => import('../../components/utils/ContractAddress.vue' /* webpackChunkName: "components/utils-contract-address" */).then(c => wrapFunctional(c.default || c))
export const UtilsCountdown = () => import('../../components/utils/Countdown.vue' /* webpackChunkName: "components/utils-countdown" */).then(c => wrapFunctional(c.default || c))
export const UtilsCountdownWithTextInlined = () => import('../../components/utils/CountdownWithTextInlined.vue' /* webpackChunkName: "components/utils-countdown-with-text-inlined" */).then(c => wrapFunctional(c.default || c))
export const UtilsCustomBoxLoader = () => import('../../components/utils/CustomBoxLoader.vue' /* webpackChunkName: "components/utils-custom-box-loader" */).then(c => wrapFunctional(c.default || c))
export const UtilsCustomLoader = () => import('../../components/utils/CustomLoader.vue' /* webpackChunkName: "components/utils-custom-loader" */).then(c => wrapFunctional(c.default || c))
export const UtilsDividerWithText = () => import('../../components/utils/DividerWithText.vue' /* webpackChunkName: "components/utils-divider-with-text" */).then(c => wrapFunctional(c.default || c))
export const UtilsGYMNETInfoStatus = () => import('../../components/utils/GYMNETInfoStatus.vue' /* webpackChunkName: "components/utils-g-y-m-n-e-t-info-status" */).then(c => wrapFunctional(c.default || c))
export const UtilsGymCountdown = () => import('../../components/utils/GymCountdown.vue' /* webpackChunkName: "components/utils-gym-countdown" */).then(c => wrapFunctional(c.default || c))
export const UtilsIncentiveHasEndedLabel = () => import('../../components/utils/IncentiveHasEndedLabel.vue' /* webpackChunkName: "components/utils-incentive-has-ended-label" */).then(c => wrapFunctional(c.default || c))
export const UtilsInfoTooltip = () => import('../../components/utils/InfoTooltip.vue' /* webpackChunkName: "components/utils-info-tooltip" */).then(c => wrapFunctional(c.default || c))
export const UtilsParcelImageCropper = () => import('../../components/utils/ParcelImageCropper.vue' /* webpackChunkName: "components/utils-parcel-image-cropper" */).then(c => wrapFunctional(c.default || c))
export const UtilsPriceInfo = () => import('../../components/utils/PriceInfo.vue' /* webpackChunkName: "components/utils-price-info" */).then(c => wrapFunctional(c.default || c))
export const UtilsPriceInfoMini = () => import('../../components/utils/PriceInfoMini.vue' /* webpackChunkName: "components/utils-price-info-mini" */).then(c => wrapFunctional(c.default || c))
export const UtilsRegisterForm = () => import('../../components/utils/RegisterForm.vue' /* webpackChunkName: "components/utils-register-form" */).then(c => wrapFunctional(c.default || c))
export const UtilsScheduledMaintenanceAlert = () => import('../../components/utils/ScheduledMaintenanceAlert.vue' /* webpackChunkName: "components/utils-scheduled-maintenance-alert" */).then(c => wrapFunctional(c.default || c))
export const UtilsSingleProductCard = () => import('../../components/utils/SingleProductCard.vue' /* webpackChunkName: "components/utils-single-product-card" */).then(c => wrapFunctional(c.default || c))
export const UtilsSmallCountdown = () => import('../../components/utils/SmallCountdown.vue' /* webpackChunkName: "components/utils-small-countdown" */).then(c => wrapFunctional(c.default || c))
export const UtilsSpecialOffersBundle = () => import('../../components/utils/SpecialOffersBundle.vue' /* webpackChunkName: "components/utils-special-offers-bundle" */).then(c => wrapFunctional(c.default || c))
export const UtilsTab = () => import('../../components/utils/Tab.vue' /* webpackChunkName: "components/utils-tab" */).then(c => wrapFunctional(c.default || c))
export const UtilsTabs = () => import('../../components/utils/Tabs.vue' /* webpackChunkName: "components/utils-tabs" */).then(c => wrapFunctional(c.default || c))
export const UtilsTestComponent = () => import('../../components/utils/TestComponent.vue' /* webpackChunkName: "components/utils-test-component" */).then(c => wrapFunctional(c.default || c))
export const UtilsThreeDotDropdown = () => import('../../components/utils/ThreeDotDropdown.vue' /* webpackChunkName: "components/utils-three-dot-dropdown" */).then(c => wrapFunctional(c.default || c))
export const UtilsThreeDotDropdownAction = () => import('../../components/utils/ThreeDotDropdownAction.vue' /* webpackChunkName: "components/utils-three-dot-dropdown-action" */).then(c => wrapFunctional(c.default || c))
export const UtilsUserBalance = () => import('../../components/utils/UserBalance.vue' /* webpackChunkName: "components/utils-user-balance" */).then(c => wrapFunctional(c.default || c))
export const UtilsUserInventoryInfo = () => import('../../components/utils/UserInventoryInfo.vue' /* webpackChunkName: "components/utils-user-inventory-info" */).then(c => wrapFunctional(c.default || c))
export const UtilsUserInventoryItemInfo = () => import('../../components/utils/UserInventoryItemInfo.vue' /* webpackChunkName: "components/utils-user-inventory-item-info" */).then(c => wrapFunctional(c.default || c))
export const AsideAsideGYMAside = () => import('../../components/aside/aside/GYMAside.vue' /* webpackChunkName: "components/aside-aside-g-y-m-aside" */).then(c => wrapFunctional(c.default || c))
export const AsideAsideBottomSheetGYMAsideBottomSheet = () => import('../../components/aside/aside-bottom-sheet/GYMAsideBottomSheet.vue' /* webpackChunkName: "components/aside-aside-bottom-sheet-g-y-m-aside-bottom-sheet" */).then(c => wrapFunctional(c.default || c))
export const AsideMenuGYMMenu = () => import('../../components/aside/menu/GYMMenu.vue' /* webpackChunkName: "components/aside-menu-g-y-m-menu" */).then(c => wrapFunctional(c.default || c))
export const AsideMenuGYMMenuItem = () => import('../../components/aside/menu/GYMMenuItem.vue' /* webpackChunkName: "components/aside-menu-g-y-m-menu-item" */).then(c => wrapFunctional(c.default || c))
export const AsideMenuUsernameStatusContent = () => import('../../components/aside/menu/UsernameStatusContent.vue' /* webpackChunkName: "components/aside-menu-username-status-content" */).then(c => wrapFunctional(c.default || c))
export const BonusHistoryItem = () => import('../../components/bonusHistoryTable/bonusHistoryItem/BonusHistoryItem.vue' /* webpackChunkName: "components/bonus-history-item" */).then(c => wrapFunctional(c.default || c))
export const BonusHistoryItemMobile = () => import('../../components/bonusHistoryTable/bonusHistoryItemMobile/BonusHistoryItemMobile.vue' /* webpackChunkName: "components/bonus-history-item-mobile" */).then(c => wrapFunctional(c.default || c))
export const CashFTPurchaseCardModal = () => import('../../components/cashFT/cashFTPurchaseCardModal/CashFTPurchaseCardModal.vue' /* webpackChunkName: "components/cash-f-t-purchase-card-modal" */).then(c => wrapFunctional(c.default || c))
export const CountdownsMenuCountdown = () => import('../../components/countdowns/menu-countdown/MenuCountdown.vue' /* webpackChunkName: "components/countdowns-menu-countdown" */).then(c => wrapFunctional(c.default || c))
export const CountdownsYellowCountdown = () => import('../../components/countdowns/yellow-countdown/YellowCountdown.vue' /* webpackChunkName: "components/countdowns-yellow-countdown" */).then(c => wrapFunctional(c.default || c))
export const HeaderComponentsBalanceSection = () => import('../../components/header/components/BalanceSection.vue' /* webpackChunkName: "components/header-components-balance-section" */).then(c => wrapFunctional(c.default || c))
export const HeaderComponentsContractAddressesDropDown = () => import('../../components/header/components/ContractAddressesDropDown.vue' /* webpackChunkName: "components/header-components-contract-addresses-drop-down" */).then(c => wrapFunctional(c.default || c))
export const HeaderAlertCountdown = () => import('../../components/header/components/HeaderAlertCountdown.vue' /* webpackChunkName: "components/header-alert-countdown" */).then(c => wrapFunctional(c.default || c))
export const HeaderComponentsMetaverseSwitcher = () => import('../../components/header/components/MetaverseSwitcher.vue' /* webpackChunkName: "components/header-components-metaverse-switcher" */).then(c => wrapFunctional(c.default || c))
export const HeaderComponentsNotificationDropdown = () => import('../../components/header/components/NotificationDropdown.vue' /* webpackChunkName: "components/header-components-notification-dropdown" */).then(c => wrapFunctional(c.default || c))
export const HeaderComponentsNotificationDropdownItem = () => import('../../components/header/components/NotificationDropdownItem.vue' /* webpackChunkName: "components/header-components-notification-dropdown-item" */).then(c => wrapFunctional(c.default || c))
export const HeaderComponentsParcelInfoSection = () => import('../../components/header/components/ParcelInfoSection.vue' /* webpackChunkName: "components/header-components-parcel-info-section" */).then(c => wrapFunctional(c.default || c))
export const HeaderComponentsParcelPriceIndexChart = () => import('../../components/header/components/ParcelPriceIndexChart.vue' /* webpackChunkName: "components/header-components-parcel-price-index-chart" */).then(c => wrapFunctional(c.default || c))
export const HeaderComponentsProfileDropdown = () => import('../../components/header/components/ProfileDropdown.vue' /* webpackChunkName: "components/header-components-profile-dropdown" */).then(c => wrapFunctional(c.default || c))
export const LevelPoolGymstreetLevelPoolTabs = () => import('../../components/levelPool/GymstreetLevelPoolTabs/GymstreetLevelPoolTabs.vue' /* webpackChunkName: "components/level-pool-gymstreet-level-pool-tabs" */).then(c => wrapFunctional(c.default || c))
export const LevelPoolGymstreetLevelsContent = () => import('../../components/levelPool/GymstreetLevelsContent/GymstreetLevelsContent.vue' /* webpackChunkName: "components/level-pool-gymstreet-levels-content" */).then(c => wrapFunctional(c.default || c))
export const LevelPoolGymstreetTab = () => import('../../components/levelPool/GymstreetTab/GymstreetTab.vue' /* webpackChunkName: "components/level-pool-gymstreet-tab" */).then(c => wrapFunctional(c.default || c))
export const LevelPoolLevelsCard = () => import('../../components/levelPool/levelsCard/LevelsCard.vue' /* webpackChunkName: "components/level-pool-levels-card" */).then(c => wrapFunctional(c.default || c))
export const LevelPoolTransactionHistoryLevelPool = () => import('../../components/levelPool/transactionHistory/TransactionHistoryLevelPool.vue' /* webpackChunkName: "components/level-pool-transaction-history-level-pool" */).then(c => wrapFunctional(c.default || c))
export const LevelPoolTurnoverProgressGymstreet = () => import('../../components/levelPool/turnoverProgress/TurnoverProgressGymstreet.vue' /* webpackChunkName: "components/level-pool-turnover-progress-gymstreet" */).then(c => wrapFunctional(c.default || c))
export const LevelPoolTurnoverRuleModalGymstreet = () => import('../../components/levelPool/turnoverRuleModal/TurnoverRuleModalGymstreet.vue' /* webpackChunkName: "components/level-pool-turnover-rule-modal-gymstreet" */).then(c => wrapFunctional(c.default || c))
export const NftRaceProgressSalesCard = () => import('../../components/nftRaceProgress/sales-card/SalesCard.vue' /* webpackChunkName: "components/nft-race-progress-sales-card" */).then(c => wrapFunctional(c.default || c))
export const NftRaceProgressTurnoverModal = () => import('../../components/nftRaceProgress/turnover-modal/TurnoverModal.vue' /* webpackChunkName: "components/nft-race-progress-turnover-modal" */).then(c => wrapFunctional(c.default || c))
export const NftRaceProgressTurnover50RuleModal = () => import('../../components/nftRaceProgress/turnover50RuleModal/Turnover50RuleModal.vue' /* webpackChunkName: "components/nft-race-progress-turnover50-rule-modal" */).then(c => wrapFunctional(c.default || c))
export const NftRaceProgressYourNextBadge = () => import('../../components/nftRaceProgress/yourNextBage/YourNextBadge.vue' /* webpackChunkName: "components/nft-race-progress-your-next-badge" */).then(c => wrapFunctional(c.default || c))
export const PoopStatisticsOwnDepositPoolStatistic = () => import('../../components/poopStatistics/ownDepositPoolStatistic/OwnDepositPoolStatistic.vue' /* webpackChunkName: "components/poop-statistics-own-deposit-pool-statistic" */).then(c => wrapFunctional(c.default || c))
export const PopupsCashftTermsAndConditionsPopup = () => import('../../components/popups/cashft-terms-and-conditions-popup/CashftTermsAndConditionsPopup.vue' /* webpackChunkName: "components/popups-cashft-terms-and-conditions-popup" */).then(c => wrapFunctional(c.default || c))
export const PopupsMysteryBoxPurchasePopup = () => import('../../components/popups/mystery-box-purchase-popup/MysteryBoxPurchasePopup.vue' /* webpackChunkName: "components/popups-mystery-box-purchase-popup" */).then(c => wrapFunctional(c.default || c))
export const PopupsMysteryBoxOptionsPopup = () => import('../../components/popups/mysteryBoxOptions/MysteryBoxOptionsPopup.vue' /* webpackChunkName: "components/popups-mystery-box-options-popup" */).then(c => wrapFunctional(c.default || c))
export const PopupsPurchasePopup = () => import('../../components/popups/purchase-popup/PurchasePopup.vue' /* webpackChunkName: "components/popups-purchase-popup" */).then(c => wrapFunctional(c.default || c))
export const PopupsUtilityTopupPopup = () => import('../../components/popups/utility-topup-popup/UtilityTopupPopup.vue' /* webpackChunkName: "components/popups-utility-topup-popup" */).then(c => wrapFunctional(c.default || c))
export const PopupsWeb2MetamaskModalsAllowanceModal = () => import('../../components/popups/web2MetamaskModals/AllowanceModal.vue' /* webpackChunkName: "components/popups-web2-metamask-modals-allowance-modal" */).then(c => wrapFunctional(c.default || c))
export const PopupsWeb2MetamaskModalsClaimModal = () => import('../../components/popups/web2MetamaskModals/ClaimModal.vue' /* webpackChunkName: "components/popups-web2-metamask-modals-claim-modal" */).then(c => wrapFunctional(c.default || c))
export const StreamlineBonusNewRegistrations = () => import('../../components/streamlineBonus/newRegistrations/NewRegistrations.vue' /* webpackChunkName: "components/streamline-bonus-new-registrations" */).then(c => wrapFunctional(c.default || c))
export const StreamlineBonusNewRegistrationsCashFT = () => import('../../components/streamlineBonus/newRegistrationsCashFT/NewRegistrationsCashFT.vue' /* webpackChunkName: "components/streamline-bonus-new-registrations-cash-f-t" */).then(c => wrapFunctional(c.default || c))
export const StreamlineBonusOwnInvest = () => import('../../components/streamlineBonus/ownInvest/OwnInvest.vue' /* webpackChunkName: "components/streamline-bonus-own-invest" */).then(c => wrapFunctional(c.default || c))
export const StreamlineBonusPositionCard = () => import('../../components/streamlineBonus/positionCard/PositionCard.vue' /* webpackChunkName: "components/streamline-bonus-position-card" */).then(c => wrapFunctional(c.default || c))
export const StreamlineBonusRewardsSection = () => import('../../components/streamlineBonus/rewardsSection/RewardsSection.vue' /* webpackChunkName: "components/streamline-bonus-rewards-section" */).then(c => wrapFunctional(c.default || c))
export const StreamlineBonusSharesCard = () => import('../../components/streamlineBonus/sharesCard/SharesCard.vue' /* webpackChunkName: "components/streamline-bonus-shares-card" */).then(c => wrapFunctional(c.default || c))
export const StreamlineBonusStandardBundleCard = () => import('../../components/streamlineBonus/standardBundleCard/StandardBundleCard.vue' /* webpackChunkName: "components/streamline-bonus-standard-bundle-card" */).then(c => wrapFunctional(c.default || c))
export const StreamlineTurnover = () => import('../../components/streamlineBonus/streamlineTurnover/StreamlineTurnover.vue' /* webpackChunkName: "components/streamline-turnover" */).then(c => wrapFunctional(c.default || c))
export const StreamlineBonusSuperBundleCard = () => import('../../components/streamlineBonus/superBundleCard/SuperBundleCard.vue' /* webpackChunkName: "components/streamline-bonus-super-bundle-card" */).then(c => wrapFunctional(c.default || c))
export const StreamlineBonusTransactionHistoryTable = () => import('../../components/streamlineBonus/transaction-history-table/TransactionHistoryTable.vue' /* webpackChunkName: "components/streamline-bonus-transaction-history-table" */).then(c => wrapFunctional(c.default || c))
export const TurnoverPoolStatisticsChart = () => import('../../components/turnoverPool/poolStatistics/PoolStatisticsChart.vue' /* webpackChunkName: "components/turnover-pool-statistics-chart" */).then(c => wrapFunctional(c.default || c))
export const TurnoverPoolAccordion = () => import('../../components/turnoverPool/poolsAccordion/PoolAccordion.vue' /* webpackChunkName: "components/turnover-pool-accordion" */).then(c => wrapFunctional(c.default || c))
export const TurnoverPoolTaxPoolOne = () => import('../../components/turnoverPool/taxPoolsOneMonth/TaxPoolOne.vue' /* webpackChunkName: "components/turnover-pool-tax-pool-one" */).then(c => wrapFunctional(c.default || c))
export const TurnoverPoolTaxPoolSix = () => import('../../components/turnoverPool/taxPoolsSixMonth/TaxPoolSix.vue' /* webpackChunkName: "components/turnover-pool-tax-pool-six" */).then(c => wrapFunctional(c.default || c))
export const TurnoverTab = () => import('../../components/turnoverPool/turnoverPoolsTab/TurnoverTab.vue' /* webpackChunkName: "components/turnover-tab" */).then(c => wrapFunctional(c.default || c))
export const TurnoverProgress = () => import('../../components/turnoverPool/turnoverProgress/TurnoverProgress.vue' /* webpackChunkName: "components/turnover-progress" */).then(c => wrapFunctional(c.default || c))
export const TurnoverProgressMonthly = () => import('../../components/turnoverPool/turnoverProgressMonthly/TurnoverProgressMonthly.vue' /* webpackChunkName: "components/turnover-progress-monthly" */).then(c => wrapFunctional(c.default || c))
export const TurnoverProgressSixMonth = () => import('../../components/turnoverPool/turnoverProgressSixMonth/TurnoverProgressSixMonth.vue' /* webpackChunkName: "components/turnover-progress-six-month" */).then(c => wrapFunctional(c.default || c))
export const TurnoverRuleModal = () => import('../../components/turnoverPool/turnoverRuleModal/TurnoverRuleModal.vue' /* webpackChunkName: "components/turnover-rule-modal" */).then(c => wrapFunctional(c.default || c))
export const UtilsButtonsGYMButton = () => import('../../components/utils/buttons/GYMButton.vue' /* webpackChunkName: "components/utils-buttons-g-y-m-button" */).then(c => wrapFunctional(c.default || c))
export const UtilsButtonsRoundButton = () => import('../../components/utils/buttons/RoundButton.vue' /* webpackChunkName: "components/utils-buttons-round-button" */).then(c => wrapFunctional(c.default || c))
export const UtilsButtonsSupportButton = () => import('../../components/utils/buttons/SupportButton.vue' /* webpackChunkName: "components/utils-buttons-support-button" */).then(c => wrapFunctional(c.default || c))
export const UtilsCardsGalleryImageCard = () => import('../../components/utils/cards/GalleryImageCard.vue' /* webpackChunkName: "components/utils-cards-gallery-image-card" */).then(c => wrapFunctional(c.default || c))
export const UtilsCardsInfoCard = () => import('../../components/utils/cards/InfoCard.vue' /* webpackChunkName: "components/utils-cards-info-card" */).then(c => wrapFunctional(c.default || c))
export const UtilsCardsMoreItemsCard = () => import('../../components/utils/cards/MoreItemsCard.vue' /* webpackChunkName: "components/utils-cards-more-items-card" */).then(c => wrapFunctional(c.default || c))
export const UtilsCardsProductCard = () => import('../../components/utils/cards/ProductCard.vue' /* webpackChunkName: "components/utils-cards-product-card" */).then(c => wrapFunctional(c.default || c))
export const UtilsCardsRewardCard = () => import('../../components/utils/cards/RewardCard.vue' /* webpackChunkName: "components/utils-cards-reward-card" */).then(c => wrapFunctional(c.default || c))
export const UtilsCardsWalletCard = () => import('../../components/utils/cards/WalletCard.vue' /* webpackChunkName: "components/utils-cards-wallet-card" */).then(c => wrapFunctional(c.default || c))
export const UtilsChartsWidget1 = () => import('../../components/utils/charts/Widget1.vue' /* webpackChunkName: "components/utils-charts-widget1" */).then(c => wrapFunctional(c.default || c))
export const UtilsCurrencyPercent = () => import('../../components/utils/currencyPercent/CurrencyPercent.vue' /* webpackChunkName: "components/utils-currency-percent" */).then(c => wrapFunctional(c.default || c))
export const UtilsCustomSelect = () => import('../../components/utils/customSelect/CustomSelect.vue' /* webpackChunkName: "components/utils-custom-select" */).then(c => wrapFunctional(c.default || c))
export const UtilsFormComponentsGymNewButton = () => import('../../components/utils/form-components/GymNewButton.vue' /* webpackChunkName: "components/utils-form-components-gym-new-button" */).then(c => wrapFunctional(c.default || c))
export const UtilsLoaderFullScreenLoader = () => import('../../components/utils/loader/FullScreenLoader.vue' /* webpackChunkName: "components/utils-loader-full-screen-loader" */).then(c => wrapFunctional(c.default || c))
export const UtilsLoaderGYMLoader = () => import('../../components/utils/loader/GYMLoader.vue' /* webpackChunkName: "components/utils-loader-g-y-m-loader" */).then(c => wrapFunctional(c.default || c))
export const UtilsLoader = () => import('../../components/utils/loader/Loader.vue' /* webpackChunkName: "components/utils-loader" */).then(c => wrapFunctional(c.default || c))
export const UtilsLocaleSwitcher = () => import('../../components/utils/localeSwitcher/LocaleSwitcher.vue' /* webpackChunkName: "components/utils-locale-switcher" */).then(c => wrapFunctional(c.default || c))
export const UtilsModalsCustomModal = () => import('../../components/utils/modals/CustomModal.vue' /* webpackChunkName: "components/utils-modals-custom-modal" */).then(c => wrapFunctional(c.default || c))
export const UtilsSteppersGuidlineStepper = () => import('../../components/utils/steppers/GuidlineStepper.vue' /* webpackChunkName: "components/utils-steppers-guidline-stepper" */).then(c => wrapFunctional(c.default || c))
export const UtilsTooltip = () => import('../../components/utils/tooltip/Tooltip.vue' /* webpackChunkName: "components/utils-tooltip" */).then(c => wrapFunctional(c.default || c))
export const NftRaceProgressTurnoverModalDirectSalesCard = () => import('../../components/nftRaceProgress/turnover-modal/direct-sales-card/DirectSalesCard.vue' /* webpackChunkName: "components/nft-race-progress-turnover-modal-direct-sales-card" */).then(c => wrapFunctional(c.default || c))
export const NftRaceProgressTurnoverModalTeamSalesCard = () => import('../../components/nftRaceProgress/turnover-modal/team-sales-card/TeamSalesCard.vue' /* webpackChunkName: "components/nft-race-progress-turnover-modal-team-sales-card" */).then(c => wrapFunctional(c.default || c))
export const TurnoverPoolTransactionHistoryMonthlyTaxPool = () => import('../../components/turnoverPool/transactionHistory/transactionHistoryMonthlyTaxPool/TransactionHistoryMonthlyTaxPool.vue' /* webpackChunkName: "components/turnover-pool-transaction-history-monthly-tax-pool" */).then(c => wrapFunctional(c.default || c))
export const TurnoverPoolTransactionHistoryTurnoverPool = () => import('../../components/turnoverPool/transactionHistory/transactionHistoryTurnoverPool/TransactionHistoryTurnoverPool.vue' /* webpackChunkName: "components/turnover-pool-transaction-history-turnover-pool" */).then(c => wrapFunctional(c.default || c))
export const TurnoverPoolTurnoverPoolsTabMonthlyTaxPoolTotalAccordion = () => import('../../components/turnoverPool/turnoverPoolsTab/monthlyTaxPoolTotalAccordion/MonthlyTaxPoolTotalAccordion.vue' /* webpackChunkName: "components/turnover-pool-turnover-pools-tab-monthly-tax-pool-total-accordion" */).then(c => wrapFunctional(c.default || c))
export const TurnoverTotalAccordion = () => import('../../components/turnoverPool/turnoverPoolsTab/turnoverTotalAccordion/TurnoverTotalAccordion.vue' /* webpackChunkName: "components/turnover-total-accordion" */).then(c => wrapFunctional(c.default || c))
export const UtilsBadgesRankBadge = () => import('../../components/utils/badges/rank-badge/RankBadge.vue' /* webpackChunkName: "components/utils-badges-rank-badge" */).then(c => wrapFunctional(c.default || c))
export const UtilsBadgesStatusBadge = () => import('../../components/utils/badges/status-badge/StatusBadge.vue' /* webpackChunkName: "components/utils-badges-status-badge" */).then(c => wrapFunctional(c.default || c))
export const UtilsButtonsSocialMediaButton = () => import('../../components/utils/buttons/SocialMediaButton/SocialMediaButton.vue' /* webpackChunkName: "components/utils-buttons-social-media-button" */).then(c => wrapFunctional(c.default || c))
export const UtilsCardsAccountInfoCard = () => import('../../components/utils/cards/account-info-card/AccountInfoCard.vue' /* webpackChunkName: "components/utils-cards-account-info-card" */).then(c => wrapFunctional(c.default || c))
export const UtilsCardsBlurCard = () => import('../../components/utils/cards/blur-card/BlurCard.vue' /* webpackChunkName: "components/utils-cards-blur-card" */).then(c => wrapFunctional(c.default || c))
export const UtilsCardsCardWithIcon = () => import('../../components/utils/cards/cardWithIcon/CardWithIcon.vue' /* webpackChunkName: "components/utils-cards-card-with-icon" */).then(c => wrapFunctional(c.default || c))
export const UtilsCardsCardWithSelect = () => import('../../components/utils/cards/cardWithSelect/CardWithSelect.vue' /* webpackChunkName: "components/utils-cards-card-with-select" */).then(c => wrapFunctional(c.default || c))
export const UtilsCardsCountdownCard = () => import('../../components/utils/cards/countdown-card/CountdownCard.vue' /* webpackChunkName: "components/utils-cards-countdown-card" */).then(c => wrapFunctional(c.default || c))
export const UtilsCardsImageQuantityCard = () => import('../../components/utils/cards/image-quantity-card/ImageQuantityCard.vue' /* webpackChunkName: "components/utils-cards-image-quantity-card" */).then(c => wrapFunctional(c.default || c))
export const UtilsCardsImportantInfoCard = () => import('../../components/utils/cards/important-info-card/ImportantInfoCard.vue' /* webpackChunkName: "components/utils-cards-important-info-card" */).then(c => wrapFunctional(c.default || c))
export const UtilsCardsInfoAlertCard = () => import('../../components/utils/cards/info-alert-card/InfoAlertCard.vue' /* webpackChunkName: "components/utils-cards-info-alert-card" */).then(c => wrapFunctional(c.default || c))
export const UtilsCardsMainCard = () => import('../../components/utils/cards/mainCard/Card.vue' /* webpackChunkName: "components/utils-cards-main-card" */).then(c => wrapFunctional(c.default || c))
export const UtilsCardsNeededPercentCard = () => import('../../components/utils/cards/needed-percent-card/NeededPercentCard.vue' /* webpackChunkName: "components/utils-cards-needed-percent-card" */).then(c => wrapFunctional(c.default || c))
export const UtilsCardsPendingGymnetRewards = () => import('../../components/utils/cards/pendingGymnetRewardsCard/PendingGymnetRewards.vue' /* webpackChunkName: "components/utils-cards-pending-gymnet-rewards" */).then(c => wrapFunctional(c.default || c))
export const UtilsCardsSpecialOffersStandardBundleCard = () => import('../../components/utils/cards/specialOffers/StandardBundleCard.vue' /* webpackChunkName: "components/utils-cards-special-offers-standard-bundle-card" */).then(c => wrapFunctional(c.default || c))
export const UtilsCardsSpecialOffersSuperBundleCard = () => import('../../components/utils/cards/specialOffers/SuperBundleCard.vue' /* webpackChunkName: "components/utils-cards-special-offers-super-bundle-card" */).then(c => wrapFunctional(c.default || c))
export const UtilsCardsTurnoverAmountCard = () => import('../../components/utils/cards/turnover-amount-card/TurnoverAmountCard.vue' /* webpackChunkName: "components/utils-cards-turnover-amount-card" */).then(c => wrapFunctional(c.default || c))
export const UtilsCarouselsPromotionCarousel = () => import('../../components/utils/carousels/PromotionCarousel/PromotionCarousel.vue' /* webpackChunkName: "components/utils-carousels-promotion-carousel" */).then(c => wrapFunctional(c.default || c))
export const UtilsIndicatorsStepIndicator = () => import('../../components/utils/indicators/step-indicator/StepIndicator.vue' /* webpackChunkName: "components/utils-indicators-step-indicator" */).then(c => wrapFunctional(c.default || c))
export const UtilsInputsGymnetAndUsdInputSwitcher = () => import('../../components/utils/inputs/gymnetAndUsdSwitcher/GymnetAndUsdInputSwitcher.vue' /* webpackChunkName: "components/utils-inputs-gymnet-and-usd-input-switcher" */).then(c => wrapFunctional(c.default || c))
export const UtilsInputsSwitchInput = () => import('../../components/utils/inputs/switchInput/SwitchInput.vue' /* webpackChunkName: "components/utils-inputs-switch-input" */).then(c => wrapFunctional(c.default || c))
export const UtilsLoaderBlurLoader = () => import('../../components/utils/loader/blur-loader/BlurLoader.vue' /* webpackChunkName: "components/utils-loader-blur-loader" */).then(c => wrapFunctional(c.default || c))
export const UtilsModalsTermsAndConditions = () => import('../../components/utils/modals/termsAndCondition/TermsAndConditions.vue' /* webpackChunkName: "components/utils-modals-terms-and-conditions" */).then(c => wrapFunctional(c.default || c))
export const UtilsPaginationTablePagination = () => import('../../components/utils/pagination/table-pagination/TablePagination.vue' /* webpackChunkName: "components/utils-pagination-table-pagination" */).then(c => wrapFunctional(c.default || c))
export const UtilsProgressBarSegmentedColoredProgressBar = () => import('../../components/utils/progress-bar/segmented-colored-progress-bar/SegmentedColoredProgressBar.vue' /* webpackChunkName: "components/utils-progress-bar-segmented-colored-progress-bar" */).then(c => wrapFunctional(c.default || c))
export const UtilsProgressBarSimpleColoredProgressBar = () => import('../../components/utils/progress-bar/simple-colored-progress-bar/SimpleColoredProgressBar.vue' /* webpackChunkName: "components/utils-progress-bar-simple-colored-progress-bar" */).then(c => wrapFunctional(c.default || c))
export const UtilsSteppersVideoPhotoSwitch = () => import('../../components/utils/steppers/VideoPhotoSwitch/VideoPhotoSwitch.vue' /* webpackChunkName: "components/utils-steppers-video-photo-switch" */).then(c => wrapFunctional(c.default || c))
export const UtilsTabsGymTabs = () => import('../../components/utils/tabs/gym-tabs/GymTabs.vue' /* webpackChunkName: "components/utils-tabs-gym-tabs" */).then(c => wrapFunctional(c.default || c))
export const UtilsTablesListTable = () => import('../../components/utils/tables/list-table/ListTable.vue' /* webpackChunkName: "components/utils-tables-list-table" */).then(c => wrapFunctional(c.default || c))
export const NftRaceProgressTurnoverModalTeamSalesTable = () => import('../../components/nftRaceProgress/turnover-modal/team-sales-card/team-sales-table/TeamSalesTable.vue' /* webpackChunkName: "components/nft-race-progress-turnover-modal-team-sales-table" */).then(c => wrapFunctional(c.default || c))
export const UtilsTablesListTableHeader = () => import('../../components/utils/tables/list-table/list-table-header/ListTableHeader.vue' /* webpackChunkName: "components/utils-tables-list-table-header" */).then(c => wrapFunctional(c.default || c))
export const UtilsTablesListTableRow = () => import('../../components/utils/tables/list-table/list-table-row/ListTableRow.vue' /* webpackChunkName: "components/utils-tables-list-table-row" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
