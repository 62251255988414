import ApiService from "~/core/services/api-interaction/ApiService";
import { fromWei, getClientRuntimeConfig } from "~/core/helpers/GlobalHelpers";
import { MetaWorldManager } from "~/core/services/map/MetaWorldManager";
import ThirdPartyApiService from "~/core/services/api-interaction/ThirdPartyApiService";
import {DataService} from "~/core/services/DataService";
import {FILECOIN_CLAIM_IN_PROCESS} from "~/pages/finances/miner-rewards/constants/ClaimStorageConstants";

const $config = getClientRuntimeConfig();
const BitopexServer = $config.bitopex;
const FileCoinServer = $config.filecoin;

export const state = () => {
    return {
        // Available Balance -
        availableBalanceHistory: {
            loading: false,
            list: [],
            totalCount: 0,
            serverError: false,
        },
        // Utility -
        utilityBalanceHistory: {
            loading: false,
            list: [],
            totalCount: 0,
            serverError: false,
        },
        // Bitopex -
        bitopexStatistics: null,
        bitopexServerError: false,
        bitopexPendingRewards: 0,
        bitopexDualMiners: null,
        bitopexBalance: 0,
        bitopexHistory: {
            loading: false,
            list: [],
            totalCount: 0,
            serverError: false,
        },
        // FileCoin
        filecoinStatistics: null,
        filecoinServerError: false,
        filecoinClaimedInProcess: false,
        filecoinDualMiners: null,
        filecoinPendingRewards: 0,
        filecoinHistory: {
            loading: true,
            list: [],
            totalCount: 0,
            serverError: false,
        },
    };
};

// Actions
export const actions = {
    // Available Balance -
    async getAvailableBalanceHistory({ commit }, { walletAddress, page, pageSize }) {
        commit('SET_AVAILABLE_BALANCE_LOADING', true);
        commit('SET_AVAILABLE_BALANCE_ERROR', false);
        try {
            const result = await ThirdPartyApiService.get(`${$config.gymnetApi}/api/get-mining-dual-minting-rewards`, {
                params: {
                    address: walletAddress,
                    page,
                    limit: pageSize,
                },
            });

            commit('SET_AVAILABLE_BALANCE_DATA', {
                list: result.data.list?.items,
                totalCount: result.data.list?.meta?.totalItems,
            });
        } catch {
            commit('SET_AVAILABLE_BALANCE_ERROR', true);
        } finally {
            commit('SET_AVAILABLE_BALANCE_LOADING', false);
        }
    },
    // Utility -
    async getUtilityBalanceHistory({ commit }, { walletAddress, page, pageSize }) {
        commit('SET_UTILITY_HISTORY_LOADING', true);
        commit('SET_UTILITY_HISTORY_ERROR', false);

        try {
            const result = await ApiService.query(`claim/utility-rewards`, {
                params: { walletAddress, page, perPage: pageSize },
            });

            commit('SET_UTILITY_HISTORY_DATA', {
                list: result.data.data,
                totalCount: parseFloat(result.data.totalCount),
            });
        } catch {
            commit('SET_UTILITY_HISTORY_ERROR', true);
        } finally {
            commit('SET_UTILITY_HISTORY_LOADING', false);
        }
    },
    // Bitopex -
    async getBitopexStatistic({ commit }, { walletAddress, bitopexDay = 5, bitopexHour = 16 }) {
        if (!walletAddress) return;
        commit('SET_BITOPEX_SERVER_ERROR', false);

        try {
            const result = await ApiService.post(`${BitopexServer}/api/v1/user`, { walletAddress })

            if (typeof result.data === 'object' && Object.keys(result.data).length) {
                const nextRewardDate = getNextRewardDate(bitopexDay, bitopexHour);
                commit('SET_BITOPEX_STATISTIC', {
                    totalReceivedBUSD: result.data.TotalClaimedBUSD * 2,
                    totalReceivedBTX: result.data.TotalClaimedBTX,
                    totalRewardsExpected: result.data.balanceBUSD * 2,
                    nextRewardsDistributionDate: nextRewardDate,
                    payments: result.data.payments,
                });
            } else if (typeof result.data === 'boolean' && result.data === false) {
                commit('SET_BITOPEX_STATISTIC', null);
            }
        } catch {
            commit('SET_BITOPEX_SERVER_ERROR', true);
        }
    },
    async getBitopexPendingRewards({ commit }, { walletAddress }) {
        if (!walletAddress) {
            return;
        }
        try {
            const response = await ApiService.query(`${BitopexServer}/api/v1/pending-rewards`, {
                params: { walletAddress }
            });
            commit('SET_BITOPEX_PENDING_REWARDS', response?.data?.pendingRewards);
        } catch(e) {
            if(e.httpErrorCode !== 404) {
                console.error("Unknown error at Bitopex store getPendingRewards", e);
            }
        }
    },
    async getBitopexDualMiners({ commit }, { walletAddress }) {
        if (!walletAddress) {
            return;
        }
        const result = {
            dualMinersActive: 0,
            dualMinersInactive: 0,
            dualMinersTotal: 0,
        };
        try {
            const response = await ApiService.post(`${BitopexServer}/api/v1/user`, { walletAddress });

            if (typeof response.data === 'object' && Object.keys(response.data).length) {
                const payments = response.data.payments;
                result.dualMinersInactive = payments.reduce((acc, payment) => acc + payment.activeMiners, 0);
                result.dualMinersTotal = payments.reduce((acc, payment) => acc + payment.minersN, 0);
                result.dualMinersActive = result.dualMinersTotal - result.dualMinersInactive;
            }
        } catch(e) {
            console.error("Unknown error at Bitopex store getDualMiners", e);
        }
        commit('SET_BITOPEX_DUAL_MINERS', result);
        return result;
    },
    async getBitopexBalance({ commit }, { walletAddress }) {
        const contract = MetaWorldManager.sharedInstance().contracts.BitopexToken;
        const balance = await contract.methods.balanceOf(walletAddress).call();
        commit('SET_BITOPEX_BALANCE', fromWei(balance));
        return fromWei(balance);
    },
    async getBitopexHistory({ commit }, { walletAddress, page, pageSize }) {
        if (!walletAddress) {
            return;
        }
        commit('SET_BITOPEX_HISTORY_LOADING', true);
        commit('SET_BITOPEX_HISTORY_ERROR', false);

        try {
            const result = await ApiService.query(`${BitopexServer}/api/v1/history`, {
                params: { walletAddress, page, perPage: pageSize }
            });
            if (!result.data || typeof result.data.massage === 'string') {
                commit('SET_BITOPEX_HISTORY_LOADING', false);
                return;
            }

            const list = result.data.data.map((tx) => {
                return {
                    balanceBefore: tx.amountBTX_before,
                    balanceAfter: tx.amountBTX_after,
                    amount: tx.amountBTX_after - tx.amountBTX_before,
                    date: tx.created_at,
                    token: 'BTX',
                    txId: tx.txHash,
                };
            });

            commit('SET_BITOPEX_HISTORY_DATA', {
                list,
                totalCount: result.data.totalCount
            });
        } catch {
            commit('SET_BITOPEX_HISTORY_ERROR', true);
        } finally {
            commit('SET_BITOPEX_HISTORY_LOADING', false);
        }
    },
    // Filecoin
    async getFilecoinStatistics({ commit }, { walletAddress, filecoinDay = 1, filecoinHour = 16 }) {
        if (!walletAddress) {
            return;
        }

        commit('SET_FILECOIN_SERVER_ERROR', false);
        try {
            const result = await ApiService.post(`${FileCoinServer}/api/v1/user`, { walletAddress });

            if (typeof result.data === 'object' && Object.keys(result.data).length) {
                const resultClaimable = await ApiService.get(`${FileCoinServer}/api/v1/claimable`);
                const claimableAmount = resultClaimable?.data?.claimable || 0;

                if (!claimableAmount) {
                    commit('SET_FILECOIN_CLAIMED_IN_PROCESS', false);
                    DataService.removeFromLocalStorage(FILECOIN_CLAIM_IN_PROCESS);
                }

                const nextRewardDate = getNextRewardDate(filecoinDay, filecoinHour);

                commit('SET_FILECOIN_STATISTIC', {
                    totalReceivedBUSD: result.data?.user?.total_claimed,
                    totalReceivedFilecoin: result.data?.user?.total_claimed_token,
                    totalRewardsExpected: result.data?.user?.active_balance,
                    nextRewardsDistributionDate: nextRewardDate,
                    claimableAmount: resultClaimable?.data?.claimable,
                    payments: result.data?.user?.payments,
                });
            } else if (typeof result.data === 'boolean' && result.data === false) {
                commit('SET_FILECOIN_STATISTIC', null);
            }
        } catch(e) {
            commit('SET_FILECOIN_SERVER_ERROR', true);
        }
    },
    async getFilecoinDualMiners({ commit }, { walletAddress }) {
        if (!walletAddress) return;

        const result = {
            dualMinersActive: 0,
            dualMinersInactive: 0,
            dualMinersTotal: 0,
            dualMinersHashpower: 0,
            dualMinersHashpowerForAllMetaverses: 0,
        };
        try {
            const response = await ApiService.post(`${FileCoinServer}/api/v1/user`, { walletAddress });
            if (typeof response.data === 'object' && Object.keys(response.data).length) {
                const payments = response.data?.user?.payments || [];
                const metaverseId = this.$metaverseId();
                result.dualMinersHashpowerForAllMetaverses = Object.values(response.data?.totalDualMiners).reduce((acc, hashpower) => acc + hashpower, 0);
                result.dualMinersHashpower = response.data?.totalDualMiners[metaverseId];
                result.dualMinersInactive = payments.reduce((acc, payment) => payment.metaverse_id === metaverseId ? acc + payment.active_miners : acc, 0);
                result.dualMinersTotal = payments.reduce((acc, payment) => payment.metaverse_id === metaverseId ? acc + payment.miners_number : acc, 0);
                result.dualMinersActive = result.dualMinersTotal - result.dualMinersInactive;
                result.inactiveDualMinersForAllMetaverses = payments.reduce((acc, payment) => acc + payment.active_miners, 0);
                result.totalDualMinersForAllMetaverses = payments.reduce((acc, payment) => acc + payment.miners_number, 0);
                result.activeDualMinersForAllMetaverses = result.totalDualMinersForAllMetaverses - result.inactiveDualMinersForAllMetaverses;
            }
        } catch(e) {
            console.error("Unknown error at FileCoinService.getDualMiners", e);
        }
        commit('SET_FILECOIN_DUAL_MINERS', result);
        return result;
    },
    async claimFilecoinPendingRewards() {
        const result = await ApiService
            .get(`${FileCoinServer}/api/v1/claim-rewards`)
            .catch(() => {
                return {data: ''};
            });
        return result?.data?.message;
    },
    async getFilecoinHistory({ commit }, { walletAddress, page, pageSize }) {
        if (!walletAddress) return;

        commit('SET_FILECOIN_HISTORY_LOADING', true);
        commit('SET_FILECOIN_HISTORY_ERROR', false);

        try {
            const result = await ApiService.query(`${FileCoinServer}/api/v1/history`, {
                params: {
                    walletAddress, page,
                    perPage: pageSize
                }
            });
            if (!result.data || typeof result.data.massage === 'string') {
                commit('SET_FILECOIN_HISTORY_LOADING', false);
                return;
            }
            const list = result.data.data.map((tx) => {
                return {
                    balanceBefore: tx.amount_before,
                    balanceAfter: tx.amount_after,
                    amount: tx.amount_after - tx.amount_before,
                    date: tx.created_at,
                    token: 'FIL',
                    txId: tx.tx_hash,
                };
            });

            commit('SET_FILECOIN_HISTORY_DATA', {
                list,
                totalCount: result.data.totalCount
            });
        } catch {
            commit('SET_FILECOIN_HISTORY_ERROR', true);
        } finally {
            commit('SET_FILECOIN_HISTORY_LOADING', false);
        }
    },
    async getFilecoinPendingRewards({ commit }, { walletAddress }) {
        if (!walletAddress) return;

        try {
            const response = await ApiService.query(`${FileCoinServer}/api/v1/pending-rewards`, {
                params: { walletAddress }
            });
            commit('SET_FILECOIN_PENDING_REWARDS', response?.data?.pendingRewards);
        } catch(e) {
            if(e.httpErrorCode !== 404) {
                console.error("Unknown error at FileCoinService.getPendingRewards", e);
            }
        }
    },
};

// Mutations
export const mutations = {
    // Available Balance -
    SET_AVAILABLE_BALANCE_LOADING(state, payload) {
        state.availableBalanceHistory.loading = payload;
    },
    SET_AVAILABLE_BALANCE_ERROR(state, payload) {
        state.availableBalanceHistory.serverError = payload;
    },
    SET_AVAILABLE_BALANCE_DATA(state, { list = [], totalCount = 0 }) {
        state.availableBalanceHistory.list = list;
        state.availableBalanceHistory.totalCount = totalCount;
    },
    // Utility -
    SET_UTILITY_HISTORY_LOADING(state, payload) {
        state.utilityBalanceHistory.loading = payload;
    },
    SET_UTILITY_HISTORY_ERROR(state, payload) {
        state.utilityBalanceHistory.serverError = payload;
    },
    SET_UTILITY_HISTORY_DATA(state, { list = [], totalCount = 0 }) {
        state.utilityBalanceHistory.list = list;
        state.utilityBalanceHistory.totalCount = totalCount;
    },
    // Bitopex -
    SET_BITOPEX_STATISTIC(state, payload) {
        state.bitopexStatistics = payload;
    },
    SET_BITOPEX_SERVER_ERROR(state, payload) {
        state.bitopexServerError = payload;
    },
    SET_BITOPEX_PENDING_REWARDS(state, payload = 0) {
        state.bitopexPendingRewards = payload;
    },
    SET_BITOPEX_DUAL_MINERS(state, payload) {
        state.bitopexDualMiners = payload;
    },
    SET_BITOPEX_BALANCE(state, payload) {
        state.bitopexBalance = payload;
    },
    SET_BITOPEX_HISTORY_LOADING(state, payload) {
        state.bitopexHistory.loading = payload;
    },
    SET_BITOPEX_HISTORY_ERROR(state, payload) {
        state.bitopexHistory.serverError = payload;
    },
    SET_BITOPEX_HISTORY_DATA(state, { list = [], totalCount = 0 }) {
        state.bitopexHistory.list = list;
        state.bitopexHistory.totalCount = totalCount;
    },
    // Filecoin
    SET_FILECOIN_STATISTIC(state, payload) {
        state.filecoinStatistics = payload;
    },
    SET_FILECOIN_SERVER_ERROR(state, payload) {
        state.filecoinServerError = payload;
    },
    SET_FILECOIN_CLAIMED_IN_PROCESS(state, payload) {
        state.filecoinClaimedInProcess = payload;
    },
    SET_FILECOIN_DUAL_MINERS(state, payload) {
        state.filecoinDualMiners = payload;
    },
    SET_FILECOIN_HISTORY_LOADING(state, payload) {
        state.filecoinHistory.loading = payload;
    },
    SET_FILECOIN_HISTORY_ERROR(state, payload) {
        state.filecoinHistory.serverError = payload;
    },
    SET_FILECOIN_HISTORY_DATA(state, { list = [], totalCount = 0 }) {
        state.filecoinHistory.list = list;
        state.filecoinHistory.totalCount = totalCount;
    },
    SET_FILECOIN_PENDING_REWARDS(state, payload) {
        state.filecoinPendingRewards = payload;
    }
};

// Getters
export const getters = {
    // Available Balance -
    availableBalanceHistory: ({ availableBalanceHistory }) => availableBalanceHistory,
    // Utility
    utilityBalanceHistory: ({ utilityBalanceHistory }) => utilityBalanceHistory,
    // Bitopex -
    bitopexStatistics: ({ bitopexStatistics }) => bitopexStatistics,
    bitopexServerError: ({ bitopexServerError }) => bitopexServerError,
    bitopexPendingRewards: ({ bitopexPendingRewards }) => bitopexPendingRewards,
    bitopexDualMiners: ({ bitopexDualMiners }) => bitopexDualMiners,
    bitopexHistory: ({ bitopexHistory }) => bitopexHistory,
    // Filecoin
    filecoinStatistics: ({ filecoinStatistics }) => filecoinStatistics,
    filecoinServerError: ({ filecoinServerError }) => filecoinServerError,
    filecoinClaimedInProcess: ({ filecoinClaimedInProcess }) => filecoinClaimedInProcess,
    filecoinDualMiners: ({ filecoinDualMiners }) => filecoinDualMiners,
    filecoinHistory: ({ filecoinHistory }) => filecoinHistory,
    filecoinPendingRewards: ({ filecoinPendingRewards }) => filecoinPendingRewards,
};

function dateToEpoch (time) {
    return time - (time % 86400000);
}

function getNextRewardDate(dayOfWeek, hourOfDay) {
    const dateCopy    = new Date(dateToEpoch(Date.now()));
    const currentTime = new Date(Date.now());
    dateCopy.setHours(hourOfDay);
    const roundDay = dateCopy.getHours() > currentTime.getHours() ? 0 : 7;
    return new Date(
      dateCopy.setDate(
        dateCopy.getDate() + ((7 - dateCopy.getDay() + dayOfWeek) % 7 || roundDay),
      ),
    );
}



