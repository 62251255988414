
  export default {
    props: {
      date: null,
      dayLabel: {
        type: String,
        default: 'D'
      },
      hourLabel: {
        type: String,
        default: 'H'
      },
      minuteLabel: {
        type: String,
        default: 'M'
      },
      secondLabel: {
        type: String,
        default: 'S'
      }
    },
    emist: ['onFinish'],
    data () {
      return {
        now: Math.trunc((new Date()).getTime() / 1000),
        event: this.date,
        finish: false
      }
    },
    mounted () {
      const _self = this
      window.setInterval(() => {
        this.now = Math.trunc((new Date()).getTime() / 1000)
        if (!this.finish && this.calculatedDate - this.now <= 0) {
          _self.finish = true;
          _self.$emit('onFinish')
        }
      }, 1000)
    },
    computed: {
      secondCount () {
        return this.calculatedDate - this.now
      },
      calculatedDate () {
        return Math.trunc(Date.parse(this.event) / 1000)
      },
      seconds () {
        if (this.secondCount < 0) return 0
        return this.secondCount % 60
      },
      minutes () {
        if (this.secondCount < 0) return 0
        return Math.trunc(this.secondCount / 60) % 60
      },
      hours () {
        if (this.secondCount < 0) return 0
        return Math.trunc(this.secondCount / 60 / 60) % 24
      },
      days () {
        if (this.secondCount < 0) return 0
        return Math.trunc(this.secondCount / 60 / 60 / 24)
      }
    },
    filters: {
      twoDigits (value) {
        if (value.toString().length <= 1) {
          return '0' + value.toString()
        }
        return value.toString()
      }
    }
  }
  