import { METAVERSE_IDS, METAVERSES } from "~/core/services/utils/MetaverseConstants";
import { GlobalMakerService } from "~/core/services/GlobalMakerService";
import {RANKS_ORDER} from "~/constants/constants";

export type MainMenuItem = {
    heading: string;
    route: string;
    svgIcon?: string;
    svgIconHover?: string;
    isVisible: () => boolean;
    sub?: MainMenuItem[];
    newItemCount?: number;
    isDisabled?: boolean;
    isNew?: boolean;
};

type MainMenuConfigReturnType = MainMenuItem[];

export const MainMenuConfig = (): MainMenuConfigReturnType => {
    const store = GlobalMakerService.$store;
    const authUser = store.state.application.driver.authUser;
    const metaverseId = store.state.map.mapModule.metaverseId;

    return [
        {
            heading: "Metaverses",
            route: "/metaverse-switch",
            svgIcon: "metaverses",
            svgIconHover: "metaverses-hover",
            isVisible: () => true,
        },
        {
            heading: "Dashboard",
            route: "/dashboard",
            svgIcon: "dashboard",
            svgIconHover: "dashboard-hover",
            isVisible: () => !!authUser
        },
        {
            heading: "Finance",
            route: "/finance",
            svgIcon: "finance",
            svgIconHover: "finance-hover",
            isVisible: () => !!authUser,
            sub: [
                {
                    heading: "My wallet",
                    route: "/finances/wallet",
                    isVisible: () => !!authUser,
                },
                {
                    heading: "Total Income",
                    route: "/finances/total-income",
                    isDisabled: true,
                    isVisible: () => true,
                },
                {
                    heading: "Minting Rewards",
                    route: "/finances/miner-rewards",
                    isVisible: () => !!authUser,
                },
                {
                    heading: "Commissions",
                    route: "/finances/commissions",
                    isVisible: () => !!authUser && authUser?.isReferralLinkActive,
                },
                {
                    heading: "Single pool",
                    route: "/finances/single-pool",
                    isVisible: () => !!authUser,
                },
            ],
        },
        {
            heading: "Products",
            route: "/nft",
            svgIcon: "products",
            svgIconHover: "products-hover",
            newItemCount: !!authUser && metaverseId === METAVERSE_IDS.GYMSTREET ? 1 : 0,
            isVisible: () => true,
            sub: [
                {
                    heading: "Shop",
                    route: "/nfts/products",
                    isVisible: () => true,
                },
                {
                    heading: "My Products",
                    route: "/nfts/my-nfts",
                    isVisible: () => !!authUser,
                },
                {
                    heading: "Cards",
                    route: "/nfts/cards",
                    isNew: true,
                    isVisible: () => !!authUser && metaverseId === METAVERSE_IDS.GYMSTREET,
                },
                {
                    heading: "Marketplace",
                    route: "/marketplace",
                    isDisabled: true,
                    isVisible: () => true,
                },
            ],
        },
        {
            heading: "Promos",
            route: "/promos",
            svgIcon: "promos",
            svgIconHover: "promos-hover",
            isVisible: () => !!authUser && metaverseId === METAVERSE_IDS.GYMSTREET,
            sub: [
                {
                    heading: "Mystery Box results",
                    route: "/promos/mystery-box",
                    isVisible: () => !!authUser,
                },
            ],
        },
        {
            heading: "Map",
            route: "/metaworld",
            svgIcon: "map",
            svgIconHover: "map-hover",
            isVisible: () => true,
            sub: [
                {
                    heading: `${METAVERSES[metaverseId].metaverse_name} Map`,
                    route: "/metaworld/map",
                    isVisible: () => true,
                },
                {
                    heading: "Gallery",
                    route: "/metaworld/gallery",
                    isVisible: () => !!authUser,
                },
            ],
        },
        {
            heading: "Partnerships",
            route: "/partnerships",
            svgIcon: "partnerships",
            svgIconHover: "partnerships-hover",
            isVisible: () => true,
        },
    ]
}

export const PartnerAreaMenuConfig = (): MainMenuConfigReturnType => {
    const store = GlobalMakerService.$store;
    const authUser = store.state.application.driver.authUser;
    const metaverseId = store.state.map.mapModule.metaverseId;

    return [
        {
            heading: "Career status",
            route: "/partner-area/career-status",
            svgIcon: "careers-status",
            svgIconHover: "careers-status-hover",
            isVisible: () => !!authUser
        },
        {
            heading: "My Team",
            route: "/partner-area/my-team",
            svgIcon: "my-team",
            svgIconHover: "my-team-hover",
            isVisible: () => !!authUser
        },
        {
            heading: "Incentives",
            route: "/partner-area/incentives",
            svgIcon: "incentives",
            svgIconHover: "incentives-hover",
            newItemCount: 1,
            isVisible: () => !!authUser,
            sub: [
                {
                    heading: "Global Pool",
                    isNew: true,
                    route: "/partner-area/incentives/global-pool",
                    isVisible: () => !!authUser,
                },
                {
                    heading: "Product Autoship",
                    route: "/partner-area/incentives/product-autoship-incentive",
                    isVisible: () => !!authUser,
                },
                {
                    heading: "Spring",
                    route: "/partner-area/incentives/spring-incentive",
                    isVisible: () => !!authUser,
                },
                {
                    heading: "Travel",
                    route: "/partner-area/incentives/travel-incentive",
                    isVisible: () => !!authUser && metaverseId === METAVERSE_IDS.GYMSTREET,
                },
                {
                    heading: "Turnover",
                    route: "/partner-area/incentives/turnover-incentive",
                    isVisible: () => !!authUser && metaverseId === METAVERSE_IDS.GYMSTREET,
                },
                {
                    heading: "Cash Promo",
                    route: "/partner-area/incentives/cash-promo",
                    isVisible: () => !!authUser,
                },
                {
                    heading: "Fast Start",
                    route: "/partner-area/incentives/fast-start",
                    isVisible: () => !!authUser && authUser.isUsedFastStartIncentive && !authUser.isRegisteredAfterMarch4,
                },
                {
                    heading: "Leadership",
                    route: "/partner-area/incentives/leadership-incentives",
                    isDisabled: true,
                    isVisible: () => !!authUser,
                },
            ],
        },
        {
            heading: "Level pool",
            route: "/partner-area/level-pool",
            svgIcon: "level-pool",
            svgIconHover: "level-pool-hover",
            isVisible: () => !!authUser
        },
        {
            heading: "Turnover pool",
            route: "/partner-area/turnover-pool",
            svgIcon: "turnover-pool",
            svgIconHover: "turnover-pool-hover",
            isVisible: () => !!authUser
        },
        // {
        //     heading: "NFT Income",
        //     route: "/partner-area/NFT-Commissions",
        //     svgIcon: "NFT-Commissions",
        //     svgIconHover: "NFT-Commissions-hover",
        //     isVisible: () => !!authUser
        // },
        // {
        //     heading: "Leadership Incentives",
        //     route: "/partner-area/nft-rank-rewards",
        //     svgIcon: "NFT-Commissions",
        //     svgIconHover: "NFT-Commissions-hover",
        //     isVisible: () => !!authUser
        // },
        {
            heading: "Global Line Bonus",
            route: "/partner-area/global-line-bonus",
            svgIcon: "global-line",
            svgIconHover: "global-line-hover",
            isVisible: () => !!authUser
        },
    ]
}
