
import {mapGetters} from "vuex";
import {MetaInventoryContainer} from "@/core/models/MetaInventoryContainer";
import CustomBoxLoader from "@/components/utils/CustomBoxLoader";
import { toCurrencyFormat } from "@/core/helpers/GlobalHelpers";

export default {
    name: "BalanceSection",
    components: {CustomBoxLoader},
    data() {
        return {
            inventoryContainer: MetaInventoryContainer.sharedInstance(),
            toCurrencyFormat
        }
    },
    computed: {
        ...mapGetters('application/driver', ['authUser']),
        balancesData() {
            return [
                {
                    icon: require('~/assets/images/wallets/usdt.png'),
                    iconMobile: require('~/assets/images/wallets/usdt.png'),
                    symbol: 'USDT',
                    value: this.inventoryContainer?.userCryptoBalance?.usdt ?? null
                },
                {
                    icon: require('~/assets/images/icons/gymnet-icon.svg'),
                    iconMobile: require('~/assets/images/icons/gymnet-icon.svg'),
                    symbol: 'GYMNET',
                    value: this.inventoryContainer?.userCryptoBalance?.gymnet ?? null
                },
                {
                    icon: require('~/assets/images/gymstreet/currencies/Subscription.svg'),
                    iconMobile: require('~/assets/images/gymstreet/currencies/Subscription.svg'),
                    symbol: 'PAP',
                    title: 'Product Autoship Points',
                    value: this.inventoryContainer?.userCryptoBalance?.subscriptionPoints ?? null
                },
                {
                    icon: require('~/assets/images/gymstreet/currencies/utility-balance.svg'),
                    iconMobile: require('~/assets/images/gymstreet/currencies/utility-balance.svg'),
                    symbol: 'Utility',
                    value: this.inventoryContainer?.minerRewardsData
                        ?.minerRewards
                        ?.minerRewardsUtilityInfo
                        ?.realAvailable ?? null,
                },
            ]
        }
    },
    methods: {
        getFourDecimalNumber(value = 0, isNum = true) {
            if (value) {
                const mainNum = value.toString().split('.');
                const stringValue = `${mainNum[0]}.${mainNum[1] ? mainNum[1].slice(0, 3) : 0}`;
                return isNum ? parseFloat(stringValue) : stringValue;
            }
        },
    }
}
