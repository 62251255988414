import { MetaWeb3Transaction } from '../models/MetaWeb3Transaction';
import ApiService from '~/core/services/api-interaction/ApiService';
import type { BuyCryptoRequest } from '~/core/models/purchase-popup-2/BuyCryptoRequest';
import { MetaWorldManager } from '~/core/services/map/MetaWorldManager';
import {fromWei, toWei} from "~/core/helpers/GlobalHelpers";
import GYMNETService from '~/pages/finances/wallet/services/GYMNETService';
import { CurrencyName } from '~/pages/finances/wallet/models/CurrencyName';
import { RawExternalWalletPayment } from '../types/purchase-popup-2/RawExternalWalletPayment';
import { OrderStatus } from "~/pages/finances/wallet/constants/OrderStatusEnum";

export class PaymentService {
    static mapDataToWeb3Transaction (data: any[]): MetaWeb3Transaction[] {
        return data.map((payment: any) => new MetaWeb3Transaction(payment));
    }

    static async fetchPaymentHistory (page = 1, recordsPerPage = 10, recipient: 'me' | 'others' = 'me', status: OrderStatus) {
        ApiService.setHeader();
        const response = await ApiService.query('payment/history', {
            params: {
                page,
                per_page: recordsPerPage,
                status: status !== OrderStatus.ALL ? status : undefined,
                recipient,
            },
        });

        const mappedPayments = PaymentService.mapDataToWeb3Transaction(response.data.payments);

        const result = {
            payments: mappedPayments,
            totalPaymentCount: Number(response.data.totalPaymentCount),
        };

        return result;
    }

    static async getPayment (paymentUID: string, transformData: boolean = true) {
        ApiService.setHeader();
        const response = await ApiService.query('payment', {
            params: { payment_uid: paymentUID },
        });

        if (!transformData) {
            return response.data.payment;
        }

        const mappedPayment = PaymentService.mapDataToWeb3Transaction([response.data.payment]);

        return mappedPayment[0];
    }

    static async fetchExchangeRate (currency: string): Promise<{data: {rate: number}}> {
        ApiService.setHeader();
        return await ApiService.query('payment/exchange-rate', { params: { currency } });
    }

    static async getTokenPriceUsd (code: CurrencyName, throwException: boolean = false): Promise<number> {

        switch (code) {
            case 'BNB':
                return await MetaWorldManager.sharedInstance().contracts.GymNetwork.methods.getBNBPrice().call();
            case 'BUSD':
                return 1;
            case 'USDT':
                return 1;
            case 'GYMNET':
                return GYMNETService.getRate(MetaWorldManager.sharedInstance());
            case 'BTC':
                try {
                    const resBTC: any = await this.fetchExchangeRate('BTC');
                    return (1 / Number(resBTC.data?.rate)) || 0;
                } catch (e) {
                    if(throwException) {
                        throw e;
                    }
                    return 0;
                }
            case 'ETH':
                try {
                    const resETH: any = await this.fetchExchangeRate('ETH');
                    return (1 / Number(resETH.data?.rate)) || 0;
                } catch (e) {
                    if(throwException) {
                        throw e;
                    }
                    return 0;
                }
            case 'BPX':
                try {
                    const usdtAddress = MetaWorldManager.sharedInstance().contracts.USDT._address;
                    const bitopexAddress = MetaWorldManager.sharedInstance().contracts.BitopexToken._address;

                    const resFIL = await MetaWorldManager.sharedInstance().contracts.PancakeRouter.methods.getAmountsOut(
                        toWei('1'), [bitopexAddress, usdtAddress ],
                    ).call();

                    return Number(fromWei(resFIL[1]));
                } catch (e) {
                    if(throwException) {
                        throw e;
                    }
                    console.error(e);
                    return 0;
                }
            case 'FIL':
                try {
                    const usdtAddress = MetaWorldManager.sharedInstance().contracts.USDT._address;
                    const filecoinAddress = MetaWorldManager.sharedInstance().contracts.FileCoin._address;

                    const resFil = await MetaWorldManager.sharedInstance().contracts.PancakeRouter.methods.getAmountsOut(
                        toWei('1'), [filecoinAddress, usdtAddress]
                    ).call();

                    return Number(fromWei(resFil[1]));
                } catch (e) {
                    if(throwException) {
                        throw e;
                    }
                    return 0;
                }
        }
    }

    static async buyCrypto (data: BuyCryptoRequest) {
        ApiService.setHeader();
        const result = await ApiService.post('payment/crypto/buy', data);
        return result.data;
    }

    static async buyCryptoForWallet (data: BuyCryptoRequest) {
        ApiService.setHeader();
        const result = await ApiService.post('payment/crypto/buy-for', data);
        return result.data;
    }

    static async* listenForPayment(
        paymentUID: string,
        interval: number,
        stillRunning: () => boolean,
        transformData: boolean = false
    ) {
        let payment: MetaWeb3Transaction | RawExternalWalletPayment = null;
        while (stillRunning()) {
            try {
                payment = await PaymentService.getPayment(paymentUID, transformData);
            } catch { /* ignore */ } finally {
                yield payment;
            }
            await new Promise(resolve => setTimeout(resolve, interval));
        }
        return payment;
    }
}
