
import {PRODUCT_TYPES} from "~/core/services/utils/Constants";

export default {
    name: "NftImageComponent",
    props: {
        nftType: {
            type: String,
            required: true,
        },
        purchaseInfo: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            PRODUCT_TYPES
        }
    },
    computed: {
        bundleData() {
            console.log('this.purchaseInfo?.bundleData', this.purchaseInfo?.bundleData);
            return this.purchaseInfo?.bundleData
        },
        getBundleDataClass() {
            if (this.nftType === PRODUCT_TYPES.BUNDLE && this.bundleData) {
                if (this.bundleData.numberOfMiners && !this.bundleData.numberOfParcels) {
                    return `image-${this.PRODUCT_TYPES.MINER}`
                } else if (!this.bundleData.numberOfMiners && this.bundleData.numberOfParcels) {
                    return `image-${this.PRODUCT_TYPES.PARCEL}`
                } else {
                    return `image-basic-${PRODUCT_TYPES.BUNDLE}`
                }
            } else {
                return `image-${this.nftType}`
            }
        }
    }
}
