
import { mapGetters } from 'vuex';
import GymNewButton from '~/components/utils/form-components/GymNewButton.vue';
import DividerWithText from '../utils/DividerWithText.vue';
import AuthService from '~/core/services/api-interaction/AuthService';
import { AuthError } from '~/core/services/api-interaction/AuthService';
import CustomLoader from '../utils/CustomLoader.vue';
import ERROR_MESSAGES from '~/core/validators/error-messages';
import {PopupHelper} from "@/core/helpers/PopupHelper";

const BACK_END_EMAIL_INTERVAL = 60;

export default {
    name: "ForceEmailConfirmationPopup",
    components: {
        GymNewButton,
        DividerWithText,
        CustomLoader,
    },
    props: {
        popupVisible: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        const changeEmailForm = {
            email: '',
        };
        const validationRules = {
            email: [
                { required: true, message: this.$t(ERROR_MESSAGES.EMAIL_ERR.REQUIRED), trigger: 'blur' },
                { type: 'email', message: this.$t(ERROR_MESSAGES.EMAIL_ERR.INVALID), trigger: ['blur', 'change'] }
            ],
        };
        return {
            resendButtonDisabled: false,
            seconds: null,
            interval: null,
            loaderVisible: false,
            actionsDisabled: false,
            changeEmailForm,
            validationRules,
        };
    },
    computed: {
        ...mapGetters({
            authUser: 'application/driver/authUser',
        }),
    },
    mounted() {
        const lastSentDate = new Date(this.authUser?.lastEmailSent);
        const currentDate = new Date();
        const seconds = Math.floor((currentDate - lastSentDate) / 1000);
        if(this.authUser?.needsToConfirmEmail) {
            if(seconds < BACK_END_EMAIL_INTERVAL) {
                this.resendButtonDisabled = true;
                this.startCountDown(BACK_END_EMAIL_INTERVAL - seconds);
            }
        }
    },
    methods: {
        async resendEmail() {
            try {
                this.startLoading();
                await AuthService.resendVerificationEmail(this.email);
                const lastEmailDate = new Date().toISOString();
                this.$store.commit('application/driver/UPDATE_AUTH_USER', {
                    lastEmailSent: lastEmailDate,
                });
                this.$auth.setUser({
                    ...this.$auth.user,
                    last_email_sent: lastEmailDate,
                });
                this.stopLoading();
                this.startCountDown();
                this.$emit('onResendEmail');
            } catch (err) {
                this.onError(err);
            }
        },
        changeEmail() {
            this.$refs.changeEmailForm.validate(async (valid) => {
                if (valid) {
                    try {
                        this.startLoading();
                        await AuthService.changeEmail(this.changeEmailForm.email);
                        const lastEmailDate = new Date().toISOString();
                        this.$store.commit('application/driver/UPDATE_AUTH_USER', {
                            email: this.changeEmailForm.email,
                            lastEmailSent: lastEmailDate,
                        });
                        this.$auth.setUser({
                            ...this.$auth.user,
                            email: this.changeEmailForm.email,
                            last_email_sent: lastEmailDate,
                        });
                        this.changeEmailForm.email = '';
                        this.stopLoading();
                        this.startCountDown();
                    } catch (err) {
                        this.onError(err);
                    }
                }
            });
        },
        startCountDown(seconds = BACK_END_EMAIL_INTERVAL) {
            this.seconds = seconds;
            this.interval = setInterval(() => {
                this.seconds--;
                if(this.seconds === 0) {
                    this.resendButtonDisabled = false;
                    this.actionsDisabled = false;
                    this.seconds = null;
                    clearInterval(this.interval);
                }
            }, 1000);
        },
        startLoading() {
            this.actionsDisabled = true;
            this.resendButtonDisabled = true;
            this.loaderVisible = true;
        },
        stopLoading() {
            this.loaderVisible = false;
        },
        async handleLogOut() {
            try {
                this.startLoading();
                await this.$auth.logout();
                await this.$store.dispatch('application/driver/logOut');
                this.stopLoading();
                this.$router.push('/nfts/products');
            } catch (err) {
                this.onError(err);
            }
        },
        onError(err) {
            this.actionsDisabled = false;
            this.resendButtonDisabled = false;
            this.stopLoading();

            let message = 'Something went wrong, please refresh the page and try again';
            if(err && err instanceof AuthError) {
                message = err.message;
            }
            PopupHelper.showErrorAlert(this.$t(message));
        },
    },

}
