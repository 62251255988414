
import VueCountdown from "@chenfengyuan/vue-countdown";
import CloudflareTurnstile from './CloudflareTurnstile.vue';
import GymNewButton from '~/components/utils/form-components/GymNewButton.vue';
import DividerWithText from '~/components/utils/DividerWithText.vue';
import ERROR_MESSAGES from '@/core/validators/error-messages';
import { DataService } from "~/core/services/DataService";
import { AuthError } from "~/core/services/api-interaction/AuthService.ts";
import InfoAlertCard from '~/components/utils/cards/info-alert-card/InfoAlertCard.vue';

const LOGIN_ATTEMPTS_PER_ACCOUNT = 3;
const LOGIN_ATTEMPTS_DATA_KEY = 'login-attempts';
const ZEROIZATION_INTERVAL = 24 * 60 * 60 * 1000;

export default {
    name: "SignInWithEmail",
    components: {
        GymNewButton,
        DividerWithText,
        InfoAlertCard,
        VueCountdown,
        CloudflareTurnstile,
    },
    props: {
        isMobile: {
            type: Boolean,
            default: false,
        },
        initialFormData: {
            type: Object,
            default: null,
        },
    },
    emits: ['onLoading', 'onError', 'onForgotPassword', 'onConnectWallet', 'onSuccessfullSignIn'],
    data () {
        return {
            signInForm: {
                email: '',
                password: '',
                turnstile_response: null,
            },
            validationRules: {
                email: [
                    {required: true, message: this.$t(ERROR_MESSAGES.EMAIL_ERR.REQUIRED), trigger: ['blur', 'change']},
                ],
                password: [
                    {required: true, message: this.$t(ERROR_MESSAGES.PASSWORD_ERR.REQUIRED), trigger: ['blur', 'change']},
                ],
                turnstile_response: [
                    {required: true, message: this.$t(ERROR_MESSAGES.CF_TURNSTILE_ERR.REQUIRED), trigger: ['blur', 'change']},
                ],
            },
            areActionsDisabled: false,
            restrictedUntil: null,
            failedAttemptsForEmail: 0,
            lastRestrictionExpiration: null,
            zeroizationTimeout: null,
            LOGIN_ATTEMPTS_PER_ACCOUNT,
            ZEROIZATION_INTERVAL,
        };
    },
    watch: {
        'signInForm.email': {
            handler (value) {
                value = value?.trim().toLowerCase();
                if (value) {
                    this.failedAttemptsForEmail = 0;
                    this.restrictedUntil = null;
                    this.lastRestrictionExpiration = null;
                    const accountsLockInfo = JSON.parse(localStorage.getItem(LOGIN_ATTEMPTS_DATA_KEY));
                    if (accountsLockInfo) {
                        const accountLockInfo = accountsLockInfo[value];
                        if (accountsLockInfo[value]) {
                            const now = new Date();
                            const blockedUntil = new Date(accountLockInfo?.restrictedUntil ?? accountLockInfo?.lastRestrictionExpiration ?? undefined);
                            // check if last failed attempt was more than 24 hours ago
                            if (accountLockInfo) {
                                this.failedAttemptsForEmail = accountLockInfo.failedAttempts;
                                this.restrictedUntil = accountLockInfo.restrictedUntil;
                                this.lastRestrictionExpiration = accountLockInfo.lastRestrictionExpiration;
                            }
                            if (!isNaN(blockedUntil.getTime())) {
                                if (blockedUntil.getTime() + ZEROIZATION_INTERVAL < now.getTime()) {
                                    // check if account data is expired
                                    this.clearUnsuccessfulEmailLoginAttempts();
                                } else if (blockedUntil.getTime() < now.getTime()) {
                                    // check if account block time is over
                                    this.saveUnsuccessfulLoginAttempt(
                                        LOGIN_ATTEMPTS_PER_ACCOUNT - 1,
                                        null,
                                        blockedUntil.getTime(),
                                    );
                                }
                            }
                        }
                    }
                }
            },
            deep: true,
            immediate: true,
        },
    },
    mounted () {
        if (this.initialFormData) {
            this.signInForm = this.initialFormData;
        }
    },
    beforeDestroy () {
        clearTimeout(this.zeroizationTimeout);
    },
    methods: {
        handleSignIn () {
            this.$refs.signInForm.validate().then(async valid => {
                if (valid) {
                    this.startLoading();
                    try {
                        const response = await this.$auth.loginWith('local', {
                            data: this.signInForm,
                        });
                        this.clearUnsuccessfulEmailLoginAttempts();
                        DataService.removeAllClaimStatusesFromLocalStorage();
                        this.$emit('onSuccessfullSignIn', this.signInForm);
                    } catch (err) {
                        this.signInForm.turnstile_response = null;
                        this.$refs.turnstile.reset();
                        this.onError(err);
                    }
                }
            });
        },
        async loginWithGoogle () {
            this.startLoading();
            try {
                await this.$auth.loginWith('google');
                this.$emit('onSignInWithGoogle');
            } catch (error) {
                this.onError(error, 'Google');
            }
        },
        async loginWithDiscord () {
            this.startLoading();
            try {
                await this.$auth.loginWith('discord');
                this.$emit('onSignInWithDiscord');
            } catch (error) {
                this.onError(error, 'Discord');
            }
        },
        startLoading () {
            this.areActionsDisabled = true;
            this.$emit('onLoading');
        },
        clearUnsuccessfulEmailLoginAttempts () {
            const accountsLockInfo = JSON.parse(localStorage.getItem(LOGIN_ATTEMPTS_DATA_KEY)) || {};
            delete accountsLockInfo[this.signInForm.email.toLowerCase()];
            localStorage.setItem(LOGIN_ATTEMPTS_DATA_KEY, JSON.stringify(accountsLockInfo));
            this.failedAttemptsForEmail = 0;
            this.restrictedUntil = null;
            this.lastRestrictionExpiration = null;
        },
        saveUnsuccessfulLoginAttempt (failedAttempts, restrictedUntil, lastRestrictionExpiration = null) {
            const accountsLockInfo = JSON.parse(localStorage.getItem(LOGIN_ATTEMPTS_DATA_KEY)) || {};
            const accountLockInfo = accountsLockInfo[this.signInForm.email.toLowerCase()] || {};
            this.failedAttemptsForEmail = failedAttempts;
            accountLockInfo.failedAttempts = failedAttempts;
            this.restrictedUntil = restrictedUntil;
            accountLockInfo.restrictedUntil = restrictedUntil;
            if (lastRestrictionExpiration) {
                accountLockInfo.lastRestrictionExpiration = lastRestrictionExpiration;
                this.lastRestrictionExpiration = lastRestrictionExpiration;
            }
            accountsLockInfo[this.signInForm.email.toLowerCase()] = accountLockInfo;
            localStorage.setItem(LOGIN_ATTEMPTS_DATA_KEY, JSON.stringify(accountsLockInfo));
        },
        onError (errorObject) {
            this.areActionsDisabled = false;
            if (errorObject instanceof AuthError) {
                if (errorObject.failedAttempts || errorObject.restrictedUntil) {
                    this.saveUnsuccessfulLoginAttempt(
                        errorObject.failedAttempts ?? null,
                        errorObject.restrictedUntil ?? null,
                        errorObject.lastRestrictionExpiration ?? null,
                    );
                }
            }
            this.$emit('onError', {
                error: errorObject,
                popupData: {
                    title: "Couldn't login.",
                    message: errorObject.message,
                },
            });
        },
        handleChangeTurnstile(value) {
            this.signInForm.turnstile_response = value;
            this.$refs.signInForm.validate();
        }
    },
};
