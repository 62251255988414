
import { mapActions, mapGetters } from "vuex";
import { initializeComponents, reinitializeComponents } from '@/core/services/keenthemes';
import {
    AUTH_FLOW_STEPS,
    diagLog,
    GYMSTREET_STATE,
    METAVERSE_SWITCH_ASK_KEY,
    ERROR_STATUSES,
NULLABLE_WALLET_ADDRESS
} from "~/core/services/utils/Constants";
import { MetaWorldManager } from "@/core/services/map/MetaWorldManager";
import { PopupHelper } from "@/core/helpers/PopupHelper";
import { MetaInventoryContainer } from "~/core/models/MetaInventoryContainer";
import { MetaParcelsRegistry } from "~/core/services/map/MetaParcelsRegistry";
import { FinishReasonEnum } from '~/core/types/purchase-popup-2/FinishReasonEnum';
import { PartnerAreaPages } from "@/constants/constants";
import { METAVERSE_IDS } from "@/core/services/utils/MetaverseConstants";
import SignatureDeclinedError from "@/core/helpers/SignatureDeclinedError";
import LayoutService from '@/core/services/LayoutService';
import ApiService from "@/core/services/api-interaction/ApiService";
import GYMHeader from "../components/header/GYMHeader.vue";
import GYMAside from "../components/aside/aside/GYMAside.vue";
import GYMLoader from "../components/utils/loader/GYMLoader.vue";
import SuccessPopup from "~/components/popups/SuccessPopup";
import ComfirmationPopup from "~/components/popups/ComfirmationPopup";
import FullScreenLoader from "@/components/utils/loader/FullScreenLoader";
import AcceptAffiliateTOSPopup from "~/components/popups/AcceptAffiliateTOSPopup";
import LoadingPopup from "@/components/popups/LoadingPopup";
import Countdown from "@/components/utils/Countdown";
import GymSignInPopup from "~/components/popups/GymSignInPopup.vue";
import MetaMaskInstallationPopup from "@/components/popups/MetaMaskInstallationPopup";
import ForceEmailConfirmationPopup from "~/components/popups/ForceEmailConfirmationPopup.vue";
import SixDayIncentivePopup from "~/components/popups/SixDayIncentivePopup.vue";
import ClaimModal from "@/components/popups/web2MetamaskModals/ClaimModal";
import AllowanceModal from "@/components/popups/web2MetamaskModals/AllowanceModal";
import PurchasePopup from "~/components/popups/purchase-popup/PurchasePopup.vue";
import MysteryBoxPurchasePopup from "~/components/popups/mystery-box-purchase-popup/MysteryBoxPurchasePopup.vue";
import UsernamePopup from "~/components/popups/UsernamePopup.vue";
import SubscriptionBuyModal from "@/pages/nfts/products/components/subscriptionsWeb2Modal/SubscriptionsWeb2Modal";
import CustomerAffiliateRoleModal from "@/components/customerAffiliateRoleModal/CustomerAffiliateRoleModal";
import DepositWeb2Modal from "@/components/upgradeWeeklyCap/DepositWeb2Modal";
import FinalizeRegistrationPopup from "~/components/popups/FinalizeRegistrationPopup.vue";
import MysteryBoxOptionsPopup from "~/components/popups/mysteryBoxOptions/MysteryBoxOptionsPopup.vue";
import CashFTPurchaseCardModal from "~/components/cashFT/cashFTPurchaseCardModal/CashFTPurchaseCardModal.vue";
import DepositWeb2ModalLockedUnlocked from "~/components/upgradeWeeklyCap/DepositWeb2ModalLockedUnlocked.vue";
import SupportButton from "~/components/utils/buttons/SupportButton.vue";

export default {
    name: 'Public',
    components: {
        DepositWeb2ModalLockedUnlocked,
        CashFTPurchaseCardModal,
        MysteryBoxOptionsPopup,
        DepositWeb2Modal,
        FinalizeRegistrationPopup,
        SubscriptionBuyModal,
        CustomerAffiliateRoleModal,
        AllowanceModal,
        ClaimModal,
        MetaMaskInstallationPopup,
        Countdown,
        LoadingPopup,
        FullScreenLoader,
        SuccessPopup,
        ComfirmationPopup,
        GYMAside,
        GYMHeader,
        GYMLoader,
        AcceptAffiliateTOSPopup,
        GymSignInPopup,
        ForceEmailConfirmationPopup,
        SixDayIncentivePopup,
        PurchasePopup,
        MysteryBoxPurchasePopup,
        UsernamePopup,
        SupportButton,
    },
    data () {
        return {
            showLoading: true,
            isCountDownActive: false,
            isSignInVisible: false,
            ERROR_STATUSES,
            isPartnerMenu: false,
            maintenanceLoop: null,
        };
    },

    computed: {
        ...mapGetters('application/maintenances', ['nearestMaintenance']),
        ...mapGetters('application/driver',
            ['applicationStatus', 'isInitWalletInProgress', 'isLoginInProgress', 'authUser', 'headerVisible']),
        ...mapGetters('application/popup-control', [
            'successPopupData',
            'comfirmationPopupData',
            'loadingPopupData',
            'isConnectWalletPopupVisible',
            'isMetaMaskInstallationPopupVisible',
            'affiliateTmsPopupData',
            'claimWeb2PopupData',
            'isWeb2ClaimPopupVisible',
            'contractNameForTransaction',
            'isWeb2AllowancePopupVisible',
            'allowanceContractAddress',
            'isWeb2SubscriptionModalVisible',
            'subscriptionWeb2PopupData',
            'depositToSPWeb2PopupData',
            'isWeb2DepositToSPModalVisible',
            'isWeb2DepositToSPModalVisibleLockedUnlocked',
            'depositToSPWeb2PopupDataLockedUnlocked',
        ]),
        ...mapGetters({
            address: 'auth/address',
        }),
        ...mapGetters('map/mapModule', ['metaverseId']),
        ...mapGetters('application/purchase-control', [
            'purchase',
            'startStep',
            'isPurchasePopupVisible',
            'externalPayment',
        ]),
        ...mapGetters({
            isMysteryBoxPurchasePopupVisible: 'application/mystery-box/isMysteryBoxFlowVisible',
            mysteryBoxPurchaseObject: 'application/mystery-box/purchase',
            mysteryBoxStartStep: 'application/mystery-box/startStep',
            isMysteryBoxOptionsPopupVisible: 'application/mystery-box/isMysteryBoxOptionsPopupVisible',
        }),
        showUserNamePopup() {
            return this.authUser && !this.authUser?.username;
        },
        showForceEmailConfirmationPopup() {
            return this.authUser && !this.authUser?.isEmailVerified && this.authUser?.emailConfirmationOverdued;
        },
        getLoadingMessage () {
            let message = 'Loading! Please Wait ...';
            switch (this.applicationStatus) {
                case GYMSTREET_STATE.INIT_WALLET_IN_PROGRESS:
                    message = 'Initializing Wallet! Please Wait ...';
                    break;
                case GYMSTREET_STATE.SIGNATURE_GRANT_IN_PROGRESS:
                    message = 'Please go to your wallet, which you just connected, and verify your login by confirming the requested transaction. It\'s free of charge!';
                    break;
                case GYMSTREET_STATE.LOGIN_IN_PROGRESS:
                    message = 'Login In Progress! Please Wait ...';
                    break;
                default:
                    message = 'Loading! Please Wait ...';
            }

            return message;
        },
        isFullScreenLoaderVisible () {
            return [
                GYMSTREET_STATE.INIT_WALLET_IN_PROGRESS,
                GYMSTREET_STATE.LOGIN_IN_PROGRESS,
                GYMSTREET_STATE.SIGNATURE_GRANT_IN_PROGRESS,
                GYMSTREET_STATE.ATTACH_WALLET_IN_PROGRESS,
            ].includes(this.applicationStatus);
        },
        getMarginTop () {
            let marginTop = 0;
            if (this.authUser && this.$route.path.startsWith('/dashboard')) marginTop += 52;
            if (this.$route.path.startsWith('/map')) marginTop = 0;
            return `${marginTop}px`;
        },
        isVisibleCustomerAffiliateRoleModal() {
            if (!this.authUser && !this.authUser?.walletAddress) return false;
            return typeof this.authUser.isRoleSelected === 'boolean' && !this.authUser.isRoleSelected;
        }
    },
    watch: {
        $route: {
            immediate: true,
            handler (to) {
                this.$nextTick(() => {
                    this.setupLocale();
                    reinitializeComponents();
                });
                if (to.path.startsWith('/partner-area')) {
                    if (this.authUser && this.authUser.hasAccessToPartnerArea()) {
                        this.isPartnerMenu = true;
                    } else {
                        this.$router.push('/dashboard');
                    }
                } else {
                    this.isPartnerMenu = false;
                }
            },
        },
        authUser: {
            deep: true,
            async handler (userData, oldUserData) {
                const mwm = MetaWorldManager.sharedInstance();
                if(userData) {
                    this.setPartnerAreaPageLinks();
                    MetaInventoryContainer.sharedInstance().setAuthUserData(userData);
                    mwm.setUserGoodsFetchingLoop();
                    if(userData.walletAddress || userData.relatedWalletAddress) {
                        mwm.fetchParcels(userData);
                    }
                    if(!oldUserData) {
                        this.handleMetaverseSwitch();
                    }
                    if(oldUserData?.id !== userData?.id) {
                        mwm.refetchReservedAreas();
                    }
                    const wallet = userData.walletAddress || userData.relatedWalletAddress;
                    this.$store.dispatch('cashFT/products/loadCashFtBundles', {
                        walletAddress: wallet,
                        withLoading: true,
                    });
                    if (!(userData.hasAccessToPartnerArea())) {
                        const sum = await mwm.fetchUserOwnedNFTsSum();
                        if (sum > 0) {
                            this.$store.commit('application/driver/UPDATE_AUTH_USER', {hasPurchasedNFT: true});
                        }
                    }
                } else if (!userData && oldUserData) {
                    mwm.refetchReservedAreas();
                    MetaInventoryContainer.sharedInstance().setAuthUserData(null);
                    MetaParcelsRegistry.sharedInstance().clearUserOwnedParcels();
                    mwm.stopUserGoodsFetchingLoop();
                    this.$store.dispatch('application/mystery-box/resetMysteryBoxHistoryData');
                    this.$store.dispatch('cashFT/products/loadCashFtBundles', {
                        walletAddress: NULLABLE_WALLET_ADDRESS,
                        withLoading: true,
                    });
                }

            },
        },
        metaverseId: {
            async handler (metaverseId, oldMetaverseId) {
                if(metaverseId !== oldMetaverseId) {
                    MetaInventoryContainer.sharedInstance().destroyUserInventoryData();
                    const mwm = MetaWorldManager.sharedInstance();
                    mwm.setUserGoodsFetchingLoop();
                    mwm.clearMetaParcelRegistry();
                    this.showLoading = true; // TODO handle simultaneous requests and dont show loading
                    await mwm.loadData();
                    this.showLoading = false;
                    mwm.fetchParcels(this.authUser);
                }
            },
        },
    },
    async mounted () {
        if(!this.authUser) {
            this.handleRefId();
        }
        this.handleMetaverseId();
        this.setMaintenanceLoop();
        this.setPartnerAreaPageLinks();
        const mwm = MetaWorldManager.sharedInstance();
        this.setupLocale();
        await this.handleWeb3AutoConnect();
        if(this.authUser) {
            MetaInventoryContainer.sharedInstance().setAuthUserData(this.authUser);
            mwm.setUserGoodsFetchingLoop();
            if(this.authUser.walletAddress || this.authUser.relatedWalletAddress) {
                mwm.fetchParcels(this.authUser);
            }
        }
        LayoutService.init();
        this.$nextTick(async () => {
            initializeComponents();
            this.setupLocale();
            mwm.loadData();
            this.showLoading = false;
        });
    },
    beforeDestroy () {
        clearInterval(this.maintenanceLoop);
    },
    methods: {
        ...mapActions('application/maintenances', ['loadUpcomingMaintenances']),
        setMaintenanceLoop () {
            clearInterval(this.maintenanceLoop);
            this.loadUpcomingMaintenances();
            this.maintenanceLoop = setInterval(() => {
                this.loadUpcomingMaintenances();
            }, 1000 * 60 * 5); // Fetch every 5 minutes
        },
        handleMetaverseId () {
            const queryMvId = Number(this.$route?.query?.metaverseId);
            const isMvIdSupported = queryMvId && Object.values(METAVERSE_IDS).includes(queryMvId);
            const isMvIdDifferent = queryMvId && queryMvId !== this.$metaverseId();
            if(isMvIdSupported && isMvIdDifferent) {
                this.$setMetaverseId(queryMvId);
            }
        },
        handleRefId() {
            if(this.$route?.query?.ref) {
                this.$store.dispatch('application/driver/setAuthStep', AUTH_FLOW_STEPS.SIGN_UP_WITH_EMAIL);
                this.$store.dispatch('application/popup-control/changeConnectWalletPopupStatus', true);
                return true;
            }
            return false;
        },
        handleMetaverseSwitch () {
            const dontAskMetaverse = localStorage.getItem(METAVERSE_SWITCH_ASK_KEY);
            if(dontAskMetaverse === 'false' || !dontAskMetaverse) {
                const queryMvId = Number(this.$route?.query?.metaverseId);
                if(!this.$route.path.startsWith('/oauth') &&
                    !this.$route.path.startsWith('/auth') &&
                    !this.$route.path.includes('/metaverse-switch') &&
                    (!queryMvId || Number(queryMvId) !== this.$metaverseId())) {
                    localStorage.setItem('redirect', this.$route.fullPath);
                    this.$router.push({path: '/metaverse-switch', query: {ref: this.$route.query.ref}});
                }
            }
        },
        async handleWeb3AutoConnect () {
            try {
                const provider = this.$ethereum();

                if (provider) {
                    if(provider.on) {
                        provider.on("accountsChanged", async (accounts) => {
                            diagLog({}, 'ACCOUNT CHANGED TO =>', {accounts});
                            if(this.authUser && accounts.length) {
                                const address = accounts[0];
                                await this.$store.dispatch('application/driver/setCurrentActiveAddress', address);
                            }
                        });
                    }
                }
            } catch (e) {
                if(e instanceof SignatureDeclinedError) {
                    PopupHelper.showErrorAlert(this.$t('Signature declined'), this.$t('You declined signature request. Please try again.'));
                }
                console.error('public handleWeb3AutoConnect', e);
            }
        },
        async handleClickPartnerArea () {
            this.isPartnerMenu = true;
            this.$router.push('/partner-area/career-status');
        },
        async handleMainAreaClick () {
            this.isPartnerMenu = false;
        },
        handleConnectWalletPopupClose () {
            this.$store.dispatch('application/popup-control/changeConnectWalletPopupStatus', false);
        },
        handleMetaMaskInstallationPopupClose () {
            this.$store.dispatch('application/popup-control/openMetaMaskInstallationPopup', false);
        },
        setupLocale () {
            let loc = this.$i18n.defaultLocale;
            if (process.browser) {
                const selectedLocale = localStorage.getItem('locale');
                if (selectedLocale) {
                    loc = selectedLocale;
                }
            }
            this.$i18n.locale = loc;
            this.currentLocale = loc;
        },
        handleGoToSpecialOffers () {
            this.$router.push({path: '/dashboard#special-offers-bundle'});

            this.$nextTick(() => {
                const anchorElement = document.querySelector('#special-offers-bundle');
                if (anchorElement) {
                    window.scrollTo({
                        top: anchorElement.offsetTop - 150,
                        behavior: 'smooth',
                    });
                }
            });
        },
        handleLogIn () {
            this.$store.dispatch('application/driver/setAuthStep', AUTH_FLOW_STEPS.SIGN_IN_WITH_EMAIL);
            this.$store.dispatch('application/popup-control/changeConnectWalletPopupStatus', true);
        },
        async logout () {
            await this.$auth.logout();
            await this.$store.dispatch('application/driver/logOut');
        },
        handleCloseModal () {
            this.$store.commit('application/driver/SET_SEND_TX_SUCCESS', -1);
            this.$store.dispatch('application/popup-control/showWeb2Popup', false, {root: true});
        },
        handleCloseSubscriptionModal () {
            this.$store.commit('application/driver/SET_SEND_TX_SUCCESS', -1);
            this.$store.dispatch('application/popup-control/showWeb2SubscriptionPopup', false, {root: true});
        },
        handleCloseDepositToSPModal () {
            this.$store.commit('application/driver/SET_SEND_TX_SUCCESS', -1);
            this.$store.dispatch('application/popup-control/showWeb2DepositToSPPopup', false, {root: true});
        },
        handleCloseDepositToSPModalLockedUnlocked () {
            this.$store.commit('application/driver/SET_SEND_TX_SUCCESS', -1);
            this.$store.dispatch('application/popup-control/showWeb2DepositToSPPopupLockedUnlocked', false, {root: true});
        },
        handleCloseAllowanceModal () {
            this.$store.dispatch('application/popup-control/showWeb2AllowancePopup', false, {root: true});
        },
        setPartnerAreaPageLinks () {
            if (this.authUser && this.authUser.hasAccessToPartnerArea()) {
                if (PartnerAreaPages.includes(this.$route.name)) {
                    this.isPartnerMenu = true;
                }
            } else if (PartnerAreaPages.includes(this.$route.name)) {
                if (this.authUser) {
                    this.$router.push('/dashboard');
                } else {
                    this.$router.push('/nfts/products');
                }
            }
        },
        reload () {
            window.location.reload();
        },
        async fetchBinaryUserData() {
            const timer = setInterval( async () => {
                const responseBinary = await ApiService.get(`${this.$config.binaryTreeApi}/api/users`);
                if (responseBinary && responseBinary?.data?.user) {
                    this.$store.commit('application/driver/UPDATE_AUTH_USER', {
                        isRoleSelected: responseBinary?.data?.user?.isRoleSelected,
                        currentRankName: responseBinary?.data?.user?.currentRank,
                        commissionRankName: responseBinary?.data?.user?.commissionRank,
                        subscriptionExpiresAt: responseBinary?.data?.subscription_expires_at,
                        isReferralLinkActive: responseBinary?.data?.user?.isReferralLinkActive,
                        isFastStartIncentiveActive: responseBinary?.data?.user?.isFastStartIncentiveActive,
                        totalOwnPurchaseAmount: responseBinary?.data?.user?.totalOwnPurchaseAmount,
                        totalUnilevelTeamTurnoverAmount: responseBinary?.data?.user.totalUnilevelTeamTurnoverAmount,
                        isUsedFastStartIncentive: responseBinary?.data?.user.isUsedFastStartIncentive,
                        firstPurchaseDate: responseBinary?.data?.firstPurchaseDate
                    });
                }
            }, 3000);
            setTimeout(() => {
                clearInterval(timer);
            }, 60000);
        },
        onPurchasePopupClose() {
            const finishReason = this.$store.state.application['purchase-control'].finishReason;
            if (finishReason === FinishReasonEnum.SUCCESS) {
                this.fetchBinaryUserData();
            }
        },
        onMysteryBoxPurchasePopupClose() {

        },
    },
};
