import Web3 from "web3";
import detectEthereumProvider from "@metamask/detect-provider";
import { EthereumProvider } from "@walletconnect/ethereum-provider";
import { getChainById } from "@/constants/networks";
import { CONTRACTS, COMMON_CONTRACTS } from "@/contracts/addresses";
import { MetaWorldManager } from "~/core/services/map/MetaWorldManager";
import { diagLog, SUPPORTED_CHAIN_IDS, WALLET_ERROR_CODES } from "~/core/services/utils/Constants";
import { METAVERSE_ABI_FOLDERS } from "~/core/services/utils/MetaverseConstants";
import { FileCoinNetwork } from "~/pages/finances/wallet/constants/WalletNetworkConstants";

export default async (ctx, inject) => {
    let provider;
    const supportedChains = SUPPORTED_CHAIN_IDS;
    let chainID = ctx.$config.chainId;

    const createHttpProviderByChainId = (id, options) => {
        const chain = getChainById(id);
        return new Web3.providers.HttpProvider(chain.url, options || {
            keepAlive: true,
        });
    };

    const web3obj = new Web3(createHttpProviderByChainId(chainID));
    const readWeb3obj = new Web3(createHttpProviderByChainId(chainID));

    const $contracts = {};
    const $write = {};

    setContracts();
    async function setContracts () {
        const commonContracts = { contracts: {}, write: {} };

        await Promise.all(Object.keys(COMMON_CONTRACTS).map(async (key) => {
            const createdContract = await createContractsByFolder(COMMON_CONTRACTS[key][chainID], 'common', key);
            commonContracts.write[key] = createdContract.write;
            commonContracts.contracts[key] = createdContract.contracts;
        }));

        await Promise.all(Object.keys(CONTRACTS).map(async (metaverseId) => {
            const metaverse = CONTRACTS[metaverseId];
            const abiFolder = METAVERSE_ABI_FOLDERS[metaverseId];
            const contracts = {};
            const write = {};
            await Promise.all(Object.keys(metaverse).map(async (key) => {
                const createdContract = await createContractsByFolder(metaverse[key][chainID], abiFolder, key);
                contracts[key] = createdContract.contracts;
                write[key] = createdContract.write;
            }));
            $contracts[metaverseId] = { ...contracts, ...commonContracts.contracts };
            $write[metaverseId] = { ...write, ...commonContracts.write };
        }));
    }

    async function createContractsByFolder(contractAddress, abiFolder, key) {
        const abi = await require(`@/contracts/abis/${abiFolder}/${key}.json`);
        return {
            contracts: new readWeb3obj.eth.Contract(abi, contractAddress),
            write: new web3obj.eth.Contract(abi, contractAddress)
        }
    }

    const createWeb3Provider = async (p, isNewConnection = true) => {
        const additionalData = {};
        let providerObject;
        const rpcMap = {
            56: getChainById(56).url,
            97: getChainById(97).url,
        };
        switch (p) {
            case "injected":
                providerObject = window.ethereum;
                await checkChainSupport(providerObject, providerObject.networkVersion);
                break;
            case "walletconnect":
                providerObject = await EthereumProvider.init({
                    projectId: ctx.$config.wcProjectId,
                    chains: ctx.$config.chainId === 56 ? [56] : [97],
                    optionalChains: ctx.$config.chainId === 56 ? [97] : [56],
                    showQrModal: true,
                    methods: [
                        "eth_sendTransaction",
                        "personal_sign",
                        "eth_sign",
                    ],
                    rpcMap,
                    qrModalOptions: {
                        themeVariables: {
                            "--w3m-z-index": 2147483647,
                            "--wcm-z-index": 2147483647,
                        },
                    },
                    metadata: {
                        name: "GymStreet",
                        description: "GymStreet Metaverse",
                        url: "https://app.gymstreet.io",
                        icons: ["https://images.gymstreet.io/logos/logo-1.png"],
                    },
                });
                if (isNewConnection && providerObject.accounts.length > 0) {
                    await providerObject.disconnect();
                }
                break;
            case "binance": {
                providerObject = window.BinanceChain;
                // await checkChainSupport(providerObject, providerObject.chainId);
                break;
            }
        }
        return {
            providerObject,
            additionalData,
        };
    };

    const enableProvider = async (providerData) => {
        const { providerObject } = providerData;
        let address = null;
        try {
            address = await providerObject.enable();
        } catch (e) {
            if (e.message.includes("Connection request reset. Please try again.")) {
                throw Object.assign(new Error("WALLET_CONNECT_MODAL_CLOSED"), { code: WALLET_ERROR_CODES.USER_REJECTED });
            }
            throw e;
        }
        return address;
    };

    const setWeb3Provider = async (p) => {
        const { providerObject, additionalData } = await createWeb3Provider(p);
        await enableProvider({ providerObject, additionalData });
        web3obj.setProvider(providerObject);
        provider = providerObject;

        if (provider.selectedAddress) {
            provider.on("chainChanged", (id) => {
                if (id === FileCoinNetwork.chainId) {
                    return;
                }
                setChainId(parseInt(id));
            });
        }
    };

    const handleChainChange = async (chId) => {
        if (SUPPORTED_CHAIN_IDS.includes(parseInt(chainID)) && SUPPORTED_CHAIN_IDS.includes(parseInt(chId))) {
            chainID = chId;
            const newProvider = createHttpProviderByChainId(chainID);
            readWeb3obj.setProvider(newProvider);
            await setContracts();
        } else {
            chainID = ctx.$config.chainId;
        }
    };

    const setChainId = async (chainId = null) => {
        if (!supportedChains.includes(parseInt(chainId))) {
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: web3obj.utils.toHex(ctx.$config.chainId) }],
            }).then(async (res) => {
                await handleChainChange(chainId);
            }).catch(async (err) => {
                await handleChainChange(ctx.$config.chainId);
            });
        }
    };

    const checkChainSupport = async (providerObject, chainId) => {
        if (providerObject && !supportedChains.includes(parseInt(chainId))) {
            await providerObject.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: web3obj.utils.toHex(ctx.$config.chainId) }],
            }).then(async (res) => {
                await handleChainChange(ctx.$config.chainId);
            });
        } else if (providerObject) {
            await handleChainChange(chainId);
        }
    };

    try {
        provider = await detectEthereumProvider({ mustBeMetaMask: false, timeout: 100 });
        diagLog({}, 'WEB3 PLUGIN => ', provider);
        if (provider) {
            web3obj.setProvider(provider);
        }
    } catch (e) {
        console.error('web3.js detectEthereumProvider', e);
    }
    const web3 = () => {
        return web3obj;
    };

    const readWeb3 = () => {
        return readWeb3obj;
    };

    const chainId = () => {
        return chainID;
    };

    const ethereum = () => {
        return provider;
    };

    ctx.$web3 = web3;
    ctx.$readWeb3 = readWeb3;
    ctx.$chainId = chainId;
    ctx.$contracts = $contracts;
    ctx.$write = $write;
    ctx.$createWeb3Provider = createWeb3Provider;
    ctx.$enableProvider = enableProvider;
    ctx.$setWeb3Provider = setWeb3Provider;
    ctx.$ethereum = ethereum;
    ctx.$checkChainSupport = checkChainSupport;
    ctx.$setChainId = setChainId;

    inject("contracts", $contracts);
    inject("write", $write);
    inject("web3", web3);
    inject("readWeb3", readWeb3);
    inject("chainId", chainId);
    inject("createWeb3Provider", createWeb3Provider);
    inject("enableProvider", enableProvider);
    inject("setWeb3Provider", setWeb3Provider);
    inject("ethereum", ethereum);
    inject("checkChainSupport", checkChainSupport);
    inject("setChainId", setChainId);

    MetaWorldManager.sharedInstance().setWeb3Properties(web3, readWeb3, $contracts, $write, ethereum, chainId, null);
};
