
import GYMButton from "~/components/utils/buttons/GYMButton";
import {BinaryTreeService} from "@/pages/partner-area/my-team/services/BinaryTreeService";
import GymNewButton from "~/components/utils/form-components/GymNewButton.vue";
import {isMobileDevice} from "~/core/helpers/GlobalHelpers";

export default {
    name: "GlobalTOSPopup",
    components: {GymNewButton, GYMButton},
    emits: ['onPopupClose', 'onAccept'],
    props: {
        popupVisible: Boolean,
        sponsorWallet: String,
        isForSignUp: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            sponsorUsername: '',
            isMobile: isMobileDevice(),
            resizeHandler: () => this.isMobile = isMobileDevice(),
        }
    },
    destroyed() {
        window.removeEventListener('resize', this.resizeHandler);
    },
    async mounted () {
        window.addEventListener('resize', this.resizeHandler);
        if (this.$route?.query?.ref) {
            try {
                const isValidRef = await BinaryTreeService.validateReferralId(this.$route?.query?.ref);
                if (isValidRef) {
                    this.sponsorUsername = BinaryTreeService.sponsorUsername || this.refIdForm.referralId;
                } else {
                    this.showRefIdError = true;
                }
            } catch (e) {
                console.log(this.$t(e.message))
            }
        }
    },
    methods: {
        handleModalClose() {
            this.$emit('onPopupClose');
        },
        handleAccept() {
            this.$emit('onAccept');
        }
    }
}
