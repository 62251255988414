// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/images/icons/show-password-icon.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../../assets/images/icons/hide-password-icon.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".password-input .el-input__suffix{right:10px}.password-input .el-input__suffix .el-input__suffix-inner{height:100%;display:flex;align-items:center}.password-input .el-input__suffix .el-input__icon{width:20px;height:20px;background-size:100%;background-repeat:no-repeat}.password-input .el-input__suffix .el-input__icon:before,.password-input .el-input__suffix .el-input__icon:after{display:none}.password-input input[type=password]+.el-input__suffix .el-input__icon{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.password-input input[type=text]+.el-input__suffix .el-input__icon{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
