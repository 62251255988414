
import GYMButton from "@/components/utils/buttons/GYMButton";

export default {
    components: {
        GYMButton,
    },
    name: "SuccessPopup",
    emits: ['handleButtonClick'],
    props: {
        popupVisible: Boolean,
        popupData: Object
    },
    methods: {
        handleClosePopup() {
        }
    }
}
