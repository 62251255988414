import { render, staticRenderFns } from "./InsufficientFunds.vue?vue&type=template&id=6eea47d8&scoped=true&"
import script from "./InsufficientFunds.vue?vue&type=script&lang=ts&"
export * from "./InsufficientFunds.vue?vue&type=script&lang=ts&"
import style0 from "./InsufficientFunds.scss?vue&type=style&index=0&id=6eea47d8&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6eea47d8",
  null
  
)

export default component.exports